<template>
  <span> {{ formatDate }}, {{ formatTime }} </span>
</template>

<script>
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

export default {
  name: "o-date-time",
  props: {
    value: {
      type: [Number, String],
      default: () => {
        return new Date();
      },
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formatTime() {
      return format(this.date, "HH:mm", { locale: de });
    },
    formatDate() {
      return format(this.date, "dd.MM.yyyy", { locale: de });
    },
    date() {
      if (typeof this.value === "string") {
        return parseISO(this.value);
      }
      return new Date(this.value);
    },
  },
};
</script>

<style></style>
