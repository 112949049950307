import Vue from "vue";
import VueRouter from "vue-router";
import RoleGuard from "./role-guard";
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

Vue.use(RoleGuard, { router });

export default router;
