<template>
  <v-sheet outlined rounded="pill" width="512px" v-bind="{ ...$attrs }">
    <v-autocomplete
      v-model="selected"
      :items="items"
      :menu-props="{
        rounded: 'xl',
      }"
      placeholder="Suche"
      filled
      rounded
      single-line
      hide-details
      item-text="label"
      item-value="id"
      clearable
      prepend-inner-icon="mdi-magnify"
      outlined
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-avatar>
            <v-avatar size="24" :color="getColorGroup(item.colorGroup).color" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ getColorGroup(item.colorGroup).name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<script>

export default {
  name: "o-chart-searchbar",
  props: {
    value: {
      type: [String, Number],
    },
    graph: {
      type: Array,
      default: () => [],
    },
    colorGroups: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getColorGroup(id) {
      const group = this.colorGroups.find((g) => {
        return g.id === id;
      });

      return group ?? this.colorGroups[0];
    },
  },
  computed: {
    items() {
      const items = [];

      for (let i = 0; i < this.colorGroups.length; i++) {
        const group = this.colorGroups[i];
        const nodes = this.graph.filter((node) => {
          return node.colorGroup === group.id;
        });
        if (nodes.length <= 0) continue;

        items.push({ header: group.name });

        items.push(...nodes);

        if (i < this.colorGroups.length - 1) {
          items.push({ divider: true });
        }
      }

      return items;
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
