import Vue from "vue";

const utils = {
  install(Vue, options) {
    Vue.prototype.$utils = {
      math: {
        clamp: function (value, min, max) {
          return Math.min(max, Math.max(value, min));
        },
      },
      formatDate(value, showDate = true, showTime = true, style = "medium") {
        const options = {};

        if (showDate)
          Object.assign(options, {
            dateStyle: style,
          });
        if (showTime)
          Object.assign(options, {
            timeStyle: style,
          });

        return value.toLocaleString("de-De", options);
      },
      hasSlot: function (name = "default", comp) {
        return !!comp.$slots[name] || !!comp.$scopedSlots[name];
      },
    };
  },
};

Vue.use(utils);

export default utils;
