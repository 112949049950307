import { apiService } from "@/services";

export const pollService = {
  checkin,
  checkout,
  updateNode,
  updateAnswer,
};

async function checkin(data) {
  // Hier muss abgefangen werden, ob eine Survey open oder closed ist.
  // Das passiert, in dem geprüft wird, ob nebem dem pash auch ein shash existiert.
  // phash und shash vorhanden -> closed (so wie unten weitermachen)
  // nur phash vorhanden -> open (checkin fetch machen und den im Return-Objekt enthaltenen shash weiternutzen)
  // ... noch anzupassen ... 26.7.2023

  /* return hasValidPhash(data).then(({ phash, shash }) => {
    return getCheckin(phash, shash).then((v0) => {
      // console.log("v0: ", v0);
      return getQuestionnarie(phash, v0.subject_hash).then((v2) => {
        return { ...v0, questionnarie: v2 };
      });
    });
  }); */

  try {
    const { phash, shash } = await hasValidPhash(data);
    const v0 = await getCheckin(phash, shash);
    const v2 = await getQuestionnarie(phash, v0.subject_hash);

    return { ...v0, questionnarie: v2 };
  } catch (error) {
    return Promise.reject(error);
  }
}

function getCheckin(phash, shash) {
  // fetch for closed survey
  // console.log("getCheckin: ", phash, shash);
  /*  const options = {
     method: "GET",
     headers: {
       "Content-Type": "application/json",
     },
   };
 
   if (shash === null || shash === undefined || shash === '') {
     return apiService.fetch(`/polls/checkin/${phash}`, options);
   }
   return apiService.fetch(`/polls/checkin/${phash}/${shash}`, options); */

  const append = shash === null || shash === undefined || shash === '' ? '' : `/${shash}`;
  return apiService.GET(`/polls/checkin/${phash}${append}`);
}

function getQuestionnarie(phash, shash) {
  /* return d3.json("questionnaire.json"); */
  /*  const options = {
     method: "GET",
     headers: {
       "Content-Type": "application/json",
     },
   };
 
   return apiService.fetch(`/polls/questionnaire/${phash}/${shash}`, options); */

  return apiService.GET(`/polls/questionnaire/${phash}/${shash}`);
}

async function checkout(data) {
  /* return hasValidPhash(data).then(({ phash, shash }) => {
    delete data.phash;
    delete data.shash;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return apiService.fetch(`/polls/checkout/${phash}/${shash}`, options);
  }); */


  try {
    const { phash, shash } = await hasValidPhash(data);

    return apiService.GET(`/polls/checkout/${phash}/${shash}`);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function updateNode(data) {
  /* return hasValidPhash(data).then(({ phash, shash }) => {
    delete data.phash;
    delete data.shash;

    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    return apiService.fetch(`/polls/node/${phash}/${shash}`, options);
  }); */

  try {
    const { phash, shash } = await hasValidPhash(data);
    const payload = { ...data };
    delete payload.phash;
    delete payload.shash;

    return apiService.PUT(`/polls/node/${phash}/${shash}`, payload);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function updateAnswer(data) {
  /*  return hasValidPhash(data).then(({ phash, shash }) => {
     delete data.phash;
     delete data.shash;
 
     const options = {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify(data),
     };
 
     return apiService.fetch(`/polls/answer/${phash}/${shash}`, options);
   }); */

  try {
    const { phash, shash } = await hasValidPhash(data);
    const payload = { ...data };
    delete payload.phash;
    delete payload.shash;

    return apiService.POST(`/polls/answer/${phash}/${shash}`, payload);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

function hasValidPhash(data) {
  // console.log("hasValidPhash: ", data.phash, data.shash)
  /* return new Promise((resolve, reject) => {
    const phash = data.phash;
    const shash = data.shash;

    return !phash
      ? reject("phash or shash could not be found")
      : resolve({ phash, shash });
  }); */

  const phash = data.phash;
  const shash = data.shash;

  return !phash ? Promise.resolve("phash or shash could not be found") : Promise.resolve({ phash, shash });
}
