import { rulesService } from "@/services";
import { companyService } from "@/services";

const state = {
  reportingId: null,
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  options: {},
};

const actions = {
  async getAll({ commit, dispatch, getters }, options) {
    try {
      commit("loading");
      const opt = options ?? getters.options;
      const id = getters.reportingId;
      if (id === null) { return []; }
      const response = await rulesService.getAll({ reporting_id: id, options: opt });
      // console.log('opt:', opt);
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });
      return response;
    } catch (error) {
      commit("failure");
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async getAllByCompanyId({ commit, dispatch, rootGetters }, options) {
    try {
      commit("loading");
      const company_id = rootGetters["account/companyId"];
      // console.log('company_id:', company_id);
      if (company_id === -1) throw new Error("CompanyId could not be found!");
      const opt = options ?? getters.options;
      const response = await companyService.getRules({ company_id: company_id, options: opt });
      // console.log('rules getAllByCompanyId:', opt, response);
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });

      return response;
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async create({ dispatch }, data) {
    try {
      await rulesService.create(data);
      dispatch("getAll");
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async update({ dispatch }, data) {
    try {
      await rulesService.update(data);
      dispatch("getAll");
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async delete({ dispatch }, data) {
    try {
      await rulesService.delete(data);
      dispatch("getAll");
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async updateOptions({ commit, dispatch }, options) {
    await commit("setOptions", options);
    dispatch("getAll");
  },

  async updateReportingId({ commit, dispatch }, reporting_id) {
    await commit("clear");
    await commit("setReportingId", reporting_id);
    dispatch("getAll");
  },

  async downloadReport({ dispatch }, rule_hash) {
    const promise = rulesService.downloadReport(rule_hash)
    dispatch("alert/showProcess", {
      text: 'Bitte warten, generiert PDF-Bericht ...',
      promise: promise,
    }, { root: true })
  }
};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, value) {
    state.isLoading = false;
    state.error = value;
  },
  clear(state) {
    state.reportingId = null;
    state.items = [];
    state.totalItems = 0;
    state.isLoading = false;
    state.error = null;
    // state.options = {};
  },
  setOptions(state, options) {
    state.options = options;
  },
  setReportingId(state, reporting_id) {
    state.reportingId = reporting_id;
  }
};

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  options: (state) => state.options,
  reportingId: (state) => state.reportingId,
};

export const rules = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};


/* import { ApiMap } from "@/common/helpers";
import { rulesService } from "@/services";

const state = {
  rules: new ApiMap([], "rules/store_getRulesByReportingId"),
};

const actions = {
  store_getRulesByReportingId({ commit, dispatch }, reportingId) {
    rulesService.getByReportId(reportingId).then(
      (data) => {
        commit("getRulesByReportingIdSuccess", {
          reportingId,
          rules: data.items,
        });
      },
      (err) => {
        commit("getRulesByReportingIdFailure", {
          reportingId,
          error: err,
        });
        dispatch("alert/showAlertError", err, { root: true });
      }
    );
  },

  store_create({ dispatch }, data) {
    rulesService.create(data).then(
      () => {
        dispatch("alert/showAlertSuccess", "[ReportService]: Bericht wurde angelegt!", {
          root: true,
        });
      },
      (err) => {
        dispatch("alert/showAlertError", err, { root: true });
      }
    );
  },

  store_update({ dispatch }, data) {
    rulesService.update(data).then(
      () => {
        dispatch("store_getRulesByReportingId", data.reporting_id);
        dispatch(
          "alert/showAlertSuccess",
          "[ReportService]: Bericht erfolgreich aktualisiert!",
          { root: true }
        );
      },
      (err) => {
        dispatch("alert/showAlertError", err, { root: true });
      }
    );
  },

  store_delete({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      rulesService.delete(data).then(
        () => {
          dispatch(
            "alert/showAlertSuccess",
            "[ReportingsService]: Auswertung und Berichte wurden gelöscht!",
            {
              root: true,
            }
          );
          resolve(data);
        },
        (err) => {
          dispatch("alert/showAlertError", err, { root: true });
          reject(err);
        }
      );
    });
  },
};

const mutations = {
  getRulesByReportingIdSuccess(state, { reportingId, rules }) {
    state.rules.success(reportingId, rules);
  },
  getRulesByReportingIdFailure(state, { reportingId, error }) {
    state.rules.failure(reportingId, error);
  },
};

const getters = {
  rules: (state) => state.rules,
  getRuleById: (state) => (id) => {
    return state.rules.get(id);
  },
};

export const rules = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
 */