
import Vue from "vue";
import { startOfDay } from "date-fns";
import { notificationService } from "@/services";


const state = {
  alert: {
    current: null,
    items: [],
    groups: [
      { key: "all", name: "Alle", },
      { key: "activity", name: "Aktivitäten" },
      { key: "system", name: "Applikation" },
    ],
    drawer: {
      show: false,
    }
  },
  process: {
    items: {}
  },
}

const actions = {
  showAlertSuccess({ dispatch }, alert) {
    let a = null;
    if (typeof alert === "string") {
      a = { text: alert };
    } else if (typeof alert === "object") {
      a = { ...alert };
    }

    dispatch("showAlert", {
      type: "success",
      ...a,
    })
  },
  showAlertError({ dispatch }, alert) {
    let a = null;
    if (typeof alert === "string") {
      a = { text: alert };
    } else if (typeof alert === "object") {
      a = { ...alert };
    }

    dispatch("showAlert", {
      type: "error",
      ...a,
    })
  },
  showAlert({ commit, dispatch }, { type, text, config: { linkTo, timeout, createdAt } = {} }) {
    try {
      if (type === null) throw new Error("[Alerts]: type is required!");
      if (text === null) throw new Error("[Alerts]: text is required!");

      commit("showAlert", {
        type,
        text,
        linkTo,
        groups: ["all", "system"],
        timeout: timeout || 2000,
        createdAt: createdAt ?? new Date(Date.now()),
      });
    } catch (error) {
      dispatch("showAlert", { text: error.message, type: "error" });
      console.error(error);
    }
  },
  removeAlert({ commit }, item) {
    commit("removeAlert", item);
  },
  clearAlerts({ commit }) {
    commit("clearAlerts");
  },
  showProcess({ commit, dispatch, getters }, { text, promise, config: { successText, errorText, linkTo, timeout, showConfirmAlert = true } = {} }) {
    try {
      if (text === null || text === undefined)
        throw new Error("[Process]: text is required");
      if (promise === null || promise === undefined)
        throw new Error("[Process]: promise is required");

      const index = getters.processes.length;
      promise.then(() => {
        if (text != "") {
          if (showConfirmAlert) dispatch("showAlert", { type: "success", text: `${successText ?? text}`, config: { linkTo, timeout } },);
        }
      }).catch(() => {
        if (text != "") {
          if (showConfirmAlert) dispatch("showAlert", { type: "error", text: `${errorText ?? text}`, config: { linkTo, timeout } },);
        }
      }).finally(() => {
        commit("removeProcess", index);
      })

      commit("addProcess", {
        index,
        text,
        promise,
        successText,
        errorText,
        link: linkTo,
        timeout,
        createdAt: alert.createdAt ?? new Date(Date.now()),
      })
    } catch (error) {
      dispatch("showAlert", { text: error.message, type: "error" });
      console.error(error);
    }
  },
  setShowAlertDrawer({ commit }, value) {
    commit("setShowAlertDrawer", value);
  },
  async loadAlerts({ commit, rootGetters }) {
    try {
      const company_id = rootGetters["account/companyId"];
      commit("clearAlerts");
      const response = await notificationService.getAll(company_id);
      response.messages.forEach((message) => {
        commit("addAlert", {
          message_id: message.message_id,
          text: message.message,
          linkTo: message.linkto,
          type: message.type,
          groups: message.groups,
          createdAt: new Date(message.createdAt),
        })
      });
    } catch (error) {
      console.error(error);
    }
  },
  async markAllGroupAlertsAsRead({ dispatch, getters }, group) {
    const alerts = getters.alertsByGroup(group);
    Object.values(alerts).forEach((items) => {
      items.forEach((item) => {
        dispatch("markAlertAsRead", item)
      })
    });
  },
  async markAlertAsRead({ commit, dispatch }, item) {
    try {
      commit("removeAlert", item);
      if (item.message_id) {
        await notificationService.markAsRead(item.message_id);
      }
    } catch (error) {
      dispatch("showAlert", { text: error.message, type: "error" });
    }
  }
}

/* const handleAlertDelete = async (alert) => {
  if (alert.message_id === null || alert.message_id === undefined) return;

  try {
    //await notificationService.delete(alert.message_id);
  } catch (error) {
    console.error(error);
  }
}
 */

const mutations = {
  showAlert(state, alert) {
    state.alert.current = alert;
    state.alert.items.push(alert);
  },
  addAlert(state, alert) {
    state.alert.items.push(alert);
  },
  clearAlerts(state) {
    for (let i = state.alert.items.length - 1; i >= 0; i--) {
      state.alert.items.splice(i, 1);
    }
  },
  removeAlert(state, item) {
    const idx = state.alert.items.indexOf(item);
    if (idx < 0) return;
    state.alert.items.splice(idx, 1);
  },
  clearCurrentAlert(state) {
    state.alert.current = null;
  },
  addProcess(state, process) {
    Vue.set(state.process.items, process.index, process);
  },
  removeProcess(state, index) {
    Vue.delete(state.process.items, index);
  },
  setShowAlertDrawer(state, value) {
    state.alert.drawer.show = value;
  }
}

const getters = {
  currentAlert: (state) => state.alert.current,
  alerts: (state) => state.alert.items,
  alertGroups: (state) => state.alert.groups,
  alertsByGroup: (state) => (groups) => {
    const alerts = {};
    state.alert.items.forEach((alert) => {
      if (alert.groups !== null
        && alert.groups !== undefined
        && alert.groups.some(item => groups.includes(item))) {

        const date = startOfDay(alert.createdAt).toISOString();
        if (alerts[date] === null || alerts[date] === undefined) {
          alerts[date] = [];
        }

        alerts[date].push(alert);
      }
    });

    return alerts;
  },
  alertsByDate: (state) => {
    const alerts = {};
    state.alert.items.forEach((element) => {
      const date = startOfDay(element.createdAt).toISOString();
      if (!Object.prototype.hasOwnProperty.call(alerts, date)) {
        alerts[date] = [];
      }
      alerts[date].push(element);
    });

    return alerts;
  },
  getGroupCount: (state) => (group) => {
    let count = 0;
    state.alert.items.forEach((alert) => {
      if (alert.groups !== null
        && alert.groups !== undefined
        && alert.groups.includes(group)) {
        count++;
      }
    });
    return count;
  },
  showAlertDrawer: (state) => state.alert.drawer.show,
  processes: (state) => Object.values(state.process.items),
}

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
