var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataset,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('o-data-table-toolbar',{attrs:{"title":"Laufende Umfragen","search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"text":"","color":"primary","to":"/surveys"}},[_vm._v(" Alle anzeigen ")])],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('b',[_vm._v("Keine laufenden Umfragen vorhanden...")])]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('h3',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v(_vm._s(item.applicant))])]}},{key:`item.accesstype`,fn:function({ item }){return [_c('o-status-chip',{staticClass:"ml-2",attrs:{"value":item.accesstype,"options":_vm.accesstypeOptions,"dark":""}})]}},{key:`item.enddate`,fn:function({ item }){return [_c('o-date-time',{attrs:{"value":item.enddate_ux * 1000}})]}},{key:`item.survey_duration`,fn:function({ item }){return [_c('o-countdown',{attrs:{"value":item.enddate_ux * 1000}})]}},{key:`item.interviews`,fn:function({ item }){return [_c('o-share-indicator',{attrs:{"values":[
        {
          name: 'bereit',
          color: '#FEB019',
          value: item.n_base - item.n_started - item.n_completed,
        },
        {
          name: 'begonnen',
          color: '#00E396',
          value: item.n_started,
        },
        {
          name: 'fertiggestellt',
          color: '#008FFB',
          value: item.n_completed,
        },
      ]}})]}},{key:`item.activity`,fn:function({ item }){return [_c('o-survey-activity-chart',{attrs:{"value":item.survey_id,"height":200,"width":400}})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }