<template>
  <o-account>
    <div class="fill-height pa-0">
      <v-app-bar app flat dense clipped-right>
        <v-app-bar-nav-icon @click="nav.mini = !nav.mini"></v-app-bar-nav-icon>
        <v-spacer />
        <o-alert-menu />
        <o-account-menu />
      </v-app-bar>
      <v-navigation-drawer
        v-model="nav.drawer"
        :mini-variant="nav.mini"
        mini-variant-width="75"
        app
        permanent
      >
        <v-list class="py-0">
          <v-list-item three-line class="d-flex justify-center" to="/" link>
            <v-list-item-icon class="align-self-center">
              <v-img src="/logo.svg" />
            </v-list-item-icon>
            <v-list-item-title class="font-weight-bold text-h6">
              OrgaCheck
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list nav>
            <v-tooltip right v-for="(item, i) in navItems" :key="i">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  :to="item.to"
                  link
                  color="primary"
                  v-on="nav.mini ? on : {}"
                  v-bind="attrs"
                >
                  <v-list-item-icon>
                    <v-icon large>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6 pb-6 mt-3">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              {{ item.title }}
            </v-tooltip>
          </v-list>
        </v-list>
        <!--  
        <template v-slot:append>
          <v-divider />
          <v-list-item>
            <v-list-item-icon class="mr-2">
              <v-icon>mdi-label</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Ver. {{ versionText }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        -->
      </v-navigation-drawer>
      <v-main style="height: 100%">
        <o-data-protection-dialog />
        <o-router-view />
      </v-main>
    </div>
  </o-account>
</template>

<script>
import { mapGetters } from "vuex";
import { OAccountMenu, OAccount } from "@/modules/user/login";
import { OAlertMenu } from "@/modules/system/alert";
import ORouterView from "@/common/components/ORouterView.vue";
import ODataProtectionDialog from "@/common/components/ODataProtectionDialog.vue";

export default {
  name: "o-home-view",
  components: {
    OAlertMenu,
    ORouterView,
    OAccountMenu,
    OAccount,
    ODataProtectionDialog,
  },
  data() {
    return {
      company_name: null,
      company_id: null,
      nav: {
        drawer: true,
        mini: true,
        admin: [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            to: "/dashboard",
          },
          {
            title: "Organisationen",
            icon: "mdi-domain",
            to: "/companies",
          },
          {
            title: "Einstellungen",
            icon: "mdi-cog",
            to: "/settings",
          },
          {
            title: "Über",
            icon: "mdi-information",
            to: "/about",
          },
        ],
        user: [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            to: "/dashboard",
          },
          {
            title: "Organisation",
            icon: "mdi-family-tree",
            to: "/orgatree",
          },
          {
            title: "Fragebögen",
            icon: "mdi-clipboard-edit",
            to: "/questionary",
          },
          { title: "Umfragen", 
            icon: "mdi-clipboard-check", 
            to: "/surveys" },
          {
            title: "Auswertungen",
            icon: "mdi-chart-box",
            to: "/reportings",
          },
          { title: "Archiv", 
            icon: "mdi-archive-lock", 
            to: "/archive",
          },
          {
            title: "Einstellungen",
            icon: "mdi-cog",
            to: "/settings",
          },
          {
            title: "Über",
            icon: "mdi-information",
            to: "/about",
          },
        ],
      },
    };
  },
  computed: {
    // versionText() {
    //   return `${process.env.VUE_APP_VERSION}`;
    // },
    navItems() {
      return this.nav[this.role];
    },
    ...mapGetters("account", ["user", "role"]),
  },
};
</script>

<style scoped>
.full-height {
  height: 100%;
}
</style>
