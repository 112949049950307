<template>
  <o-tree />
</template>

<script>
import { OTree } from "@/modules/company/tree";
export default {
  components: { OTree },
  name: "o-tree-view",
};
</script>

<style></style>
