import { subjectsService } from "@/services";
import { surveysService } from "@/services";

const state = {
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  subjectsoptions: {},
};

const actions = {

  async getAllSubjectsPagination({ dispatch }, value) {
    
    // nur zur Überprüfung, ob die Funktion ein Promise zurückgibt (tut sie :-)
    // const result = subjectsService.getAllSubjects(survey_id);
    // if (result && typeof result.then === 'function') {
    //   console.log('subjectsService.getAllSubjects returns a Promise');
    // } else {
    //   console.log('subjectsService.getAllSubjects does not return a Promise');
    // }

    // console.log('getAllSubjectsPagination: ', value.survey.survey_id, value.options);

    const subjectsopt = value.options ?? getters.subjectsoptions;
    const survey_id = value.survey.survey_id;
    
    return new Promise((resolve, reject) => {
      subjectsService.getAllSubjectsPagination( survey_id, subjectsopt )
        .then(({ subjects }) => {
          resolve(subjects);
        })
        .catch((error) => {
          dispatch("alert/showAlertError", {
            text: `${error.status} ${error.statusText}: ${error.message}`,
          }, { root: true });
          reject(error);
        });
    });
  },

  async getAllSubjects({ dispatch }, data) {
    
    // nur zur Überprüfung, ob die Funktion ein Promise zurückgibt (tut sie :-)
    // const result = subjectsService.getAllSubjects(survey_id);
    // if (result && typeof result.then === 'function') {
    //   console.log('subjectsService.getAllSubjects returns a Promise');
    // } else {
    //   console.log('subjectsService.getAllSubjects does not return a Promise');
    // }
    console.log("Received data:", data);
    const survey_id = data.survey_id;
    return new Promise((resolve, reject) => {
      subjectsService.getAllSubjects(survey_id)
        .then(({ subjects }) => {
          resolve(subjects);
        })
        .catch((error) => {
          dispatch("alert/showAlertError", {
            text: `${error.status} ${error.statusText}: ${error.message}`,
          }, { root: true });
          reject(error);
        });
    });
  },

  async exportSubjects({ dispatch }, item) {
    
    function sanitizeFilenameSuffix(suffix) {
      return suffix.replace(/[^a-zA-Z0-9]/g, '');
    }
    
    try {
      const suffix = item.name || "export";
      const sanitizedSuffix = sanitizeFilenameSuffix(suffix);
      const exportFilename = `orgacheck-teilnehmerlinks-${sanitizedSuffix}.csv` || "export.csv"; // eslint-disable-line

      const rawdata = await subjectsService.exportSubjects(item.survey_id);
      const blob = new Blob([rawdata], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportFilename);
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", exportFilename);
      a.click();
  
      return Promise.resolve(rawdata);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async newgenerateSubjectKeys({ dispatch }, data) {
    // const mysurvey_id = String(data.survey_id);
    // const count = data.count;
    // console.log('subjects.module => generateSubjectKeys data: ', data, typeof data, typeof data.survey_id, data.survey_id, mysurvey_id, count);
    try {
      const result = await subjectsService.generateSubjectKeys(data.survey_id, data.count);
      // await dispatch("getAllSubjects", data);
      return Promise.resolve(result);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async deleteUnusedSubjects({ dispatch }, data) {
    try {
      const result = await subjectsService.deleteUnusedSubjects(data);
      return Promise.resolve(result);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      Promise.reject(error);
    }
  },
  
  async updateSubjectsOptions({ commit, dispatch }, value) {
    // console.log('!!! updateSubjectsOptions !!!: ', value.survey, value.options);
    commit("setSubjectsOptions", value.options);
    // dispatch("getAllSubjectsPagination", value);
  }

};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, error) {
    state.isLoading = false;
    state.error = error;
  },
  setSubjectsOptions(state, subjectsoptions) {
    state.subjectsoptions = subjectsoptions;
  }
}

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  subjectsoptions: (state) => state.subjectsoptions,
};

export const subjects = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
