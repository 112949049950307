<template>
  <a @click.prevent="open">
    <slot>{{ href }}</slot>
  </a>
  <!-- <div
    class="d-flex flex-row align-center"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div style="width: 30px">
      <v-icon v-show="hover">mdi-content-copy</v-icon>
    </div>
    <span class="d-inline-block text-truncate" style="max-width: 300px">
      {{ href }}
    </span>
    <div style="width: 30px">
      <v-icon v-show="hover">mdi-content-copy</v-icon>
    </div>
  </div> -->
  <!-- <div
    :style="{
      position: 'relative',
    }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    class="pa-2"
  >
    <span class="px-4">{{ href }}</span>
    <div
      v-show="hover"
      class="rounded-lg"
      :style="{
        'background-color': 'rgba(1, 1, 1, 0.5)',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
      }"
    >
      <v-row no-gutters align="center" justify="center" dense style="height: 100%">
        <v-col cols="auto">
          <v-icon dark>mdi-link-variant</v-icon>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn depressed rounded small color="primary">
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "o-link",
  props: {
    href: {
      type: String,
      default: "#",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    open() {
      let route = this.$router.resolve({ path: this.href });
      window.open(route.href);
    },
  },
};
</script>

<style></style>
