<template>
  <div v-if="company !== null">
    <h1 class="pb-2">{{ company.name }}</h1>
    <h4 class="pb-2">{{ company.address }}</h4>
    <h4 class="pb-8">{{ company.zip }} {{ company.city }}</h4>
    <o-change-email-form />
    <o-change-password-form />
    <o-delete-account-request />
    <!--<h3 class="pb-3">Konto Löschen</h3>
    <span style="display: block" class="pb-6">
      Möchten Sie ihr Konto Löschen?<br />
      <br />
      Diese Konto beinhaltet <b>1000 Umfragen und 500 Auswertungen</b>. Löschen
      des Kontos beantragt das Löschen jeglicher assoziereten inhalte.
    </span>
    <a style="color: red">Ich möchte mein Konto Löschen</a> -->
  </div>
</template>

<script>
import {
  OChangeEmailForm,
  OChangePasswordForm,
  ODeleteAccountRequest,
} from "@/modules/user/settings";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "o-account-settings-view",
  components: { OChangeEmailForm, OChangePasswordForm, ODeleteAccountRequest },
  data() {
    return {
      company: null,
    };
  },
  async mounted() {
    this.company = await this.getCompany(this.user.company_id);
    // console.log("name: ", this.company);
  },
  methods: {
    ...mapActions("company", {
      getCompany: "get",
    }),
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style></style>
