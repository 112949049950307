<template>
  <div class="py-8">
    <o-center column>
      <v-card flat rounded="xl" class="py-12 px-6" max-width="940px">
        <v-icon size="192" class="d-flex flex-column" color="success">
          mdi-check-circle-outline
        </v-icon>
        <v-card-title class="text-h5">
          <span>
            <b>{{ value.survey_company_name }}</b> bedankt sich für ihre
            Teilnahme!
          </span>
        </v-card-title>
        <!-- <v-card-text>
          Ihr Ergebniss wird eingereicht und ausgewertet. Diese bekommen sie von
          Ihrer Zugehörigen Leitung nach Abschluss der Umfrage.
        </v-card-text> -->
        <v-card-text>
          <div class="text-subtitle-1" v-html="value.goodbye_text" />
        </v-card-text>
      </v-card>
    </o-center>
    <v-footer app outlined height="90" />
  </div>
</template>

<script>
import OCenter from "@/common/components/OCenter.vue";

export default {
  name: "o-poll-farewell",
  components: {
    OCenter,
  },
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style></style>
