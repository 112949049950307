<template>
  <o-reportings-table />
</template>

<script>
import { OReportingsTable } from "@/modules/company/reportings";

export default {
  name: "o-reportings-view",
  components: { OReportingsTable },
};
</script>

<style></style>
