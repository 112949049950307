<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2"> Details </v-list-item-title>
        <v-container fluid>
          <v-text-field
            v-model="name"
            rounded
            filled
            label="Name *"
            :rules="rules"
            disabled
          />
          <v-text-field
            v-model="applicant"
            rounded
            filled
            label="Auftraggeber"
            disabled
          />
          <div>
            <v-textarea
              v-model="description"
              rounded
              filled
              label="Beschreibung"
              auto-grow
              counter="256"
              disabled
            />
          </div>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2">Zugang</v-list-item-title>
        <v-container fluid>
          <v-radio-group row v-model="accessType">
            <v-radio
              label="closed (personalisierter Zugang)"
              value="closed"
              disabled
            />
            <v-radio 
              label="open (Hash wird erzeugt)" 
              value="open" 
              disabled
            />
          </v-radio-group>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2">
          Nachrichten
        </v-list-item-title>
        <v-container fluid>
          <v-textarea
            v-model="page_welcome"
            rounded
            filled
            label="Willkommen"
            auto-grow
            counter="8096"
            disabled
          />
          <!--    <v-textarea
            v-model="page_feedback"
            rounded
            filled
            label="Feedback"
            auto-grow
            counter="8096"
          /> -->
          <v-textarea
            v-model="page_bye"
            rounded
            filled
            label="Verabschiedung"
            auto-grow
            counter="8096"
            disabled
          />
          <v-textarea
            v-model="page_error"
            rounded
            filled
            label="Fehler Meldung"
            auto-grow
            counter="8096"
            disabled
          />
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "o-archive-form",
  props: {
    value: {
      type: Object,
      required: true,
    },
    reload: Boolean,
  },
  data() {
    return {
      rules: [(v) => !!v],
    };
  },
  watch: {
    reload() {
      if (this.reload) {
        this.getSurvey();
        this.$emit("update:reload", false);
        this.$emit("reload");
      }
    },
  },
  methods: {
    updateValue(key, value) {
      let item = { ...this.value };
      item[key] = value;
      this.$emit("input", item);
    },
  },
  computed: {
    name: {
      get() {
        return this.value.name;
      },
      set(value) {
        this.updateValue("name", value);
      },
    },
    applicant: {
      get() {
        return this.value.applicant;
      },
      set(value) {
        this.updateValue("applicant", value);
      },
    },
    questionnaire_id: {
      get() {
        return this.value.questionnaire_id;
      },
      set(value) {
        this.updateValue("questionnaire_id", value);
      },
    },
    questionnaires: {
      get() {
        return this.value.questionnaires;
      },
      set(value) {
        this.updateValue("questionnaires", value);
      },
    },
    description: {
      get() {
        return this.value.description;
      },
      set(value) {
        this.updateValue("description", value);
      },
    },
    accessType: {
      get() {
        return this.value.accesstype;
      },
      set(value) {
        this.updateValue("accesstype", value);
      },
    },
    page_welcome: {
      get() {
        return this.value.page_welcome;
      },
      set(value) {
        this.updateValue("page_welcome", value);
      },
    },
    page_bye: {
      get() {
        return this.value.page_bye;
      },
      set(value) {
        this.updateValue("page_bye", value);
      },
    },
    page_error: {
      get() {
        return this.value.page_error;
      },
      set(value) {
        this.updateValue("page_error", value);
      },
    },
    page_feedback: {
      get() {
        return this.value.page_feedback;
      },
      set(value) {
        this.updateValue("page_feedback", value);
      },
    },
    startdate: {
      get() {
        return this.value.startdate;
      },
      set(value) {
        this.updateValue("startdate", value);
      },
    },
    enddate: {
      get() {
        return this.value.enddate;
      },
      set(value) {
        this.updateValue("enddate", value);
      },
    },
  },
};
</script>

<style></style>
