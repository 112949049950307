<template>
  <o-chart-editor v-model="tree" @refresh="load" @save="save" />
</template>

<script>
import { mapActions } from "vuex";
import OChartEditor from "./OChartEditor.vue";

export default {
  name: "o-tree",
  components: { OChartEditor },
  data() {
    return {
      saved: false,
      timeout: 3000,
      tree: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const response = await this.getTreeByCompanyId();
      this.tree = { ...response };
    },
    async save() {
      try {
        await this.update(this.tree);
        this.showAlertSuccess(
          "Organisationsbaum wurde erfolgreich gespeichert"
        );
      } catch (error) {
        console.log(error);
      }
    },
    async update(value) {
      if (this.saved) return;

      try {
        await this.updateTree(value);
        this.saved = true;
        setTimeout(() => (this.saved = false), this.timeout);
      } catch (error) {
        console.error(error);
      }
    },
    ...mapActions("tree", {
      getTreeByCompanyId: "getByCompanyId",
      updateTree: "update",
    }),
    ...mapActions("alert", ["showAlertSuccess"]),
  },
  watch: {
    tree: {
      handler: function (value) {
        this.update(value);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
