import { companyService, treeService } from "@/services";

function parseTreeData(data) {
  const tree = data;
  tree.graph = JSON.parse(tree.graph);
  tree.color_groups = JSON.parse(tree.color_groups);
  tree.reporting_groups = JSON.parse(tree.reporting_groups);
  tree.reporting_categories = JSON.parse(tree.reporting_categories);
  tree.config = JSON.parse(tree.config);
  //tree.categories = JSON.parse(tree.categories);
  return tree;
}

const state = {
  isLoading: false,
  tree: null,
};

const actions = {
  async getByCompanyId({ commit, dispatch, rootGetters }) {
    try {
      commit("getRequest");

      const company_id = rootGetters["account/companyId"];
      const response = await companyService.getTree(company_id);
      const tree = parseTreeData(response.trees[0]);

      commit("getSuccess", tree);

      return tree;
    } catch (error) {
      commit("getFailure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async getByCompanyIdParameter({ commit, dispatch }, companyId) {
    try {
      commit("getRequest");
      
      const response = await companyService.getTree(companyId);
      const tree = parseTreeData(response.trees[0]);

      commit("getSuccess", tree);

      return tree;
    } catch (error) {
      commit("getFailure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async getByTreeId({ commit, dispatch }, treeId) {
    try {
      commit("getRequest");

      const response = await treeService.get(treeId);
      // const tree = parseTreeData(response);
      const tree = parseTreeData(response.trees[0]);
      commit("getSuccess", tree);
      // dispatch("alert/showAlertSuccess", `Baum #${tree.tree_id} wurde erfolgreich geladen`, {
      //   root: true,
      // });
      return tree;
    } catch (error) {
      commit("getFailure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async getPollTreeBySurveyId({ commit, dispatch }, surveyId) {
    try {
      commit("getRequest");
      const response = await treeService.getPollTreeBySurveyId(surveyId);
      const tree = parseTreeData(response);
      return tree;
    } catch (error) {
      commit("getFailureSurveyTrees", { id: surveyId, error });
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async getFieldTreeBySurveyId({ commit, dispatch }, surveyId) {
    try {
      commit("getRequest");
      const response = await treeService.getFieldTreeBySurveyId(surveyId);
      const tree = parseTreeData(response);
      return tree;
    } catch (error) {
      commit("getFailureSurveyTrees", { id: surveyId, error });
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async update({ dispatch }, data) {
    try {
      if (!data || !data.tree_id) {
        return Promise.resolve(false);
      }

      await treeService.update(data.tree_id, data);
      dispatch("getByCompanyId");

      return Promise.resolve(true);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async updateValue({ commit }, { key, value }) {
    commit("updateValue", { key, value });
  },
  async delete({ dispatch }, id) {
    try {
      await treeService.delete(id);
      dispatch("alert/showAlertSuccess", `Tree ${id} wurde gelöscht`, {
        root: true,
      });
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async create({ dispatch }, companyId) {
    try {
      await treeService.create(companyId);
      dispatch(
        "alert/showAlertSuccess",
        `Tree for company ${companyId} wurde angelegt`,
        {
          root: true,
        }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async getNodeCount({ dispatch }, id) {
    try {
      const count = await treeService.getNodeCount(id);

      return Promise.resolve(count.n_nodes);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
};

const mutations = {
  getRequest(state) {
    state.isLoading = true;
  },
  getSuccess(state, tree) {
    state.tree = tree;
    state.isLoading = false;
  },
  getFailure(state, error) {
    state.tree = error;
    state.isLoading = false;
  },
  updateValue(state, { key, value }) {
    state.tree[key] = value;
  }
};

const getters = {
  tree: (state) => state.tree,
  isLoading: (state) => state.isLoading,
};

export const tree = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
