<template>
  <v-item-group class="pt-10" v-model="_value">
    <v-row align="center" align-content="center" justify="center">
      <v-col v-for="(item, i) in scalar" :key="i">
        <o-center>
          <v-item v-slot="{ active, toggle }" :value="i + 1">
            <v-icon
              :color="active ? getColor(item) : ''"
              @click="toggle"
              size="3em"
              style="flex: 0 0 auto"
            >
              {{ getIcon(item) }}
            </v-icon>
          </v-item>
        </o-center>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
import OCenter from "@/common/components/OCenter.vue";

export default {
  name: "o-poll-scalar-icon",
  components: {
    OCenter,
  },
  props: {
    value: {
      type: Number,
    },
    scalar: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getIcon(value) {
      return typeof value === "object" ? value.icon : value;
    },
    getColor(value) {
      const color = typeof value === "object" ? value.color : "primary";
      return color ?? "primary";
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.icon-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
