var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('o-rules-edit-drawer',{model:{value:(_vm.edit.item),callback:function ($$v) {_vm.$set(_vm.edit, "item", $$v)},expression:"edit.item"}}),_c('v-data-table',{attrs:{"dense":"","items":_vm.items,"server-items-length":_vm.totalItems,"headers":_vm.table.headers,"hide-default-footer":"","height":_vm.height,"fixed-header":"","loading":_vm.isLoading,"search":_vm.search,"items-per-page":-1,"item-key":"rule_id"},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[(_vm.reporting_reference != null)?_c('v-toolbar-title',[_c('v-toolbar-title',{staticClass:"text-h5"},[_c('span',{staticClass:"berichte-title"},[_vm._v("Berichte:")]),_c('span',{staticClass:"text-h7"},[_vm._v(" "+_vm._s(_vm.transformedReportingReference)+" ")])])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.editItem(_vm.edit.default)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Neuer Bericht ")],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('b',[_vm._v("Keine Berichte vorhanden...")])]},proxy:true},{key:`item.index`,fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:`item.name`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.rulelocator)+" --> "+_vm._s(item.hash))])])]}},{key:`item.split_typus`,fn:function({ item }){return [_c('o-status-chip',{attrs:{"small":"","value":item.split_typus,"options":{
          category: {
            name: 'Kategoriebericht',
            color: '#E96F1E',
          },
          node: {
            name: 'Abteilungsbericht',
            color: '#E13568',
          },
          company: {
            name: 'Organisationsbericht',
            color: '#B3BF1D',
          },
        }}})]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.n_completed < 3},on:{"click":function($event){return _vm.openPDF(item.hash)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-pdf-box")])],1)]}}],null,true)},[_vm._v(" Bericht herunterladen ")]),_c('v-menu',{attrs:{"offset-y":"","rounded":"lg","offset-overflow":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Bericht bearbeiten")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyToClipboard(item.hash)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(" mdi-content-copy ")])],1),_c('v-list-item-title',[_vm._v("Link kopieren")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v("Bericht löschen")])],1)],1)],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }