import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import plugins from "@/plugins";

import pdfview from '@/plugins/pdfview';
Vue.use(pdfview);

Vue.config.productionTip = false;

console.log(`Start Environmemnt: ${process.env.VUE_APP_API_ENV}`);
if (process.env.VUE_APP_API_ENV !== 'development')  {
  console.log('Disabling console.log in production and staging');
  console.log = function() {};
} else {  
  console.log(`console.log enabled - Environment: ${process.env.VUE_APP_API_ENV}`);
}

new Vue({
  router,
  store,
  ...plugins,
  render: (h) => h(App),
}).$mount("#app");
