import { jwtService } from "./jwt.service";
import router from "@/router";
import axios from "axios";

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  header: {
    "Content-Type": "application/json",
  }
})


http.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  const err = {
    status: error.response.status || 0,
    statusText: error.response.statusText || "Fehler",
    message: error.response.data.message || (error.response.data.error && error.response.data.error.message),
  };

  // Unauthorized
  if (router.currentRoute.path !== "/login"
    && error.response.status === 401) {
    router.replace("/login");
    jwtService.remove();
  }

  throw err;
})


async function GET(url, params, headers = {}) {
  return http.get(url, {
    headers: { ...await jwtService.header(), ...headers },
    params: params,
  });
}

async function PUT(url, data, headers = {}) {
  return http.put(url, data, {
    headers: { ...await jwtService.header(), ...headers },
  });
}

async function POST(url, data, headers = {}) {
  return http.post(url, data, {
    headers: { ...(await jwtService.header()), ...headers },
  });
}

async function DELETE(url, params, headers = {}) {
  return http.delete(url, {
    headers: { ...await jwtService.header(), ...headers },
    params,
  });
}

export const apiService = {
  http,
  fetch,
  GET,
  PUT,
  POST,
  DELETE,
};