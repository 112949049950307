<template>
  <v-navigation-drawer :value="show" fixed right clipped width="100%">
    <template v-slot:append>
      <v-divider />
      <v-container class="pa-0" v-if="page === 1 && show">
        <v-toolbar flat>
          <v-btn color="error" depressed text @click.prevent.stop="close">
            <v-icon left>mdi-cancel</v-icon>
            Abbruch
          </v-btn>
          <v-spacer />
          <v-btn
            text
            @click="page = 2"
            :disabled="!valid || item.short_subcategories.length <= 0"
          >
            Fortfahren
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </v-container>
      <v-container class="pa-0" v-if="page === 2">
        <v-toolbar flat>
          <v-btn text @click="--page">
            <v-icon left>mdi-chevron-left</v-icon>
            Zurück
          </v-btn>
          <v-spacer />
          <v-btn color="primary" depressed text @click.prevent.stop="save">
            <v-icon left> mdi-checkbox-marked-circle </v-icon>
            Speichern
          </v-btn>
        </v-toolbar>
      </v-container>
    </template>

    <v-container v-if="show" class="mt-10">
      <v-stepper v-model="page" flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="page > 1" step="1">
            Fragebogen Eigenschaften
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="page > 2" step="2">
            Zusammenfassung
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="5">
                    <v-card flat>
                      <v-card-title v-if="item">
                        {{ item.name }} (ID {{ item.questionnaire_id }})
                      </v-card-title>
                      <div class="pl-4">
                        Fragebogenname ist eine Pflichtangabe.
                      </div>
                      <v-divider />
                      <v-card-text>
                        <v-form v-model="valid">
                          <p>Name *</p>
                          <v-text-field
                            v-model="item.name"
                            rounded
                            filled
                            placeholder="Name"
                            :rules="[(v) => !!v]"
                          />
                          <p>Beschreibung</p>
                          <v-textarea
                            v-model="item.description"
                            rounded
                            filled
                            placeholder="Beschreibung"
                            auto-grow
                          />
                        </v-form>
                        <small class="text--secondary">Pflichtfeld *</small>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="7">
                    <v-card flat>
                      <v-card-title>Module</v-card-title>
                      <div class="pl-4" v-if="!isEditAllowed">
                        Module können nicht mehr verändert werden, weil Fragebogen bereits verwendet wird.
                      </div>
                      <div class="pl-4" v-if="isEditAllowed">
                        Bitte wählen Sie mindestens ein Fragebogenmodul aus.
                      </div>
                      <v-divider />
                      <v-card-text>
                        <o-scrollable max-height="80vh">
                          <o-categorie-treeview
                            v-model="item.short_subcategories"
                            :long.sync="item.long_subcategories"
                            :editallowed="isEditAllowed"
                          />
                        </o-scrollable>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="5">
                    <v-card flat>
                      <v-card-title>Allgemeine Informationen</v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-card flat>
                          <v-card-title>Name</v-card-title>
                          <v-card-text>
                            {{ item.name }}
                          </v-card-text>
                        </v-card>
                        <v-card flat>
                          <v-card-title>Beschreibung</v-card-title>
                          <v-card-text>
                            {{ item.description }}
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="7">
                    <v-card flat>
                      <v-card-title>Module</v-card-title>
                      <v-divider />
                      <v-card-text>
                        <o-scrollable max-height="80vh">
                          <o-categorie-summary 
                            :item="item" 
                            :editallowed="isEditAllowed"
                          />
                        </o-scrollable>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import OScrollable from "@/common/components/OScrollable.vue";
import {
  OCategorieTreeview,
  OCategorieSummary,
} from "@/modules/company/questions";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "o-questionary-edit-drawer",
  components: {
    OScrollable,
    OCategorieTreeview,
    OCategorieSummary,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,
      item: null,
      page: 1,
      editallowed: true,
    };
  },
  methods: {
    close() {
      this.$emit("input", null);
      this.page = 1;
    },
    save() {
      if (!this.item || !this.item.company_id) {
        // console.warn(
        //   "could not update or create questionary. because item was null or company_id did not exist!"
        // );
        return;
      }

      var handler = !this.item.questionnaire_id
        ? this.createQuestionary
        : this.updateQuestionary;
      handler(this.item);

      this.close();
    },
    ...mapActions("questionary", {
      createQuestionary: "create",
      updateQuestionary: "update",
    }),
  },
  computed: {
    show() {
      return this.item !== null && this.item !== undefined;
    },
    isEditAllowed() {
      if (this.item.status === 'active') {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    value(value) {
      this.item = value;
      if (this.item) {
        if (!this.item.company_id) {
          this.item.company_id = this.user.company_id;
        }
      }
    },
  },
};
</script>

<style></style>
