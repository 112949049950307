<template>
  <v-card class="text-center pa-16" flat>
    <o-logo class="pb-16" />
    <h1 class="text-h2">{{ status }} - {{ statusText }}</h1>
    <p class="text-body-1 py-6">{{ message }}</p>
  </v-card>
</template>

<script>
import OLogo from "./OLogo.vue";

export default {
  name: "o-error-card",
  components: { OLogo },
  props: {
    status: {
      type: Number,
      default: 400,
    },
    statusText: {
      type: String,
      default: "Bad Request",
    },
    message: {
      type: String,
      default: "Hello World",
    },
  },
};
</script>

<style></style>
