<template>
  <v-container class="py-12">
    <v-row>
      <v-col>
        <v-card flat color="transparent">
          <v-card-title> Über OrgaCheck </v-card-title>
            <o-version />
          <v-card-actions class="d-flex flex-row">
            <v-btn
              v-for="(item, i) in vleadButtons"
              :key="i"
              color="primary"
              depressed
              class="ma-2 text-subtitle-2 text-capitalize"
              :href="item.pdf"
              target="_blank"
            >
              {{ item.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat color="transparent">
          <v-card-title> Wissenswertes </v-card-title>
          <v-card-text>
            Vertiefen Sie Ihr Wissen zum Thema Digitalisierung,
            Organisationsanalysen und MTO-Ansatz, auf dem der OrgaCheck basiert.
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-for="(item, i) in knowledgeButtons"
              :key="i"
              color="primary"
              depressed
              class="ma-2 text-subtitle-2 text-capitalize"
              :href="item.pdf"
              target="_blank"
            >
              {{ item.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat color="transparent">
          <v-card-title> Datenschutz </v-card-title>
          <v-card-text>
            Informieren Sie sich hier über unsere Datenschutzbedingungen.
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-for="(item, i) in protectionButtons"
              :key="i"
              color="primary"
              depressed
              class="ma-2 text-subtitle-2 text-capitalize"
              :href="item.pdf"
              target="_blank"
            >
              {{ item.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat color="transparent">
          <v-card-title> Tipps & Tricks </v-card-title>
          <v-card-text>
            Sie wissen nicht so richtig, wie Sie mit den Ergebnissen Ihres
            OrgaChecks fortfahren sollen? Finden Sie hier Tipps, wie Sie die
            Ergebnisse in der Organisation präsentieren können und wie Sie einzelne
            Themen, die für Sie im Fokus stehen, in der Praxis angehen können.
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-for="(item, i) in hintButtons"
              :key="i"
              color="primary"
              depressed
              class="ma-2 text-subtitle-2 text-capitalize"
              :href="item.pdf"
              target="_blank"
              :disabled="item.disabled != null && item.disabled == true"
            >
              {{ item.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-img src="/assets/images/logo-foerderer.jpg" contain class="mt-12" />
    <v-dialog
      v-model="$pdf.view"
      persistent
      max-width="80%"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card tile height="90vh" color="transparent">
        <v-card-title>
          <span>{{ $pdf.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="$pdf.view = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <embed v-if="$pdf.view" :src="$pdf.file" width="100%" class="pdfview" />
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
import { OVersion } from "@/modules/system/version";

export default {
  name: "o-about-view",
  components: { OVersion },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      vleadButtons: [{ text: "Impressum", pdf: "./pdfs/impressum.pdf" }],
      protectionButtons: [
        {
          text: "Datenschutzerklärung",
          pdf: "./pdfs/datenschutzerklaerung.pdf",
        },
      ],
      knowledgeButtons: [
        { text: "Digitalisierung", pdf: "./pdfs/digitalisierung.pdf" },
        { text: "MTO-Ansatz", pdf: "./pdfs/mto-ansatz.pdf" },
        {
          text: "Organisationsanalyse (Herkunft & Zweck)",
          pdf: "./pdfs/unternehmensanalyse.pdf",
        },
      ],
      hintButtons: [
        {
          text: "Wie sind die Ergebnisse aufgebaut?",
          pdf: "./pdfs/OC_Wie-sind-die-Ergebnisse-aufgebaut.pdf",
        },
        {
          text: "Umgang mit den Ergebnissen",
          pdf: "./pdfs/OC_Umgang-mit-den-Ergebnissen.pdf",
        },
      ],
    };
  },
};
</script>

<style></style>
