<template>
  <section>
    <h3 class="pb-3">Konto Löschen</h3>
    <span style="display: block" class="pb-6">
      Möchten Sie ihr Konto Löschen?<br />
      <br />
      Ihr Konto beinhaltet
      <b>
        {{ this.surveyCount }} Umfragen und
        {{ this.reportingsCount }} Auswertungen.
      </b>
        <br />
        <b>Bitte beachten Sie:</b> Mit der Löschung Ihres Kontos werden auch alle verbundenen Inhalte gelöscht!<br />
    </span>
    <a style="color: red" @click.prevent="requestDelete">
      Ich möchte mein Konto löschen.
    </a>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "o-delete-account-request",
  data() {
    return {
      surveyCount: 0,
      reportingsCount: 0,
    };
  },
  async mounted() {
    const response = await Promise.all([
      this.getSurveyCount(),
      this.getReportCount(),
    ]);

    this.surveyCount = response[0];
    this.reportingsCount = response[1];
  },
  methods: {
    async requestDelete() {
      const permission = await this.awaitModal({
        title: "Konto Löschen?",
        message: "Sind sie sich sicher das Löschen Ihres Kontos zu beantragen?",
      });

      if (permission) {
        this.requestAccountDelete(this.user.user_id);
      }
    },
    ...mapActions("account", {
      requestAccountDelete: "requestDelete",
    }),
    ...mapActions("surveys", ["getSurveyCount"]),
    ...mapActions("reportings", ["getReportCount"]),
    ...mapActions("modal", ["awaitModal"]),
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style></style>
