<template>
  <v-btn
    v-on="$listeners"
    v-bind="{ ...$attrs, ...$props }"
    @click.prevent="handler"
    @submit="handler"
    :loading="loading"
  >
    <slot> </slot>
  </v-btn>
</template>

<script>
export default {
  name: "o-async-btn",
  props: {
    click: {
      type: Function,
      default: () => {
        return new Promise((resolve, reject) => {
          setTimeout(resolve, 1000);
        });
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handler(evt) {
      if (this.loading) return;
      this.loading = true;
      //const { target } = evt;
      try {
        await this.click();
        this.loading = false;
        //target.parentNode.dispatchEvent(evt);
      } catch (err) {
        this.loading = false;
      }

      return false;
    },
  },

  /* onProductClick = (e) => {
    const { target, nativeEvent } = e;
    const clonedNativeEvent = new MouseEvent('click', nativeEvent);

    if (!this.realClick) {
      this.realClick = true;
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    // @todo what you want before the link is acted on here

    this.realClick = false;
    target.dispatchEvent(clonedNativeEvent);
  } */
};
</script>

<style></style>
