<template>
  <v-tooltip bottom open-delay="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        @click.prevent="setShowAlertDrawer(!showAlertDrawer)"
        v-on="on"
        v-bind="attrs"
      >
        <v-badge
          :content="alerts.length"
          :value="alerts.length > 0"
          color="primary"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    Benachrichtigungen
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "o-alert-drawer-btn",
  computed: {
    ...mapGetters("alert", ["alerts", "showAlertDrawer"]),
  },
  methods: {
    ...mapActions("alert", ["setShowAlertDrawer"]),
  },
};
</script>

<style></style>
