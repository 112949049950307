<template>
    <v-navigation-drawer 
        :width="'95vw'" 
        :value="show_subjectmanager" 
        style="z-index: 400"
        ref="subjectmanager" 
        absolute 
        right
    >
        <template v-slot:prepend>
            <v-toolbar dense flat>
                <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click.prevent.stop="close">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <span>zurück</span>
                </v-tooltip>
                <v-tooltip bottom :open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="display: flex; align-items: center;">
                            <v-icon size="26" :color="umfrage_statusfarbe()" left>mdi-account-group</v-icon>
                            <v-toolbar-title class="pl-2">
                                {{ subjectsCount }} Umfrageteilnehmer
                            </v-toolbar-title>
                        </div>
                    </template>
                    <span>{{ survey_titlestatus() }} </span>
                </v-tooltip>
                <v-spacer />
                <o-search dense v-model="search" :placeholder="searchPlaceholder"/>
                <v-toolbar-items>
                    <v-tooltip bottom v-if="subjectsCount">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.prevent.stop="refreshData">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>aktualisieren</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="button_exportSubjectsLinks()" icon v-bind="attrs" v-on="on" @click.prevent.stop="exportSurveySubjectsList">
                                <v-icon>mdi-account-arrow-down-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Links exportieren</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="button_generateSubjects()" icon v-bind="attrs" v-on="on" @click.prevent.stop="generateSurveySubjects">
                                <v-icon>mdi-account-key</v-icon>
                            </v-btn>
                        </template>
                        <span>Teilnehmerschlüssel generieren</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="button_resetSubjects()" icon v-bind="attrs" v-on="on" @click.prevent.stop="deleteUnusedSurveySubjects">
                                <v-icon color="error">mdi-account-cancel-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Freie Teilnehmerschlüssel löschen</span>
                    </v-tooltip>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
        </template>

        <o-app-modal />

        <o-subject-generate-dialog
            v-model="showGenerateDialog"
            :survey_id="getSurveyId()"
            :company_id="getCompanyId()"
            :name="getSurveyName()"
            :override="true"
            @generation-complete="onGenerationComplete"
        />

        <v-data-table
            v-resize="resizeDataTable"
            :items="subjectsArray"
            :loading="isLoading"
            :headers="headers"
            :height="height"
            fixed-header
            item-key="shash"
            :search="search"
            :custom-filter="filter"
            :options="subjectsoptions"
            @update:options="updateSurveySubjectsOptions"
            class="custom-margin-subjects-table"
            >
            <template v-slot:[`item.index`]="{ index }">
                {{ index + 1 }}
            </template>
            <template v-slot:[`item.poll_url`]="{ item }">
                <span class="d-inline-block text-truncate" style="max-width: 300px">
                <o-link :href="item.poll_url">
                    {{ item.shash }}
                </o-link>
                </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="statusColor(item.status)" dark small>
                {{ statusName(item.status) }}
                </v-chip>
            </template>
            <template v-slot:[`item.last_reaction`]="{ item }">
                <!--  {{ new Date(item.last_reaction).toLocaleString("de-DE") }} -->
                {{ $utils.formatDate(new Date(item.last_reaction)) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left :open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    icon
                    @click.prevent="urlToClipboard(item.poll_url)"
                    v-on="on"
                    v-bind="attrs"
                    >
                    <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                </template>
                Fagebogenlink kopieren
                </v-tooltip>
            </template>
        </v-data-table>
    
    </v-navigation-drawer>
  </template>
  
  <script>
    import { mapActions, mapGetters } from "vuex";
    import OLink from "@/common/components/OLink.vue";
    import OSearch from "@/common/components/OSearch.vue";
    import OSubjectGenerateDialog from "./OSubjectGenerateDialog.vue";
    import { OAppModal } from "@/modules/system/modal";

  export default {
    components: { OSearch, OLink, OSubjectGenerateDialog, OAppModal },
    name: 'OSurveySubjectsManager',
    props: {
      survey: {
        type: Object,
        required: false, // Prop ist jetzt optional
        default: () => null // Standardwert ist null
      },
      show_subjectmanager: {
        type: Boolean,
        required: true
        }
    },
    data() {
      return {
        items: [],
        subjects: [],
        subjectsArray: [], // Neues Array für die konvertierten Werte
        subjectsCount: 0,
        surveytitlestatus: "not defined",
        newtotalItems: 0,
        height: '78vh',
        search: "",
        searchPlaceholder: "Teilnehmerschlüssel",
        showGenerateDialog: false,
        sm_company_id: null,
        sm_survey_id: null,
        sm_survey_name: null,
        sm_name: null,
        headers: [
            {
                text: "Teilnehmerschlüssel",
                value: "poll_url",
                align: "start",
            },
            {
                text: "Status",
                value: "status",
                align: "start",
            },
            {
                text: "Abteilung",
                value: "node_name",
                align: "start",
            },
            {
                text: "Ebene",
                value: "node_level",
                align: "start",
            },
            {
                text: "Kategorien",
                value: "node_category_names",
                align: "start",
            },
            {
                text: "Letzte Aktivität",
                value: "last_reaction",
                align: "start",
            },
            {
                text: "",
                value: "actions",
                align: "end",
            },
        ],
        status: {
            ready: {
                name: "bereit",
                color: "#FEB019",
            },
            start: {
                name: "begonnen",
                color: "#00E396",
            },
            complete: {
                name: "fertiggestellt",
                color: "#008FFB",
            },
            all: {
                name: "alle",
                color: "magenta",
            },
        },
      };
    },
    async created() {
        await this.loadSubjects();
    },
    computed: {
        ...mapGetters("account", ["user"]),
        ...mapGetters("subjects", ["subjectsoptions", "isLoading"]),
        overrideKeys() {
            return this.subjects && this.subjects.length >= 1;
        },
    },
    mounted() {
        if (this.survey) {
            this.sm_survey_name = this.survey.name;    
            this.sm_survey_id = this.survey.survey_id;
        } 
        this.sm_company_id = String(this.user.company_id);
    },
    watch: {
        subjects(newVal) {
            this.subjectCount = newVal.length;
        },
        show_subjectmanager(newValue, oldValue) {
            console.log(`show_subjectmanager hat sich geändert: ${oldValue} -> ${newValue}`);
        },
    },
    methods: {
        survey_titlestatus() {
            if (this.survey === null || this.survey === undefined) return 'not defined';
            const status = this.survey.status === 'active' ? 'aktiv' : 'inaktiv';
            const zugang = this.survey.accesstype === 'open' ? 'offen' : 'geschlossen';
            return 'Umfrage: ' + this.survey.name + ', Status: ' + status + ', Zugang: ' + zugang;
        },
        umfrage_statusfarbe() {
            if (this.survey === null || this.survey === undefined) return 'error'; 
            return this.survey.status === 'active' ? 'success' : 'error';
        },
        button_resetSubjects() {
            if (this.survey === null || this.survey === undefined) return false;
            if (this.survey.status === 'active' || this.survey.accesstype === 'open' || this.subjectsCount === 0) {
                return true;
            } else {
                return false;
            }
        },
        button_generateSubjects() {
            if (this.survey === null || this.survey === undefined) return false;
            if (this.survey.status === 'active' || this.survey.accesstype === 'open' || this.subjectsCount >= 8000) {
                return true;
            } else {
                return false;
            }
        },
        button_exportSubjectsLinks() {
            if (this.survey === null || this.survey === undefined) return false;
            if (this.subjectsCount === 0 || this.survey.accesstype === 'open') {
                return true;
            } else {
                return false;
            }
        },
        getSurveyId() {
            return this.sm_survey_id;
        },
        getSurveyName() {
            return this.sm_survey_name;
        },
        getCompanyId() {
            return this.sm_company_id;
        },
        async refreshData() {
            await this.loadSubjects();
        },
        close() {
            this.$emit('reloadOSurveyTable');
            this.$emit('update:show_subjectmanager', false);
        },
        filter(value, search, item) {
            if (typeof value === "string") {
                return item.shash === search || item.phash === search;
            }
        },
        statusName(status) {
            return this.status[status].name;
        },
        statusColor(status) {
            return this.status[status].color;
        },

        urlToClipboard(url) {
            navigator.clipboard.writeText(this.totalPath(url));
        },
        totalPath(url) {
            const route = this.$router.resolve({
                path: url,
            });
            return new URL(route.href, window.location.origin).href;
        },
        resizeDataTable(e) {
            // const h = this.$refs.drawer.$el.children[1].clientHeight;
            // this.height = `${h}px`;
            this.height = window.innerHeight * 0.78;
        },
        async exportList(item) {
            if (item === null || item === undefined) return;
            const permission = await this.awaitModal({
                title: "Teilnehmerlinks von " + item.name + " exportieren",
                message:
                " Link-Liste als CSV-File herunterladen?",
            });
            if (permission) {
                this.showProcess({
                text: "Bitte warten, Teilnehmer aus " + item.name + " werden exportiert ...",
                promise: this.exportSubjects(item),
                // csv-file wird aus exportSubjects() delivered, daher hier kein return!
                });
            }
        },
        async loadSubjects() {
            try {
                if (this.survey === null || this.survey === undefined) return;
                
                const params = {
                    survey: this.survey,
                    options: this.subjectsoptions
                };
                const promise = this.getAllSubjectsPagination(params);
                this.subjects = await promise;
                this.subjectsArray = Object.values(this.subjects); // für die Tabellenansicht
                this.subjectsCount = this.subjectsArray.length; // Setzen der totalItems
            } catch (error) {
                console.error('Fehler beim Laden der parginate Items:', error);
            }
        },
        async generateSurveySubjects() {
            this.showGenerateDialog = true;
            // await this.loadSubjects();
        },
        async onGenerationComplete() {
            await this.loadSubjects();
            
        },
        async deleteUnusedSurveySubjects() {
            if (this.survey === null || this.survey === undefined) return;

            const permission = await this.awaitModal({
                title: "Löschen",
                message: " Wollen Sie wirklich unbenutzte Teilnehmerschlüssel löschen? Beachte: Damit können Sie künftige Umfrageteilnehmer von der Umfrage aussperren!",
            });

            if (permission) {
                const promise =  this.deleteUnusedSubjects(this.survey);

                this.showProcess({
                    text: "Freie Teilnehmerschlüssel werden gelöscht",
                    promise: promise,
                });
                // unbedingt das Promise abwarten, sonst werden die Subjects zu früh geladen!
                let delresult = await promise;
                if (delresult) await this.loadSubjects();
            }
        },
        updateSurveySubjectsOptions() {
            // Notwendig, um die Optionen mit dem Parametern (survey und subjectsoptions) zu aktualisieren!
            this.updateSubjectsOptions({ 'survey': this.survey, 'options': this.subjectsoptions });
        },
        async exportSurveySubjectsList() {

            if (this.survey === null || this.survey === undefined) return;
            const permission = await this.awaitModal({
                title: "Teilnehmerlinks von " + this.survey.name + " exportieren",
                message: " Link-Liste als CSV-File herunterladen?",
            });

            if (permission) {
                    this.showProcess({
                    text: "Bitte warten, Teilnehmerlinks aus " + this.survey.name + " werden exportiert ...",
                    promise: this.exportSubjects(this.survey),
                });
            
            }
        },
        ...mapActions("modal", ["awaitModal"]),
        ...mapActions("alert", ["showProcess"]),
        ...mapActions("subjects", {
            getAllSubjectsPagination: "getAllSubjectsPagination",
            updateSubjectsOptions: "updateSubjectsOptions",
            exportSubjects: "exportSubjects",
            deleteUnusedSubjects: "deleteUnusedSubjects",
            }),
        
    },

  };
  </script>

<style scoped>

.custom-margin-subjects-table {
  margin-top: 20px; /* Hier können Sie die gewünschte Anzahl von Pixeln anpassen */
}

</style>