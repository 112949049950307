<template>
  <o-questionary-table></o-questionary-table>
</template>

<script>
import { OQuestionaryTable } from "@/modules/company/questionary";

export default {
  name: "o-questionary-view",
  components: { OQuestionaryTable },
};
</script>

<style></style>
