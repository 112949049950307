<template>
  <v-tooltip
    :disabled="!hasTooltip"
    top
    open-delay="1000"
    v-bind="{ ...$attrs.class }"
  >
    <template #activator="{ on, attrs }">
      <v-chip
        v-bind="{ ...$attrs, ...attrs, ...$props }"
        v-on="{
          ...$listeners,
          ...on,
          ...(hasCallback ? { click: () => callback(inputValue) } : {}),
        }"
        :color="color"
        outlined
      >
        <v-icon v-if="hasIcon" left> {{ icon }} </v-icon>
        {{ name }}
      </v-chip>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  name: "o-status-chip",
  props: {
    value: {
      type: String,
    },
    options: {
      type: Object,
    },
    inputValue: {},
  },
  data() {
    return {
      fallback: {
        name: "Not found",
        color: "error",
        icon: "mdi-error",
        callback: null,
        tooltip: null,
      },
    };
  },
  computed: {
    name() {
      return this._options.name;
    },
    color() {
      return this._options.color;
    },
    hasIcon() {
      const icon = this._options.icon;
      return icon !== null && icon != undefined;
    },
    icon() {
      return this._options.icon;
    },
    hasTooltip() {
      const tooltip = this._options.tooltip;
      return tooltip !== null && tooltip !== undefined;
    },
    tooltip() {
      return this._options.tooltip;
    },
    hasCallback() {
      const callback = this._options.callback;
      return (callback !== null) & (callback !== undefined);
    },
    callback() {
      return this._options.callback;
    },
    _options() {
      return this.options[this.value];
    },
  },
};
</script>

<style></style>
