<template>
  <v-responsive :width="width">
    <v-sheet
      class="d-flex flex-row"
      style="overflow: clip"
      width="100%"
      :height="height"
      rounded="xl"
      :color="getBackgroundColor"
    >
      <template v-for="(value, i) in _values">
        <v-sheet
          v-if="value.value > 0"
          :key="i"
          :color="value.color"
          :width="getSize(value)"
          height="100%"
          outlined
        />
      </template>
    </v-sheet>
    <v-row no-gutters>
      <v-col v-for="(value, i) in _values" :key="i">
        <div class="d-flex flex-row align-center pa-1 pr-4">
          <v-avatar size="12" :color="value.color" />
          <h4 class="ml-2 mr-3">{{ value.name }}</h4>
          <span class="text--secondary">{{ value.value }}</span>
        </div>
      </v-col>
    </v-row>
  </v-responsive>
</template>

<script>
export default {
  name: "o-share-indicator",
  props: {
    values: {
      type: Array,
      default: () => {
        return [
          {
            name: "bereit",
            color: "#FEB019",
            value: 5,
          },
          {
            name: "begonnen",
            color: "#00E396",
            value: 5,
          },
          {
            name: "fertiggestellt",
            color: "#008FFB",
            value: 5,
          },
        ];
      },
    },
    width: {
      type: Number || String,
      default: 384,
    },
    height: {
      type: Number || String,
      default: 12,
    },
    sortBy: {
      type: Function,
      default: null,
    },
  },
  methods: {
    getMargin(i) {
      const length = this.values.length - 1;
      return i >= 1 && i <= length - 1 ? "mx-1" : "";
    },
    getSize(item) {
      return `${(item.value / this.totalCount) * 100}%`;
    },
    compareValues(a, b) {
      return a.value > b.value ? -1 : 1;
    },
  },
  computed: {
    _values() {
      return this.sortBy !== null && this.sortBy !== undefined
        ? [...this.values].sort(this.sortBy)
        : this.values;
    },
    totalCount() {
      return this.values.reduce((value, elem) => {
        return value + elem.value;
      }, 0);
    },
    getBackgroundColor() {
      return this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.2)";
    },
  },
};
</script>

<style></style>
