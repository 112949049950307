<template>
  <v-snackbar
    v-model="visible"
    top
    rounded="xl"
    :timeout="currentAlert?.timeout || 2000"
  >
    <o-alert-item :value="currentAlert" />
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="visible = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
import OAlertItem from "./OAlertItem.vue";

export default {
  components: { OAlertItem },
  name: "o-alert-snackbar",
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters("alert", ["currentAlert"]),
  },
  watch: {
    currentAlert: {
      handler: function (value) {
        this.visible = !!value;
      },
      deep: true,
    },
    /*  $route(to, from) {
      this.clearAlert();
    }, */
  },
};
</script>

<style scoped></style>
