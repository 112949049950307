<template>
  <!-- <v-btn depressed rounded @click.prevent="setShow(!show)">
    <v-badge
      :content="items.length"
      :value="items.length > 0"
      color="primary"
      overlap
    >
      <v-icon>mdi-bell</v-icon>
    </v-badge>
  </v-btn> -->
  <v-menu
    offset-y
    left
    rounded="xl"
    nudge-top="-5"
    :close-on-content-click="false"
    z-index="1000"
    absolute
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" v-on="on" rounded>
        <v-badge
          :content="alerts.length"
          :value="alerts.length > 0"
          color="primary"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-responsive width="640">
      <v-toolbar elevation="1" extension-height="34">
        <v-toolbar-title>Benachrichtigungen</v-toolbar-title>
        <v-spacer />
        <v-tooltip open-delay="500" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" @click="clearAlerts">
              <v-icon> mdi-notification-clear-all </v-icon>
            </v-btn>
          </template>
          Benachrichtigungen Leeren
        </v-tooltip>
        <!--  <v-tooltip open-delay="500" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon> mdi-refresh </v-icon>
            </v-btn>
          </template>
          Aktualisieren
        </v-tooltip> -->
        <template #extension>
          <v-tabs v-model="tab" class="custom-tabs">
            <v-tab
              v-for="group in alertGroups"
              :key="`tab_${group.key}`"
              :value="group.key"
            >
              <v-badge color="red" overlap inline>
                <template v-slot:badge>
                  <span>{{ getGroupCount(group.key) }}</span>
                </template>
                {{ group.name }}
              </v-badge>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <!-- <v-divider /> -->
      <o-scrollable>
        <o-alert-list :value="getSelectedAlertGroup" />
      </o-scrollable>
    </v-responsive>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OScrollable from "@/common/components/OScrollable.vue";
import OAlertList from "./OAlertList.vue";
import AlertLoadMixin from "../mixins/AlertLoadMixin";

export default {
  name: "o-alert-menu",
  components: { OScrollable, OAlertList },
  mixins: [AlertLoadMixin],
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    getSelectedAlertGroup() {
      return [this.alertGroups[this.tab].key];
    },
    ...mapGetters("alert", [
      "alerts",
      "alertGroups",
      "alertsByDate",
      "alertsByGroup",
      "getGroupCount",
    ]),
  },
  methods: {
    clearAlerts() {
      this.markAllGroupAlertsAsRead(this.alertGroups[this.tab].key);
    },
    ...mapActions("alert", ["markAllGroupAlertsAsRead"]),
  },
};
</script>

<style>
.custom-tabs .v-tabs-slider::after,
.custom-tabs .v-tab {
  text-transform: none;
}
</style>
