<template>
  <v-text-field
    @focus="open"
    @blur="close"
    :placeholder="placeholder"
    prepend-inner-icon="mdi-magnify"
    hide-details
    single-line
    filled
    :background-color="!active ? 'transparent' : 'none'"
    class="expanded-search rounded-lg"
    :class="{ closed: !active }"
    v-model="_value"
    :dense="dense"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "o-search",
  props: {
    value: {
      type: String,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    placeholder: {
        type: String,
        required: false, // Prop ist jetzt optional
        default: "Suche" // Standardwert ist null
      },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open() {
      this.active = true;
      this._value = "";
    },
    close() {
      this.active = false;
    },
    print(msg) {
      // console.log(msg);
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
.v-input.expanded-search {
  transition: max-width 0.3s, background-color 0.3s;
}

.v-input.expanded-search > .v-input__control > .v-input__slot::before,
.v-input.expanded-search > .v-input__control > .v-input__slot::after {
  border-color: transparent !important;
  border: none;
}

.v-input.expanded-search.closed {
  max-width: 45px;
}
</style>
