<template>
  <v-radio-group v-model="_value" class="px-2" :rules="rules" >
    <!--  <v-row align-content="center" justify="center" align="center">
      <v-col v-for="(item, index) in scalar" :key="index">
        <v-radio :value="index" :color="getColor(item)">
          <template v-slot:label>
            <span>{{ getLabel(item) }}</span>
          </template>
        </v-radio>
      </v-col>
    </v-row> -->
    <v-radio
      v-for="(item, index) in scalar"
      :key="index"
      :value="index + 1"
      :color="getColor(item)"
    >
      <template v-slot:label>
        <span>{{ getLabel(item) }}</span>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: "o-poll-scalar-radio",
  props: {
    value: {
      type: Number,
    },
    scalar: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rules: [(v) => v !== undefined && v !== null],
    };
  },
  methods: {
    getLabel(value) {
      return typeof value === "object" ? value.label : value;
    },
    getColor(value) {
      const color = typeof value === "object" ? value.color : "primary";
      return color ?? "primary";
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
