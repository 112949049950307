<template>
  <div>
    <v-form v-model="valid" @submit.prevent.stop="submit" ref="form">
      <v-card flat height="350px">
        <v-card-title class="pb-6"> Passwort vergessen? </v-card-title>
        <v-card-subtitle>
          Haben Sie ihr Passwort vergessen? Einfach Ihre Email eingeben. Sie
          erhalten innerhalb der nächsten <b>10 Minuten</b> ein neu generiertes
          Passwort an Ihre Email-Adresse.
        </v-card-subtitle>
        <v-card-text>
          <o-email-field v-model="email" />
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <o-async-btn
            block
            :disabled="!valid"
            x-large
            rounded
            depressed
            color="primary"
            type="submit"
            :click="submit"
          >
            Zurücksetzen
          </o-async-btn>
          <v-btn small rounded text class="mt-6" @click.prevent="close">
            <v-icon left>mdi-arrow-left</v-icon>
            Zurück zum Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import OEmailField from "@/common/components/OEmailField.vue";
import OAsyncBtn from "@/common/components/OAsyncBtn.vue";
import { mapActions } from "vuex";

export default {
  name: "o-password-forgotten-form",
  components: {
    OEmailField,
    OAsyncBtn,
  },
  data() {
    return {
      valid: false,
      email: "",
    };
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.$emit("close");
      });
    },
    async submit() {
      try {
        await this.resetPassword({ email: this.email });
        return true;
      } catch (error) {
        return false;
      } finally {
        this.$nextTick(() => this.close());
      }
    },
    ...mapActions("account", ["resetPassword"]),
  },
};
</script>

<style></style>
