<template>
  <v-menu
    offset-y
    left
    rounded="xl"
    nudge-top="-5"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-width="minWidth"
    z-index="10"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" v-on="on" rounded>
        <slot name="title"> Menu </slot>
        <v-icon right> mdi-chevron-down </v-icon>
      </v-btn>
    </template>
    <slot>
      <v-list>
        <slot name="prepend"></slot>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          v-on="item.click ? { click: item.click } : {}"
        >
          <slot name="item" v-bind="{ item, index }">
            <v-list-item-avatar v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.subtitle">{{item.subtitle}}</v-list-item-subtitle>
            </v-list-item-content>
          </slot>
        </v-list-item>
        <slot name="append"></slot>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  name: "o-menu-dropdown",
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    maxHeight: {
      type: String,
      default: "auto",
    },
    minWidth: {
      type: String,
      default: "0",
    },
    maxWidth: {
      type: String,
      default: "auto",
    },
  },
};
</script>

<style scoped></style>
