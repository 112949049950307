import { orgagroupsService } from "@/services";
import { ApiValue } from "@/common/helpers";

const state = {
  orgagroups: new ApiValue([], "orgagroups/getAll"),
};

const actions = {
  getAll({ commit, dispatch }) {
    orgagroupsService.getAll().then(
      (data) => {
        commit("getAllSuccess", data.orgagroups);
      },
      (err) => {
        commit("getAllFailure", err);
        dispatch("alert/failure", err, { root: true });
      }
    );
  },
};

const mutations = {
  getAllSuccess(state, orgagroups) {
    state.orgagroups.success(orgagroups);
  },
  getAllFailure(state, error) {
    state.orgagroups.failure(error);
  },
};

const getters = {
  orgagroups: (state) => state.orgagroups,
};

export const orgagroups = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
