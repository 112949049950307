<template>
  <div style="position: relative; height: 100%">
    <div
      style="
        position: fixed;
        z-index: 2;
        width: 256px;
        height: 100%;
        border-right: solid 1px rgba(0, 0, 0, 0.12);
      "
    >
      <v-list nav class="pa-4" height="100%">
        <h2 class="pb-4">Einstellungen</h2>
        <v-list-item
          v-for="(link, i) in navItems"
          :key="i"
          link
          dense
          :to="link.to"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div style="margin-left: 256px" class="pa-12">
      <v-responsive max-width="1280px">
        <o-router-view />
      </v-responsive>
    </div>
  </div>
</template>

<script>
import ORouterView from "@/common/components/ORouterView.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ORouterView,
  },
  name: "o-settings-view",
  data() {
    return {
      nav: {
        admin: [
          {
            title: "Konto",
            icon: "mdi-account",
            to: "/settings/account",
          },
          {
            title: "Theme",
            icon: "mdi-tune",
            to: "/settings/application",
          },
        ],
        user: [
          {
            title: "Konto",
            icon: "mdi-account",
            to: "/settings/account",
          },
        /*   {
            title: "Organistation",
            icon: "mdi-domain",
            to: "/settings/company",
          }, */
          {
            title: "Theme",
            icon: "mdi-tune",
            to: "/settings/application",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("account", ["user", "role"]),
    navItems() {
      return this.nav[this.role];
    },
  },
};
</script>

<style></style>
