<template>
  <div :class="_classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "o-center",
  props: {
    column: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _classes() {
      let style = "d-flex justify-center align-center";
      style += this.column ? " flex-column" : " flex-row align-content-center";
      return style;
    },
  },
};
</script>

<style></style>
