<template>
  <v-list-item :disabled="disabled" dense>
    <v-list-item-avatar>
      <v-avatar size="24" class="white--text" :color="avatarColor">
        <v-icon size="18" v-if="selected" color="white" dark>mdi-pencil</v-icon>
        <v-icon size="18" v-else-if="completed && !selected" color="white">
          mdi-check
        </v-icon>
        <span v-else>{{ index }}</span>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content class="text-body-2">
      {{ value.name }}
    </v-list-item-content>
    <v-list-item-action-text>
      ({{ finishedQuestionsCount }}/{{ totalQuestionCount }})
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
export default {
  name: "o-poll-stepper-step",
  props: {
    value: {
      type: Object,
    },
    index: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarColor() {
      return !this.disabled
        ? this.selected
          ? "primary"
          : "secondary"
        : "grey";
    },
    totalQuestionCount() {
      return this.value.questions.length;
    },
    finishedQuestionsCount() {
      return this.value.questions.reduce((value, elem) => {
        value += elem.rawvalue != null || elem.rawvalue != undefined ? 1 : 0;
        return value;
      }, 0);
    },
  },
};
</script>

<style></style>
