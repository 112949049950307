<template>
  <div>
    <o-company-edit-drawer 
      v-model="edit.item"
      height="100%" 
    />
    <o-tree-drawer
      v-if="tree.item != null"
      :tree_name="tree.item.name"
      :tree_id="tree.item.tree_id" 
      v-model="tree.item"
      height="100%" 
    />
    <v-data-table
      :items="items"
      :server-items-length="totalItems"
      :loading="isLoading"
      :headers="table.headers"
      :search="table.search"
      :options="options"
      @update:options="updateOptions"
    >
      <template #top>
        <o-data-table-toolbar title="Organisationen" :search.sync="table.search">
          <v-btn
            depressed
            color="primary"
            @click.prevent.stop="editItem(edit.default)"
          >
            Neue Organisation
          </v-btn>
        </o-data-table-toolbar>
      </template>
      <template #no-data>
        <b>Keine Organisationen vorhanden...</b>
      </template>
      <template v-slot:[`toolbar.prepend`]>
        <b>Organisationen</b>
      </template>
      <template v-slot:[`toolbar.append`]>
        <v-btn
          depressed
          x-large
          rounded
          color="primary"
          @click.prevent.stop="editItem(edit.default)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar size="56">
            <v-avatar color="error" v-if="item.supervisor === 1">
              <span class="white--text text-h5">{{ item.name[0] }}</span>
            </v-avatar>
            <v-avatar color="primary" v-else>
              <span class="white--text text-h5">{{ item.name[0] }}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.orgagroup_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ item.address }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.zip + " " + item.city }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              <o-date-time :value="item.created_at" />
            </v-list-item-title>
            <v-list-item-subtitle> erstellt am </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <!--
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status === 'active' ? 'success' : 'error'">
          {{ item.status }}
        </v-chip>
      </template>
      -->
      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          inset
          true-value="active"
          false-value="inactive"
          :input-value="item.status"
          @change="(value) => updateCompanyStatus(item, value)"
          color="success"
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              @click.prevent.stop="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          Organisation bearbeiten
        </v-tooltip>
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              @click.prevent.stop="showTree(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-family-tree</v-icon>
            </v-btn>
          </template>
          Organisationsbaum ansehen
        </v-tooltip>
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <o-async-btn
              icon
              rounded
              color="error"
              :disabled="
                item.status === 'inactive' && item.supervisor === 0
                  ? false
                  : true
              "
              :click="() => resetItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-database-refresh</v-icon>
            </o-async-btn>
          </template>
          Organisation zurücksetzen
        </v-tooltip>
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <o-async-btn
              icon
              rounded
              color="error"
              :disabled="
                item.status === 'active' && item.supervisor === 0
                  ? false
                  : true
              "
              :click="() => regeneratePassword(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-lock-reset</v-icon>
            </o-async-btn>
          </template>
          Benutzer-Passwort regenerieren
        </v-tooltip>
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <o-async-btn
              icon
              rounded
              color="error"
              :disabled="
                item.status === 'inactive' && item.supervisor === 0
                  ? false
                  : true
              "
              :click="() => deleteItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </o-async-btn>
          </template>
          Organisation löschen
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import OCompanyEditDrawer from "./OCompanyEditDrawer.vue";
import OTreeDrawer from "@/common/components/OTreeDrawer.vue";
import OAsyncBtn from "@/common/components/OAsyncBtn.vue";
import ODataTableToolbar from "@/common/components/ODataTableToolbar.vue";
import { mapActions, mapGetters } from "vuex";
import ODateTime from "@/common/components/ODateTime.vue";

export default {
  name: "o-company-table",
  components: {
    OCompanyEditDrawer,
    OTreeDrawer,
    OAsyncBtn,
    ODataTableToolbar,
    ODateTime,
  },
  props: {
    reload: Boolean,
  },
  data() {
    return {
      tree_id: null,
      tree_name: '',
      edit: {
        item: null,
        default: {
          company_id: -1,
          tree_id: 1,
          address: "",
          zip: "",
          city: "",
          name: "",
          description: "",
          identifier: "",
          orgagroup_id: 1,
          orgagroup_name: "",
          email: "",
          status: "inactive",
          n_active_surveys: 0,
          n_inactive_surveys: 0,
          n_tree_nodes: 0,
          n_interview: 0,
        },
      },
      tree: {
        item: null,
        default: {
          company_id: -1,
          tree_id: 1,
          address: "",
          zip: "",
          city: "",
          name: "",
          description: "",
          identifier: "",
          orgagroup_id: 1,
          orgagroup_name: "",
          email: "",
          status: "inactive",
          n_active_surveys: 0,
          n_inactive_surveys: 0,
          n_tree_nodes: 0,
          n_interview: 0,
        },
      },
      table: {
        search: "",
        headers: [
          {
            text: "Name",
            value: "name",
            align: "start",
            class: ["header", "rounded-l"],
            divider: false,
          },
          {
            text: "OC Gruppe",
            value: "orgagroup_name",
            class: ["header"],
            sortable: true,
          },
          {
            text: "Email",
            value: "email",
            class: ["header"],
          },
          {
            text: "Aktive Umfagen",
            value: "n_active_surveys",
            class: ["header"],
            sortable: false,
          },
          {
            text: "Inaktive Umfagen",
            value: "n_inactive_surveys",
            class: ["header"],
            sortable: false,
          },
          {
            text: "Interviews",
            value: "n_interviews",
            class: ["header"],
            sortable: false,
          },
          {
            text: "Orgabaum Elemente",
            value: "n_tree_nodes",
            class: ["header"],
            sortable: false,
          },
          {
            text: "erstellt",
            value: "created_at",
            class: ["header"],
          },
          {
            text: "Status",
            value: "status",
            sortable: true,
            class: ["header", "rounded-r"],
          },
          {
            text: "Aktionen",
            value: "action",
            sortable: false,
            align: "end",
            class: ["header", "rounded-r"],
          },
        ],
      },
    };
  },
  methods: {
    editItem(item) {
      this.edit.item = { ...item };
    },
    showTree(item) {
      this.tree.item = { ...item };
    },
    updateCompanyStatus(item, value) {
      this.updateCompany({ ...item, status: value });
    },
    async regeneratePassword(item) {
      if (item === null || item === undefined) return;
      // console.log("reset Company triggered", item);
      const permission = await this.awaitModal({
        title: "Passwort regenerieren",
        message:
          "Wollen sie das Passwort von der Organisation " +
          item.name +
          " wirklich zurücksetzen? Ein neues Passwort wird erzeugt und an den Benutzer per Email gesendet!",
      });
      if (permission) {
        this.regenerateUserPassword(item);
        this.select(null);
      }
    },
    async resetItem(item) {
      if (item === null || item === undefined) return;
      // console.log("reset Company triggered", item);
      const permission = await this.awaitModal({
        title: "Organisation zurücksetzen",
        message:
          "Wollen sie die Organisation " +
          item.name +
          " wirklich zurücksetzen? Orgabaum, Fragebögen, Umfragen, Teilnehmerlisten und Auswertungen werden unwiderruflich gelöscht!",
      });
      if (permission) {
        this.resetCompany(item);
        this.select(null);
      }
    },
    async deleteItem(item) {
      if (item === null || item === undefined) return;
      // console.log("delete Company triggered", item);
      const permission = await this.awaitModal({
        title: "Organisation löschen",
        message:
          "Wollen sie die Organisation " +
          item.name +
          " wirklich löschen? Nutzer, Orgabaum, Fragebögen, Umfragen, Teilnehmerlisten und Auswertungen werden unwiderruflich gelöscht!",
      });
      //return permission && this.deleteReporting(item);

      if (permission) {
        this.deleteCompany(item);
        this.select(null);
      }
    },
    ...mapActions("modal", ["awaitModal"]),
    ...mapActions("company", {
      updateOptions: "updateOptions",
      deleteCompany: "delete",
      resetCompany: "reset",
      regenerateUserPassword: "regenerate",
      updateCompany: "update",
    }),
  },
  computed: {
    ...mapGetters("company", ["items", "totalItems", "isLoading", "options"]),
  },
};
</script>

<style>
.item {
  height: 86px;
}
.header {
  height: 86px;
}
</style>
