<template>
    <v-navigation-drawer :value="show" absolute right width="100%" outlined>
      
      <template v-slot:prepend>
        <v-toolbar flat dense v-if="tree_id">
          <v-icon size="32"> mdi-family-tree </v-icon>
          <v-toolbar-title class="pl-2">
            <v-list-item-content>
              <v-list-item-title>Organisationsbaum ansehen </v-list-item-title>
              <v-list-item-subtitle class="text--secondary">
                Baum #{{ tree_id }} {{ tree_name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-divider />
      </template>

      <template>
        <o-show-tree 
          :tree_type="this.tree_type"
          :tree_id="tree_id" 
          :survey_id="this.survey_id"
        />
      </template>
  
      <template v-slot:append>
        <v-divider />
        <v-toolbar flat>
          <v-btn text color="error" @click.prevent.stop="cancel">
            <v-icon left>mdi-cancel</v-icon>
            Abbruch
          </v-btn>
          <v-spacer />
        </v-toolbar>
      </template>
    </v-navigation-drawer>
  </template>
  
  <script>
  import { OShowTree } from "@/modules/company/showtree";
  
  export default {
    name: "o-tree-drawer",
    components: { OShowTree },
    props: {
      tree_id: {
        type: Number,
      },
      survey_id: {
        type: Number,
        default: null,
      },
      tree_name: {
        type: String,
      },
      tree_type: {
        type: String,
        default: "organisation",
        // "fieldstatus = Completes werden angezeigt", "organisation = die Organisation wird angezeigt"
      },
    },
    data() {
      return {
        isDrawerOpen: true,
      };
    },
    methods: {
      cancel() {
        this.$emit("input", null);
      },
    },
    computed: {
      show() {
        return this.tree_id !== null && this.tree_id !== undefined;
      },
    },
  };
  </script>
  
  <style></style>
  