<template>
  <v-card
    flat
    class="py-2 px-4 mb-3"
    rounded="xl"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <v-card-title>
      <v-toolbar flat rounded="xl">
        <v-toolbar-title class="text-h5">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <!-- <o-search v-if="showSearch" v-model="search" dense class="mr-3" /> -->
        <slot name="append"> </slot>
      </v-toolbar>
      <!--  <span class="text-h5"> {{ title }}</span>
      <v-spacer />
      <o-search v-if="showSearch" v-model="search" dense class="mr-3" />
      <slot name="append"> </slot> -->
    </v-card-title>
    <v-card-text>
      <slot v-bind="{ search }"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "o-data-table-card",
  props: {
    title: {
      type: String,
      default: "Data Table Title",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style></style>
