<template>
  <div
    v-shortkey.prevent.stop="keymap"
    @shortkey="handleKeyInput"
    style="position: relative"
    class="fill"
  >
    <o-chart-searchbar
      :style="{
        position: 'absolute',
        top: '1%',
        left: '0',
        right: '0',
        marginLeft: 'auto',
        marginRight: 'auto',
      }"
      v-model="chart.selected.id"
      :graph="graph"
      :colorGroups="colorGroups"
    />
    <o-chart-drawer
      :graph="graph"
      :colorGroups.sync="colorGroups"
      :reportingGroups.sync="reportingGroups"
      :reportingCategories.sync="reportingCategories"
      :config.sync="config"
      :active.sync="chart.selected.id"
      :zoom="chart.zoom"
      @zoomIn="chart.current.zoomIn()"
      @zoomOut="chart.current.zoomOut()"
      @expand="chart.current.expandAll()"
      @collapse="chart.current.collapseAll()"
      @center="chart.current.fit({ scale: false })"
      @add="addNode"
      @delete="(node) => deleteNode(node)"
      @refresh="$emit('refresh')"
      @save="$emit('save')"
    />
    <o-chart-node-edit-drawer
      v-model="chart.selected.node"
      :reportingGroups="reportingGroups"
      :reportingCategories="reportingCategories"
      :colorGroups="colorGroups"
      @add="addNode"
      @delete="(node) => deleteNode(node)"
      @close="selectNode(null)"
    />
    <div
      ref="org-chart-container"
      @click.prevent.stop="onCanvasClick"
      class="fill"
    />
  </div>
</template>

<script>
import OrgChart from "../helpers/org-chart";
import OChartNodeEditDrawer from "./OChartNodeEditDrawer.vue";
import OChartDrawer from "./OChartDrawer.vue";
import OChartSearchbar from "./OChartSearchbar.vue";

import { mapActions } from "vuex";

export default {
  name: "o-chart",
  components: {
    OChartNodeEditDrawer,
    OChartDrawer,
    OChartSearchbar,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      isDataLoaded: false,
      chart: {
        current: null,
        selected: {
          id: null,
          node: null,
        },
        zoom: 1.0,
        config: {
          layout: 0,
          compact: true,
        },
      },
    };
  },
  methods: {
    async loadData() {
      // load data
      this.isDataLoaded = true;
    },
    addTooltipStyles() {
      if (this.isDataLoaded) {
        // Your code here will execute after the DOM has been updated.
        const style = document.createElement('style');
        style.innerHTML = `
          [data-tooltip] {
            position: relative;
            cursor: pointer;
          }

          [data-tooltip]::after {
            content: attr(data-tooltip);
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: #333;
            color: #fff;
            padding: 5px;
            border-radius: 3px;
            white-space: nowrap;
            font-size: 12px;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s;
          }

          [data-tooltip]:hover::after,
          [data-tooltip]:focus::after {
            opacity: 1;
          }
        `;
        document.head.appendChild(style);
      }
    },
    renderChart(value) {
      if (!value) return;
      if (!this.chart.current) {
        this.chart.current = new OrgChart()
          .container(this.$refs["org-chart-container"])
          .onNodeClick(this.onNodeClick)
          .compact(this.config.compact)
          .zoomCallback((level) => {
            this.chart.zoom = level;
          })
          .nodeContent(this.renderNode)
          .buttonContent(this.renderButton)
          .nodeWidth(() => 300)
          .nodeHeight(() => 120);
      }
      this.chart.current.data(value).render();
    },
    containsSpecialChar(label, sublabel, description) {
      const specialChar = '✓';
      return label.includes(specialChar) || sublabel.includes(specialChar) || description.includes(specialChar);
    },
    getCategoryNamesByIds(category_ids) {
      return category_ids.map(id => {
        const foundObject = this.value.reporting_categories.find(item => item.id === id);
        return foundObject ? foundObject.name : null;
      });
    },
    renderNode(d) {
      const label = d.data.label;
      const sublabel = d.data.sublabel;
      const depth = d.depth;
      const description = d.data.description;
      const colorGroup = this.getColorGroup(d.data.colorGroup);
      const theme = this.$vuetify.theme.isDark ? "theme--dark" : "theme--light";

      var reportingCategoryLabels = "";
      const rcl = this.getCategoryNamesByIds(d.data.reportingCategories);
      if ((rcl.length !== 0) && (rcl)) {
        reportingCategoryLabels = ", Kategorien: " + rcl.join(', ');  
      }

      // nicht schön, aber effizient - es wird einfach nach dem Haken gesucht und danach die Hintergrundfarbe gesetzt
      var nodeBackgroundColor = "";
      if (this.containsSpecialChar(label, sublabel, description)) {
        nodeBackgroundColor = "background-color: lightgreen;";
      }

      return `
          <div class="rounded-lg v-sheet v-sheet--outlined ${theme}" style="${nodeBackgroundColor} width: inherit; height: inherit; border: 2px solid ${colorGroup.color}">
            <div title="Ebene: ${depth}${reportingCategoryLabels}" class="ma-1 v-sheet ${theme} rounded-xl" style="height: 12px; background-color: ${colorGroup.color}; border-color: ${colorGroup.color}" ></div>
            <div class="ma-2 v-list-item v-list-item--two-line ${theme}">
              <div>
                <div class="ma-0 ml-0 text-h6 text-truncate" style="max-width:270px;">${label}</div>
                <div class="ma-1 ml-0 v-list-item__subtitle text-truncate" style="max-width:270px; font-weight:bold;">${sublabel}</div>
                <div class="ma-1 ml-0 v-list-item__subtitle d-inline-block text-truncate" style="max-width:270px;">${description}</div>
              </div>
            </div>
          </div>
      `;
    },
    renderButton(d) {
      const node = d.node;
      const colorGroup = this.getColorGroup(node.data.colorGroup);
      const theme = this.$vuetify.theme.isDark ? "theme--dark" : "theme--light";

      return `
      <div style="width: inherit; height: inherit; box-sizing: border-box; border: 2px solid ${
        colorGroup.color
      }" class="rounded-circle v-sheet ${theme}">
        <div class="row text-center no-gutters align-center" style="width: inherit; height: inherit">
          <div class="col">
            <div style="text-align: center;">${
              node.data._directSubordinates
            }</div>
          </div>
          <div class="col">
            <i class="v-icon notranslate mdi ${
              node.children ? "mdi-minus" : "mdi-plus"
            } ${theme}" aria-hidden="true" style="font-size: 16px"></i>
          </div>
        </div>
      </div>
      `;
    },

    addNode(parentNode) {
      const node = {
        id: this.generateId(),
        parentId: parentNode.id,
        colorGroup: parentNode.colorGroup,
        reportingCategories: [],
        label: "",
        sublabel: "",
        description: "",
      };

      const attrs = this.chart.current.getChartState();
      this.chart.current.addNode(node);
      this.selectNode(attrs.nodeId(node));
      this.graph = attrs.data;
    },
    async deleteNode(node) {
      try {
        const permission = await this.awaitModal({
          title: "Abteilung Löschen?",
          message:
            "Wollen Sie diese Abteilung und weitere Unterabteilungen wirklich löschen?",
        });

        if (permission) {
          const attrs = this.chart.current.getChartState();
          this.chart.current.removeNode(attrs.nodeId(node));
          this.graph = attrs.data;

          return Promise.resolve();
        }
      } catch (err) {
        console.log(err);
        return Promise.reject();
      }
    },
    getColorGroup(id) {
      const group = this.colorGroups.find((g) => {
        return g.id === id;
      });
      return group ? group : this.colorGroups[0];
    },
    onNodeClick(id, evt) {
      evt.stopPropagation();
      this.selectNode(id);
    },
    onCanvasClick(evt) {
      evt.stopPropagation();
      this.selectNode(null);
    },
    generateId(maxLength = 12) {
      return (
        Date.now().toString(maxLength) +
        Math.floor(
          Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
        ).toString(maxLength)
      );
    },
    getNodeNeighbourIndex(node) {
      const parent = node.parent;
      if (!parent) return 0;

      const children = parent.children ?? parent._children;
      if (!children) return null;

      for (let i = 0; i < children.length; i++) {
        if (children[i].id === node.id) return i;
      }

      return 0;
    },
    selectNode(id) {
      const node = this.chart.current.getNode(id);
      this.chart.selected = {
        id: id,
        node: node ? node : null,
      };
    },
    selectNoteParent(node) {
      if (!node) return;

      const parent = node.parent;
      if (!parent) return;

      this.selectNode(parent.id);
    },
    selectNoteChildAt(node, i) {
      if (!node) return;

      const children = node.children ?? node._children;
      if (!children || i < 0 || i >= children.length) return;

      this.selectNode(children[i].id);
    },
    selectNodeNeighbour(node, dir) {
      if (!node) return;

      const parent = node.parent;
      if (!parent) return;

      const children = parent.children ?? parent._children;
      if (!children) return;

      let index = this.getNodeNeighbourIndex(node);
      index += dir;
      index = Math.max(Math.min(children.length - 1, index), 0);

      const neighbour = children[index];
      if (!neighbour) return;

      this.selectNode(neighbour.id);
    },
    handleKeyInput(evt) {
      if (!evt.srcKey) return;

      const handlers = {
        back: () => this.selectNode(null),
      };

      const handler = handlers[evt.srcKey];
      if (!handler) return;

      handler(evt);
    },
    ...mapActions("modal", ["awaitModal"]),
  },
  async mounted() {
    await this.loadData();
    this.addTooltipStyles();
  },
  computed: {
    graph: {
      get() {
        return this.value && this.value.graph ? this.value.graph : [];
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          graph: value,
        });
      },
    },
    colorGroups: {
      get() {
        return this.value && this.value.color_groups
          ? this.value.color_groups
          : [];
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          color_groups: value,
        });
      },
    },
    reportingGroups: {
      get() {
        return this.value && this.value.reporting_groups
          ? this.value.reporting_groups
          : [];
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          reporting_groups: value,
        });
      },
    },
    reportingCategories: {
      get() {
        return this.value && this.value.reporting_categories
          ? this.value.reporting_categories
          : [];
      },
      set(value) {
        this.$emit("input", {
          ...this.value,
          reporting_categories: value,
        });
      },
    },
    config: {
      get() {
        return this.value && this.value.config
          ? this.value.config
          : this.chart.config;
      },
      set(value) {
        if (!this.value) {
          this.chart.config = value;
          return;
        }

        this.$emit("input", {
          ...this.value,
          config: value,
        });
      },
    },
    keymap() {
      return {
        back: ["esc"],
      };
    },
  },
  watch: {
    graph(value) {
      this.renderChart(value);
    },
    "chart.selected.id"(value) {
      this.chart.current.clearHighlighting();

      if (!value) return;
      this.chart.current.setHighlighted(value, true).render();
    },
    config(value) {
      const layout = ["top", "left", "bottom", "right"][value.layout];
      this.chart.current
        .layout(layout)
        .compact(value.compact)
        .render()
        .fit({ scale: false });
    },
  },
};
</script>

<style scoped>
.v-list-item__subtitle {
  font-size: 28px;
}
.fill {
  width: 100%;
  height: 100%;
}
</style>
