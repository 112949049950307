<template>
  <v-responsive :max-height="maxHeight" :height="height" class="scroll-wrapper">
    <slot></slot>
  </v-responsive>
</template>

<script>
export default {
  name: "o-scrollable",
  props: {
    maxHeight: {
      type: String,
      default: "80vh",
    },
    height: {
      type: String,
    },
  },
};
</script>

<style scoped>
.scroll-wrapper {
  overflow: auto;
}
</style>
