import { apiService } from "./index";

export const companyService = {
  getAll: (options) => apiService.POST(`/companies/all`, options),
  get: (id) => apiService.GET(`/companies/${id}`),
  create: (data) => apiService.POST(`/companies`, data),
  update: (id, data) => apiService.PUT(`/companies/${id}`, data),
  reset: (id) => apiService.GET(`/companies/reset/${id}`),
  regenerate: (id) => apiService.GET(`/companies/regeneratepasswords/${id}`),
  delete: (id) => apiService.DELETE(`/companies/${id}`),
  register: (data) => apiService.POST(`/companies`, data),
  getUser: (id) => apiService.GET(`/companies/users/${id}`),
  getTree: (id) => apiService.GET(`/companies/trees/${id}`),
  getRules: ({ company_id, options }) => apiService.POST(`/companies/rules/${company_id}`, options),
}