<template>
  <!-- <v-form ref="form" v-model="valid" @submit.prevent.stop="submit">
    <v-card flat class="ma-3 pa-4" color="transparent">
      <v-row>
        <v-col cols="3">
          <v-row no-gutters>
            <v-col cols="auto" align-self="center">
              <v-icon x-large color="primary">mdi-lock</v-icon>
            </v-col>
            <v-col>
              <v-card-title> Passwort ändern </v-card-title>
              <v-card-subtitle
                >Nach erfolgreichen erneuern. Werden sie zum Login
                weitergeleitet
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-card-text>
            <p>Passwort</p>
            <o-password-field v-model="password" placeholder="" disable-icon />
            <p>Passwort Bestätigung</p>
            <o-password-field placeholder="" disable-icon />
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="!valid" large depressed color="primary" type="submit">
          Speichern
        </v-btn>
      </v-card-actions>
      <v-divider />
    </v-card>
  </v-form> -->
  <div>
    <v-row justify="center" align="start" v-if="!show">
      <v-col>
        <h3 class="mb-3">Passwort</h3>
        Das aktuelle Passwort lautet <b>*********</b>
      </v-col>
      <v-col cols="auto">
        <v-btn text x-small color="primary" @click="show = true">Ändern</v-btn>
      </v-col>
    </v-row>
    <div v-if="show">
      <v-row>
        <v-col>
          <h3 class="mb-3">Passwort</h3>
        </v-col>
        <v-col cols="auto">
          <v-btn text x-small color="primary" @click="show = false">
            Schließen
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="valid">
        <v-row>
          <v-col>
            <o-password-field
              label="Aktuelles Passwort"
              prependInnerIcon=""
              v-model="passwordOld"
            />
          </v-col>
          <v-col>
            <o-password-field
              label="Neues Passwort"
              prependInnerIcon=""
              v-model="passwordNew"
            />
          </v-col>
        </v-row>
      </v-form>
      <span style="display: block">
        Bitte geben Sie zuerst Ihr aktuelles und danach das neue Passwort ein.
        <p>
        Die Passwort-Änderung wird sofort nach Speicherung wirksam. <b>Bitte merken Sie sich Passwort und neue Email-Adresse für Ihren künftigen Login in OrgaCheck!</b>
        </p>
      </span>
      <o-async-btn
        depressed
        color="primary"
        class="mt-3"
        :disabled="!valid"
        :click="save"
      >
        Speichern
      </o-async-btn>
    </div>
    <v-divider class="my-6" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import OPasswordField from "@/common/components/OPasswordField.vue";
import OAsyncBtn from "@/common/components/OAsyncBtn.vue";

export default {
  name: "o-change-password-form",
  components: { OPasswordField, OAsyncBtn },
  data() {
    return {
      valid: false,
      show: false,
      passwordNew: "",
      passwordOld: "",
    };
  },
  methods: {
    async save() {
      return this.updatePassword({
        userId: this.user.user_id,
        password_new: this.passwordNew,
        password_old: this.passwordOld,
      });
    },
    ...mapActions("account", ["updatePassword"]),
  },
  computed: {
    ...mapGetters("account", ["user"]),
    _valid() {
      return this.valid;
    },
  },
};
</script>

<style></style>
