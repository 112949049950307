<template>
  <v-list class="py-0">
    <template v-if="isEmpty">
      <div class="py-12 d-flex justify-center align-center">
        <span style="opacity: 0.5">
          Keine Benachrichtigungen vorhanden...
        </span>
      </div>
    </template>
    <template v-else>
      <div class="d-flex flex-column-reverse">
        <template v-for="(date, key) in items">
          <div
            :key="`item_${key}_${index}`"
            v-for="(item, index) in date"
            class="pb-4"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-row no-gutters align="center">
                  <v-col cols="10">
                    <o-alert-item :value="item" />
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-fade-transition>
                      <v-btn
                        icon
                        @click.prevent="markAlertAsRead(item)"
                        v-show="hover"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </template>
            </v-hover>
          </div>
          <v-subheader :key="`subheader_${key}`">
            <o-date-time
              :key="`subheader_${key}`"
              :value="key"
              :show-time="false"
            />
          </v-subheader>
          <!-- <o-alert-item
            v-for="(item, index) in date"
            :key="`item_${key}_${index}`"
            :value="item"
            class="pr-6 pb-2"
          />
          <v-subheader :key="`subheader_${key}`">
            <o-date-time :key="`subheader_${key}`" :value="key" :show-time="false" />
          </v-subheader> -->
          <!-- <div :key="`section_${key}`">
            <div
            :key="`item_${index}`"
            v-for="(item, index) in date"
            class="pb-4"
            >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-row no-gutters align="center">
                  <v-col cols="10">
                    <o-alert-item :value="item" />
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-fade-transition>
                      <v-btn
                      icon
                      @click.prevent="removeAlert(index)"
                      v-show="hover"
                      >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-hover>
        </div>
      </div> -->
        </template>
      </div>
    </template>
  </v-list>
</template>

<script>
import OAlertItem from "./OAlertItem.vue";
import ODateTime from "@/common/components/ODateTime.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "o-alert-list",
  components: {
    OAlertItem,
    ODateTime,
  },
  props: {
    value: {
      type: Array,
      default: () => ["all"],
    },
  },
  methods: {
    ...mapActions("alert", ["removeAlert", "markAlertAsRead"]),
  },
  computed: {
    ...mapGetters("alert", ["alertsByGroup"]),
    items() {
      return this.alertsByGroup(this.value);
    },
    isEmpty() {
      return Object.keys(this.items).length <= 0;
    },
  },
};
</script>

<style></style>
