<template>
  <v-navigation-drawer 
    :value="show" 
    fixed 
    right 
    clipped 
    outlined 
    width="768px"
    height="100%"
  >
    <template v-slot:prepend>
      <v-toolbar dense flat class="mt-12">
        <v-icon size="26">mdi-pencil</v-icon>
        <v-toolbar-title v-if="show" class="pl-2">
          Umfrage bearbeiten (ID {{ item.survey_id }})</v-toolbar-title
        >
      </v-toolbar>
      <v-divider />
    </template>
    <template v-slot:append>
      <v-divider />
      <v-toolbar flat>
        <v-btn text color="error" @click.prevent="close">
          <v-icon left>mdi-cancel</v-icon>
          Abbruch
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click.prevent="saveItem"
          :disabled="!valid"
        >
          <v-icon left>mdi-checkbox-marked-circle</v-icon>
          Speichern
        </v-btn>
      </v-toolbar>
    </template>
    <div v-if="show">
      <v-form v-model="valid">
        <o-survey-form v-bind="$props" v-model="item" />
        <v-divider />
        <v-container fluid>
          <p class="text-subtitle-2">Zeitraum</p>
          <v-row align="center">
            <v-col cols="1" class="text-caption">Start</v-col>
            <v-col>
              <!--  <o-date-time-picker
                v-model="item.startdate_ux"
                label="Start-Datum und -Zeit"
                :disabled="false"
                :required="true"
              /> -->
              <o-date-time-field
                v-model="item.startdate_ux"
                :max="item.enddate_ux"
                required
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="1" class="text-caption">End</v-col>
            <v-col>
              <!--  <o-date-time-picker
                v-model="item.enddate_ux"
                label="Ende-Datum und -Zeit"
                :disabled="false"
                :required="true"
              /> -->
              <o-date-time-field
                v-model="item.enddate_ux"
                :min="item.startdate_ux"
                required
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-container fluid>
          <p class="text-subtitle-2">Fragebogen</p>
          <o-questionary-select v-model="item.questionnaire_id" />
        </v-container>
        <v-divider />
      </v-form>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import OSurveyForm from "./OSurveyForm.vue";
import { OQuestionarySelect } from "@/modules/company/questionary";
import ODateTimeField from "@/common/components/ODateTimeField.vue";

export default {
  name: "o-survey-edit-drawer",
  components: {
    OSurveyForm,
    OQuestionarySelect,
    ODateTimeField,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,
      item: null,
      types: {
        status: [
          { value: "active", text: "Aktiv" },
          { value: "inactive", text: "Inaktiv" },
          { value: "onhold", text: "Pausiert" },
        ],
      },
    };
  },
  methods: {
    dateToUxTimestamp(timestamp_ux) {
      var d = new Date(timestamp_ux * 1000),
        yyyy = d.getFullYear(),
        mm = ("0" + (d.getMonth() + 1)).slice(-2),
        dd = ("0" + d.getDate()).slice(-2),
        hh = d.getHours(),
        h = hh,
        min = ("0" + d.getMinutes()).slice(-2),
        time;
      // ie: 2014-03-24, 13:00
      time = yyyy + "-" + mm + "-" + dd + ", " + h + ":" + min;
      return time;
    },
    saveItem() {
      if (!this.item || !this.item.company_id) {
        // console.warn(
        //   "could not update or create survey. because item was null or company_id did not exist!"
        // );
        return;
      }

      this.item.startdate = this.dateToUxTimestamp(this.item.startdate_ux);
      this.item.enddate = this.dateToUxTimestamp(this.item.enddate_ux);

      var handler = !this.item.survey_id
        ? this.createSurvey
        : this.updateSurvey;
      handler(this.item);

      this.close();
    },
    close() {
      this.$emit("input", null);
    },
    ...mapActions("surveys", {
      createSurvey: "create",
      updateSurvey: "update",
    }),
  },
  computed: {
    show() {
      return this.item !== null && this.item !== undefined;
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    value(value) {
      // console.log(value);
      this.item = value;
      if (!this.item) return;

      if (!this.item.company_id) {
        this.item.company_id = this.user.company_id;
      }

      this.item.time = null;
      this.item.date = null;

      this.item.startdate_ux = this.item.startdate
        ? Date.parse(this.item.startdate) / 1000
        : Math.floor(Date.now() / 1000);

      this.item.enddate_ux = this.item.enddate
        ? Date.parse(this.item.enddate) / 1000
        : Math.floor(Date.now() / 1000);
    },
  },
};
</script>

<style></style>
