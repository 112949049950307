<template>
  <div>
    <v-toolbar flat dense class="px-0">
      <v-toolbar-title> Organisation </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent.stop="$refs.treeview.updateAll(true)">
          <v-icon> mdi-expand-all </v-icon>
        </v-btn>
        <v-btn icon @click.prevent.stop="$refs.treeview.updateAll(false)">
          <v-icon> mdi-collapse-all </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-text-field
      class="mx-4"
      placeholder="Suche..."
      hide-details
      dense
      single-line
      outlined
      prepend-inner-icon="mdi-magnify"
      clearable
    ></v-text-field>
    <v-container>
      <v-treeview
        v-if="items"
        :items="items"
        :active.sync="_active"
        ref="treeview"
        item-key="id"
        item-children="children"
        activatable
        hoverable
        dense
        open-all
        transition
      >
        <template v-slot:label="{ item }">
          <v-list-item class="px-0">
            <v-list-item-avatar>
              <v-avatar
                size="24"
                :color="`${getColorGroupbyId(item.data.colorGroup).color}`"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.data.label }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.data.sublabel }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:append="{ active, item }">
          <v-fade-transition>
            <div v-if="active">
              <v-btn icon @click.prevent.stop="$emit('add', item.data)">
                <v-icon> mdi-plus-thick</v-icon>
              </v-btn>
              <v-btn
                icon
                v-if="item.data.id !== '0'"
                color="error"
                @click.prevent.stop="$emit('delete', item.data)"
              >
                <v-icon> mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </v-fade-transition>
        </template>
      </v-treeview>
    </v-container>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "o-chart-treeview",
  props: {
    active: {},
    graph: {
      type: Array,
    },
    colorGroups: {
      type: Array,
    },
  },
  data() {
    return {
      items: null,
    };
  },
  mounted() {
    this.items = this.buildTree(this.graph);
  },
  methods: {
    getColorGroupbyId(id) {
      return (
        this.colorGroups.find((group) => group.id === id) ?? this.colorGroups[0]
      );
    },
    buildTree(graph) {
      if (graph === null || graph === undefined || graph.length <= 0) return;

      const root = d3
        .stratify()
        .id(function (d) {
          return d.nodeId || d.id;
        })
        .parentId(function (d) {
          return d.parentNodeId || d.parentId;
        });
      return [root(graph)];
    },
  },
  computed: {
    _active: {
      get() {
        return this.active ? [this.active] : [];
      },
      set(value) {
        this.$emit("update:active", value[0] ?? null);
      },
    },
  },
  watch: {
    graph(value) {
      this.items = this.buildTree(value);
    },
  },
};
</script>

<style></style>
