<template>
  <h1 class="text-h3">
    Willkommen, <b>{{ user.company_name }}</b>
  </h1>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "o-welcome-message",
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style></style>
