<template>
  <o-loader getter="poll/checkin" :fetchParam="{ ...query }" ref="item">
    <template v-slot:default="{ value }">
      <v-app-bar
        app
        fixed
        flat
        height="90"
        clipped-right
        extension-height="55px"
      >
        <v-container>
          <v-row>
            <v-col cols="auto">
              <o-logo style="width: 200px" />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn icon @click.prevent="toggleDarkMode">
                <v-icon>{{
                  $vuetify.theme.dark
                    ? "mdi-weather-night"
                    : "mdi-white-balance-sunny"
                }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-app-bar-nav-icon
                @click="drawer = !drawer"
                v-if="_step === 3"
              />
            </v-col>
          </v-row>
        </v-container>
        <template v-slot:extension>
          <v-container class="pa-0">
            <v-item-group v-model="_step" mandatory>
              <v-layout
                :wrap="false"
                align-center
                align-content-center
                style="overflow-x: auto"
              >
                <v-flex v-for="(item, i) in items" :key="i">
                  <v-item v-slot="{ active, toggle }" :value="i + 1">
                    <v-card
                      flat
                      width="100%"
                      height="50"
                      class="d-flex align-center px-4"
                      :color="active ? selectedBackgroundColor : 'none'"
                      v-on="
                        item.clickable && _step < items.length && i < progress
                          ? { click: toggle }
                          : {}
                      "
                    >
                      <v-avatar
                      :dark="false"
                        size="28"
                        :color="
                          active || i < progress ? 'primary' : 'secondary'
                        "
                        class="white--text mx-2"
                      >
                        <v-icon color="white" v-if="i < progress" :key="1">
                          mdi-check
                        </v-icon>
                        <div v-else :key="2">
                          {{ i + 1 }}
                        </div>
                      </v-avatar>
                      {{ item.title }}
                    </v-card>
                  </v-item>
                </v-flex>
              </v-layout>
            </v-item-group>
          </v-container>
        </template>
      </v-app-bar>
      <v-main>
        <v-fade-transition mode="out-in">
          <component
            :is="items[step - 1].comp"
            v-bind:value="value"
            @next="_step++"
            @back="_step--"
            :drawer.sync="drawer"
          />
        </v-fade-transition>
      </v-main>
    </template>
  </o-loader>
</template>

<script>
//phash: 38da245804542427259dcbb861eac0dc
//shash: 4711

import OLoader from "@/common/components/OLoader.vue";
import OLogo from "@/common/components/OLogo.vue";
import {
  OPollWelcome,
  OPollDepartment,
  OPollQuestionnaire,
  OPollFarewell,
} from "@/modules/poll";

import { OAlertMenu } from "@/modules/system/alert";

export default {
  name: "o-poll-view",
  components: {
    OLoader,
    OLogo,
    OAlertMenu,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      step: 1,
      progress: 1,
      drawer: false, // setzt Poll Subcategory-Navigation
      items: [
        { title: "Willkommen", comp: OPollWelcome, clickable: true },
        {
          title: "Organisationszuweisung",
          comp: OPollDepartment,
          clickable: true,
        },
        { title: "Umfrage", comp: OPollQuestionnaire, clickable: true },
        { title: "Abschied", comp: OPollFarewell, clickable: false },
      ],
    };
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "darkmode",
        JSON.stringify(this.$vuetify.theme.dark)
      );
    },
  },
  computed: {
    selectedBackgroundColor() {
      return this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(0, 0, 0, 0.05)";
    },
    query() {
      const split = this.id.split("&");
      return {
        phash: split[0],
        shash: split[1],
      };
    },
    _step: {
      get() {
        return this.step;
      },
      set(value) {
        this.step = value;
        this.progress = Math.max(this.progress, this.step);
      },
    },
  },
};
</script>

<style scoped></style>
