<template>
  <!--  <v-form v-model="valid" @submit.prevent.stop="submit">
    <v-card flat class="ma-3 pa-4" color="transparent">
      <v-row>
        <v-col cols="3">
          <v-row no-gutters>
            <v-col align-self="center" cols="auto">
              <v-icon large color="primary">mdi-email</v-icon>
            </v-col>
            <v-col>
              <v-card-title>Email ändern</v-card-title>
              <v-card-subtitle
                >Nach erfolgreichen ändern. Werden sie zum Login weitergeleitet
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-card-text>
            <p>Email Adresse</p>
            <o-email-field v-model="email" placeholder="" disable-icon />
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          large
          color="primary"
          :disabled="!valid"
          type="submit"
        >
          Speichern
        </v-btn>
      </v-card-actions>
      <v-divider />
    </v-card>
  </v-form> -->
  <div>
    <v-row justify="center" align="start" v-if="!show">
      <v-col>
        <h3 class="mb-3">Email Adresse</h3>
        Aktuelle Anmeldung mit folgender Email-Adresse: <b>{{ user.email }}</b>
      </v-col>
      <v-col cols="auto">
        <v-btn text x-small color="primary" @click="show = true">Ändern</v-btn>
      </v-col>
    </v-row>
    <div v-else>
      <v-row>
        <v-col>
          <h3 class="mb-3">Email Adresse</h3>
        </v-col>
        <v-col cols="auto">
          <v-btn text x-small color="primary" @click="show = false">
            Schließen
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="valid">
        <v-row>
          <v-col>
            <o-email-field
              label="Aktuelle Email"
              placeholder="max@mustermann.de"
              prepend-inner-icon=""
              v-model="emailOld"
            />
          </v-col>
          <v-col>
            <o-email-field
              label="Neue Email"
              placeholder="max@mustermann.de"
              prepend-inner-icon=""
              v-model="emailNew"
            />
          </v-col>
        </v-row>
      </v-form>
      <span style="display: block">
        Bitte geben Sie zuerst Ihre aktuelle und danach die neue Email-Adresse ein.
        Nach der Speicherung können Sie sich mit der neuen Email-Adresse anmelden. Alle System-Nachrichten werden an die neue Email-Adresse versendet.
        <b>Bitte merken Sie sich Passwort und neue Email-Adresse für Ihren künftigen Login in OrgaCheck!</b>
      </span>
      <o-async-btn
        depressed
        color="primary"
        class="mt-3"
        :disabled="!_valid"
        :click="submit"
      >
        Speichern
      </o-async-btn>
    </div>
    <v-divider class="my-6" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OEmailField from "@/common/components/OEmailField.vue";
import OAsyncBtn from "@/common/components/OAsyncBtn.vue";

export default {
  name: "o-change-email-form",
  components: { OEmailField, OAsyncBtn },
  data() {
    return {
      valid: false,
      show: false,
      emailNew: "",
      emailOld: "",
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    _valid() {
      return (
        this.user.email !== this.newEmail &&
        this.newEmail === this.newEmailConfirm &&
        this.valid
      );
    },
  },
  methods: {
    ...mapActions("account", ["updateEmail"]),
    async submit() {
      return this.updateEmail({
        userId: this.user.id,
        email_new: this.emailNew,
        email_old: this.emailOld,
      });
    },
  },
};
</script>

<style></style>
