<template>
  <div class="py-8">
    <!--  <v-navigation-drawer app right clipped floating width="450" /> -->
    <o-center>
      <v-card flat max-width="1180" class="px-6 py-3" rounded="xl">
        <v-card-title> Organisationszuweisung </v-card-title>
        <v-card-subtitle>
          Bitte ordnen Sie sich ihrer Organisationseinheit zu. Diese Angabe ist
          Pflicht. Falls dies nicht möglich ist, wählen Sie die nächstliegende
          Einheit aus.
        </v-card-subtitle>
        <v-card-text>
          <o-poll-tree-field
            :graph="_graph"
            :surveyId="`${value.survey_id}`"
            :active.sync="_active"
          />
        </v-card-text>
      </v-card>
    </o-center>
    <v-footer app outlined>
      <v-container>
        <v-row>
          <v-col cols="auto">
            <v-btn rounded x-large depressed text left @click="$emit('back')">
              <v-icon>mdi-chevron-left</v-icon>
              Zurück
            </v-btn>
          </v-col>
          <v-col></v-col>
          <v-col cols="auto">
            <v-btn
              rounded
              x-large
              depressed
              color="primary"
              right
              :disabled="!value"
              @click="$emit('next')"
            >
              Weiter
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import OCenter from "@/common/components/OCenter.vue";
import { OPollTreeField } from "@/modules/company/tree";

export default {
  name: "o-poll-department",
  components: {
    OCenter,
    OPollTreeField,
    //OLogo,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,
      selected: [],
    };
  },
  mounted() {
    console.log(this.value);
  },
  methods: {
    ...mapActions("poll", ["updateNode"]),
  },
  computed: {
    _graph() {
      return JSON.parse(this.value.tree.graph[0]);
    },
    _valid() {
      return (
        this.value.subject_node_id !== null &&
        this.value.subject_node_id !== undefined &&
        this.value.subject_node_id !== ""
      );
    },
    _active: {
      get() {
        return [this.value.subject_node_id];
      },
      set(value) {
        const id = value[0];
        if (id) {
          const copy = Object.assign(this.value, {
            subject_node_id: id,
          });

          this.$emit("input", copy);

          this.updateNode({
            phash: this.value.poll_hash,
            shash: this.value.subject_hash,
            node_id: id,
          });
        }
      },
    },
  },
};
</script>

<style></style>
