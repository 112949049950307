<template>
  <v-chip-group mandatory active-class="primary--text" v-model="theme">
    <v-chip class="pa-6" :value="false">
      Light <v-icon right>mdi-white-balance-sunny</v-icon>
    </v-chip>
    <v-chip class="pa-6" :value="true">
      Dark <v-icon right>mdi-weather-night</v-icon>
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  name: "o-change-theme-switch",
  computed: {
    theme: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(value) {
        this.$vuetify.theme.dark = value;
        localStorage.setItem("darkmode", JSON.stringify(value));
      },
    },
  },
};
</script>

<style></style>
