const state = {
  modalVisible: false,
  modalPromise: null,
  modalContent: '',
};

const actions = {
  async awaitModal({ commit, state }, content) {
    console.log('awaitModal called');
    commit("setModalContent", content);
    commit("setModalVisible", true);
    return new Promise((resolve) => {
        commit("setModalPromise", resolve);
      });
  },

  closeModal({ commit }) {
    commit("setModalVisible", false);
    commit("resolveModalPromise", false);
  },
  acceptModal({ commit }) {
    commit("setModalVisible", false);
    commit("resolveModalPromise", true);
  },
};

const mutations = {
  setModalVisible(state, visible) {
    state.modalVisible = visible;
  },
  setModalContent(state, content) {
    state.modalContent = content;
  },
  setModalPromise(state, promise) {
    state.modalPromise = promise;
  },
  resolveModalPromise(state, value) {
    if (!state.modalPromise) return;

    state.modalPromise(value);
    state.modalPromise = null;
  },
};

const getters = {
  visible: (state) => state.modalVisible,
  content: (state) => state.modalContent,
};

export const modal = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
