<template>
  <v-card rounded="xl" flat :color="backgroundColor" class="py-4 px-4" style="width: 100%">
    <v-card-title class="mb-1">
      <h4>Frage {{ index }}</h4>
    </v-card-title>
    <v-card-subtitle class="text-h6 mb-1" v-if="value.scaletype !== 33">
      {{ value.text }}
    </v-card-subtitle>
    <v-card-text>
      <o-poll-scalartype-item :question="value" v-model="answer" class="pt-1" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OPollScalartypeItem from "./scalartypes/OPollScalartypeItem.vue";

export default {
  name: "o-poll-question-item",
  components: {
    OPollScalartypeItem,
  },
  props: {
    index: {
      type: Number,
    },
    value: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("poll", ["updateAnswer"]),
  },
  computed: {
    backgroundColor() {
      return this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.05)"
        : "rgba(0, 0, 0, 0.05)";
    },
    answer: {
      get() {
        return this.value.rawvalue;
      },
      set(value) {
        const newValue = Object.assign(this.value, { rawvalue: value });
        this.$emit("input", newValue);

        this.updateAnswer({
          phash: this.checkin.value.poll_hash,
          shash: this.checkin.value.subject_hash,
          question_id: this.value.qid,
          rawvalue: value,
        });
      },
    },

    ...mapGetters("poll", ["checkin"]),
  },
};
</script>

<style scoped></style>
