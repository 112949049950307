<template>
  <apex-chart
    type="area"
    :options="chart.options"
    :series="chart.series"
    v-bind="{ ...$attrs }"
    class="ma-0"
  />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "o-survey-activity-chart",
  props: {
    value: {
      type: String,
      required: true,
    },
    sparkline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chart: {
        options: {
          chart: {
            id: this.value,
            type: "area",
            //group: "activity",
            toolbar: {
              show: false,
            },
            stacked: false,
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: this.sparkline,
            },
            background: "rgba(0, 0, 0, 0)",
          },
          stroke: {
            curve: "smooth",
          },
          noData: {
            text: "loading...",
          },
          theme: {
            mode: this.$vuetify.theme.dark ? "dark" : "light",
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 5,
          },
          xaxis: {
            show: false,
            type: "category",
            labels: {
              show: false,
              formatter: (value) => {
                return this.$utils.formatDate(
                  new Date(value),
                  true,
                  false,
                  "short"
                );
              },
            },
          },
          yaxis: {
            show: true,
          },
        },
        series: [],
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const data = await this.getSurveySeries(this.value, 14);
      this.chart.series = data;
    },
    ...mapActions("surveys", ["getSurveySeries"]),
  },
};
</script>

<style></style>
