<template>
  <div>
    <v-toolbar dense flat>
      <v-text-field
        placeholder="search"
        flat
        dense
        hide-details
        clearable
        rounded
        filled
        prepend-inner-icon="mdi-magnify"
        v-model="search"
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            rounded
            @click="$refs.treeview.updateAll(true)"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-expand-all</v-icon>
          </v-btn>
        </template>
        Knoten ausklappen
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            rounded
            @click="$refs.treeview.updateAll(false)"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon>mdi-collapse-all</v-icon>
          </v-btn>
        </template>
        Zuklappen
      </v-tooltip>
    </v-toolbar>
    <o-scrollable :max-height="maxHeight" v-if="!loading">
      <v-treeview
        ref="treeview"
        v-model="_select"
        :active.sync="_active"
        :items="items"
        item-text="data.label"
        rounded
        :activatable="!multiple"
        :selectable="multiple"
        selection-type="independent"
        hoverable
        open-all
        dense
        :search="search"
      >
        <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
          <slot :name="name" v-bind="item"></slot>
        </template>
        <template #label="{ item }">
          <span class="text-subtitle-2">{{ item.data.label }}</span>
        </template>
      </v-treeview>
    </o-scrollable>
  </div>
</template>

<script>
import * as d3 from "d3";
import OScrollable from "@/common/components/OScrollable.vue";
import { mapActions } from "vuex";

export default {
  components: { OScrollable },
  name: "o-survey-tree-field",
  props: {
    value: {
      type: Array,
    },
    active: {
      type: Array,
    },
    surveyId: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "256px",
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      search: "",
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const tree = await this.getFieldTreeBySurveyId(this.surveyId);
    this.loading = false;

    if (tree) {
      this.items = this.parseGraph(tree.graph);
    }
  },
  methods: {
    parseGraph(value) {
      if (!value) return;

      const root = d3
        .stratify()
        .id(function (d) {
          return d.nodeId || d.id;
        })
        .parentId(function (d) {
          return d.parentNodeId || d.parentId;
        });

      return [root(value)];
    },
    ...mapActions("tree", ["getFieldTreeBySurveyId"]),
  },
  computed: {
    _select: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    _active: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("update:active", value);
      },
    },
  },
  /* watch: {
    graph: {
      handler: function (value) {
        if (!value) return;

        console.log(value);
        this.items = this.parseGraph(this.graph);
        this.itemCount = this.graph.length;
      },
      deep: true,
    },
  }, */
};
</script>

<style></style>

