import { userService, jwtService } from "@/services";
import router from "@/router";

const state = { status: {}, user: null };

const actions = {
  async login({ dispatch, commit }, { username, password }) {
    commit("loginRequest");

    try {
      await userService.login(username, password);

      const { payload } = await jwtService.get();
      const user = await userService.get(payload.user_id);
      commit("loginSuccess", user);
      await router.push("/");
    } catch (error) {
      commit("loginFailure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },

  async logout({ dispatch, commit }) {
    try {
      await userService.logout();
      await router.push("/login");
      commit("logout");

    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },

  async register({ dispatch, commit }, user) {
    try {
      await userService.register(user);
      commit("registerSuccess", user);
      setTimeout(() => {
        dispatch("alert/showAlertSuccess", "Registration successful", { root: true });
      });
    } catch (error) {
      commit("registerFailure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async updateStatus({ dispatch, commit }) {
    const { valid, payload } = await jwtService.get();

    if (!valid) {
      dispatch("logout");
      return;
    }
    try {
      const user = await userService.get(payload.user_id);
      commit("loginSuccess", user);
    } catch (error) {
      commit("loginFailure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async resetPassword({ commit, dispatch }, data) {
    try {
      await userService.resetPassword(data);
      dispatch("alert/showAlertSuccess", "Ein neues Passwort wurde gesendet!", {
        root: true,
      });
      return Promise.resolve();
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async updateEmail({ dispatch }, { userId, email_new, email_old }) {
    try {
      const res = await userService.updateEmail(userId, {
        email_new,
        email_old,
      });
      dispatch("alert/showAlertSuccess", "Email wurde erfolgreich aktualisiert!", {
        root: true,
      });
      dispatch("updateStatus");

      return Promise.resolve(res);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async updatePassword({ dispatch }, { userId, password_new, password_old }) {
    try {
      const res = await userService.updatePassword(userId, {
        password_new,
        password_old,
      });

      dispatch(
        "alert/showAlertSuccess",
        "Passwort wurde erfolgreich aktuallisiert!",
        { root: true }
      );
      return Promise.resolve(res);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async requestDelete({ dispatch }, userId) {
    try {
      const res = await userService.requestDelete(userId);

      dispatch(
        "alert/showAlertSuccess",
        "Antrag wurde erfolgreich eingereicht! Sie bekommen in kurz eine Email mit weiteren Details",
        { root: true }
      );
      return Promise.resolve(res);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
};

const mutations = {
  loginRequest(state) {
    state.status = { loggingIn: true };
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state, token) {
    state.status = { registering: true };
  },
  registerSuccess(state, token) {
    state.status = {};
  },
  registerFailure(state, token) {
    state.status = {};
  },
};

const getters = {
  status: (state) => state.status,
  user: (state) => state.user,
  role: (state) => {
    if (!state.user || !state.user.user_role) return "guest";
    return state.user.user_role;
  },
  companyId: (state) => (state.user && state.user.company_id) || -1,
};

export const account = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
