import { reportingsService } from "@/services";

const state = {
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  options: {}
};

const actions = {
  async getAll({ commit, dispatch, rootGetters, getters }, options) {
    try {
      commit("loading");
      const company_id = rootGetters['account/companyId'];
      if (company_id === null) { return []; }
      const opt = options ?? getters.options;
      const response = await reportingsService.getAll({ company_id, options: opt });
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async create({ dispatch }, data) {
    try {
      const { reporting_id } = await reportingsService.create(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Eine neue Auswertung wurde angelegt",
        {
          root: true,
        }
      );
      return Promise.resolve(reporting_id);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async update({ dispatch }, data) {
    try {
      await reportingsService.update(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Auswertung wurde erfolgreich aktualisiert",
        { root: true }
      );
      return data.reporting_id;
    } catch (error) {
      dispatch("alert/error", error, { root: true });
      return error;
    }
  },

  async delete({ dispatch }, data) {
    try {
      await reportingsService.delete(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Die Auswertung und Berichte sollen gelöscht werden",
        {
          root: true,
        }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async getReportCount({ dispatch, rootGetters }, id) {
    try {
      const company_id = rootGetters["account/companyId"];
      const count = await reportingsService.getReportCount(company_id);
      return Promise.resolve(parseInt(count.n_reportings));
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async updateOptions({ commit, dispatch }, options) {
    // console.log('options:', options);
    await commit("setOptions", options);
    dispatch("getAll");
  }
};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, value) {
    state.isLoading = false;
    state.error = value;
  },
  setOptions(state, options) {
    state.options = options;
  }
};

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  options: (state) => state.options,
};

export const reportings = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
