import { versionService } from "@/services";

const state = {
  version: null,
  error: null,
};

const actions = {
  async getVersion({ dispatch } ) {
    try {
      const result = await versionService.getServerVersion();
      // console.log("Result: ", result.version);
      return Promise.resolve(result);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
};

const getters = {
  version: (state) => state.version,
};

export const version = {
  namespaced: true,
  state,
  actions,
  getters,
};


