<template>
  <div>
    <v-toolbar flat dense class="px-0">
      <v-toolbar-title> Kategorien </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent.stop="$refs.treeview.updateAll(true)">
          <v-icon> mdi-expand-all </v-icon>
        </v-btn>
        <v-btn icon @click.prevent.stop="$refs.treeview.updateAll(false)">
          <v-icon> mdi-collapse-all </v-icon>
        </v-btn>
        <v-btn icon @click.prevent.stop="addGroup">
          <v-icon> mdi-plus-thick </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-text-field
      v-model="search"
      outlined
      dense
      hide-details
      placeholder="Suche..."
      prepend-inner-icon="mdi-magnify"
      class="mx-4"
      clearable
    >
    </v-text-field>
    <v-container>
      <v-treeview
        :items="_items"
        :search="search"
        :open.sync="open"
        item-children="children"
        item-key="data.id"
        transition
        hoverable
        ref="treeview"
        open-all
      >
        <template #label="{ item }">
          <div class="pb-1">
            <v-text-field
              v-model="item.data.name"
              flat
              hide-details
              single-line
              dense
            />
          </div>
        </template>
        <template #append="{ item, leaf }">
          <v-row no-gutters v-if="!leaf">
            <v-col cols="auto">
              <v-btn icon @click.prevent.stop="() => addCategory(item.data.id)">
                <v-icon> mdi-plus-thick </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                icon
                color="error"
                @click.prevent.stop="deleteGroup(item.data.id)"
              >
                <v-icon> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn
            v-else
            icon
            color="error"
            @click.prevent.stop="deleteCategory(item.data.id)"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "o-chart-report-group-edit-drawer",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    reportingGroups: {
      type: Array,
      default: () => {
        return [];
      },
    },
    reportingCategories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      search: "",
      open: [],
    };
  },
  methods: {
    generateId(maxLength = 12) {
      return (
        Date.now().toString(maxLength) +
        Math.floor(
          Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
        ).toString(maxLength)
      );
    },
    addGroup() {
      const group = {
        id: this.generateId(),
        name: `Neue Gruppe ${this._groups.length}`,
        children: [],
      };

      this._groups.push(group);
    },
    addCategory(group_id) {
      const category = {
        id: this.generateId(),
        name: `Neue Kategorie ${this._categories.length}`,
        group_id: group_id,
      };

      this.open.push(group_id);
      this._categories.push(category);
    },
    async deleteGroup(id) {
      try {
        const permission = await this.awaitModal({
          title: "Gruppe löschen?",
          message: "Wollen Sie die Gruppe wirklich löschen?",
        });
        if (permission) {
          this._categories = this._categories.filter((cat) => {
            return cat.group_id !== id;
          });

          this._groups = this._groups.filter((group) => {
            return group.id !== id;
          });

          return Promise.resolve();
        }
      } catch (error) {
        console.log(error);
        return Promise.reject();
      }
    },
    async deleteCategory(id) {
      try {
        const permission = await this.awaitModal({
          title: "Kategorie löschen?",
          message: "Wollen Sie die Kategorie wirklich löschen?",
        });

        if (permission) {
          this._categories = this._categories.filter((cat) => {
            return cat.id !== id;
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.log(error);
        return Promise.reject();
      }
    },
    getCategoryById(group_id) {
      return this._categories.filter((cat) => {
        return cat.group_id === group_id;
      });
    },
    ...mapActions("modal", ["awaitModal"]),
  },
  computed: {
    _items() {
      /*  const groups = [...this._groups];
      groups.forEach((group) => {
        group.children = this._categories.filter((cat) => {
          return cat.group_id === group.id;
        });
      });

      return groups; */

      /* const groups = [];
      this._groups.forEach((group) => {
        groups.push({
          ...group,
          children: this._categories.filter((cat) => {
            return cat.group_id === group.id;
          }),
        });
      });

      return groups; */

      const groups = [];
      this._groups.forEach((group) => {
        const children = [];
        this._categories.forEach((cat) => {
          if (cat.group_id === group.id) {
            children.push({
              data: cat,
            });
          }
        });

        groups.push({
          data: group,
          children,
        });
      });

      return groups;
    },
    _groups: {
      get() {
        return this.reportingGroups;
      },
      set(value) {
        this.$emit("update:reportingGroups", value);
      },
    },
    _categories: {
      get() {
        return this.reportingCategories;
      },
      set(value) {
        this.$emit("update:reportingCategories", value);
      },
    },
  },
};
</script>

<style>
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0);
}

.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0);
}
</style>
