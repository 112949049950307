<template>
  <component :is="roles[user.user_role]" />
</template>

<script>
import { mapGetters } from "vuex";
import { OUserDashboard, OAdminDashboard } from "@/modules/user/dashboard";

export default {
  name: "o-dashboard-view",
  data() {
    return {
      roles: {
        admin: OAdminDashboard,
        user: OUserDashboard,
      },
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style , OCompanyTablescoped></style>
