<template>
  <v-select
    v-model="_value"
    label="Bitte wählen Sie eine Umfrage"
    :items="items"
    item-value="survey_id"
    item-text="name"
    filled
    rounded
    :loading="isLoading"
    :menu-props="{
      offsetY: true,
      rounded: 'xl',
    }"
    clearable
    v-bind="{ ...$attrs, ...$props }"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "o-survey-select",
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    const response = await this.getAll({});
    this.items = response.items;
  },
  methods: {
    ...mapActions("surveys", ["getAll"]),
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters("surveys", ["isLoading"]),
  },
};
</script>

<style></style>
