<template>
  <v-container fluid class="fill-height pa-0">
    <v-app-bar app flat dense>
      <v-spacer />
      <!-- <o-alert-drawer-btn /> -->
    </v-app-bar>
    <v-main>
      <o-login />
    </v-main>
  </v-container>
</template>

<script>
//import { OAlertDrawerBtn } from "@/modules/system/alert";
import { OLogin } from "@/modules/user/login";
export default {
  name: "o-login-view",
  components: {
    OLogin,
    //OAlertDrawerBtn,
  },
  data() {
    return {
      window: 0,
    };
  },
};
</script>

<style></style>
