<template>
  <v-navigation-drawer absolute right width="450px" :value="show" hide-overlay>
    <template v-slot:prepend>
      <v-fade-transition>
        <v-toolbar dense flat v-if="show">
          <v-btn icon @click="close">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title class="pl-2">
            <v-list-item-content>
              <v-list-item-title> Abteilung Bearbeiten </v-list-item-title>
                <v-list-item-subtitle class="text--secondary">
                {{ node.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon @click="$emit('add', node, 0)">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              :disabled="value.id === '0'"
              icon
              color="error"
              @click="$emit('delete', node, 0)"
            >
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-fade-transition>
      <v-divider />
    </template>
    <v-fade-transition>
      <v-list v-if="show">
        <v-list-item>
          <v-list-item-content class="px-4">
            <v-text-field
              rounded
              filled
              v-model="node.label"
              label="Bezeichnung"
              :readonly="!editable"
              @keydown="cancel"
            />
            <v-text-field
              rounded
              filled
              label="Unterbezeichnung"
              v-model="node.sublabel"
              :readonly="!editable"
              @keydown="cancel"
            />
            <v-textarea
              rounded
              filled
              auto-grow
              label="Beschreibung"
              v-model="node.description"
              :readonly="!editable"
              @keydown="cancel"
            />
            <o-chart-category-select
              :items="colorGroups"
              v-model="node.colorGroup"
              :readonly="!editable"
              @keydown="cancel"
            />
            <o-chart-report-group-select
              :groups="reportingGroups"
              :categories="reportingCategories"
              v-model="node.reportingCategories"
              @keydown="cancel"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <slot name="append" v-bind="{ node }"></slot>
        </v-list-item>
      </v-list>
    </v-fade-transition>
    <template #append>
      <v-divider />
      <v-toolbar flat dense>
        <v-btn text color="error" @click.prevent.stop="close">
          <v-icon left>mdi-cancel</v-icon>
          Abbruch
        </v-btn>
        <v-spacer />
        <v-btn text color="primary" @click.prevent.stop="save">
          <v-icon left>mdi-checkbox-marked-circle</v-icon>
          Speichern
        </v-btn>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
import OChartCategorySelect from "./OChartCategorySelect.vue";
import OChartReportGroupSelect from "./OChartReportGroupSelect.vue";

export default {
  name: "o-chart-node-editor",
  components: {
    OChartCategorySelect,
    OChartReportGroupSelect,
  },
  props: {
    value: {
      type: Object,
    },
    reportingGroups: {
      type: Array,
    },
    reportingCategories: {
      type: Array,
    },
    colorGroups: {
      type: Array,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      node: null,
    };
  },
  methods: {
    save() {
      // console.log(this.node);
      this.$emit("input", {
        ...this.value,
        data: Object.assign(this.value.data, this.node),
      });
      this.$nextTick(() => this.close());
    },
    close() {
      this.$emit("close");
    },
    cancel(e) {
      if (e.key !== "Escape") return;
      e.target.blur();
    },
  },
  computed: {
    show() {
      return this.node !== null && this.node !== undefined;
    },
  },
  watch: {
    value(value) {
      this.node = value ? { ...value.data } : null;
    },
  },
};
</script>

<style></style>

<!-- <template>
  <v-navigation-drawer absolute right width="450px" :value="show">
    <template v-slot:prepend>
      <v-fade-transition>
        <v-toolbar dense flat v-if="show">
          <v-btn icon @click="$emit('close')">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title class="pl-2">
            <v-list-item-content>
              <v-list-item-title>
                Bearbeiten {{ value.data.label }}
              </v-list-item-title>
              <v-list-item-subtitle class="text--secondary">
                {{ value.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon @click="$emit('add', data, 0)">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              :disabled="value.id === '0'"
              icon
              color="error"
              @click="$emit('delete', data, 0)"
            >
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-fade-transition>
      <v-divider />
    </template>
    <v-fade-transition>
      <v-list v-if="show">
        <v-list-item>
          <v-list-item-content class="px-4">
            <v-text-field
              rounded
              filled
              label="Bezeichnung"
              v-model="_label"
              :readonly="!editable"
              @keydown="cancel"
            />
            <v-text-field
              rounded
              filled
              label="Unterbezeichnung"
              v-model="_sublabel"
              :readonly="!editable"
              @keydown="cancel"
            />
            <v-textarea
              rounded
              filled
              auto-grow
              label="Beschreibung"
              v-model="_description"
              :readonly="!editable"
              @keydown="cancel"
            />
            <o-chart-category-select
              v-model="_colorGroup"
              :items="colorGroups"
              :readonly="!editable"
              @keydown="cancel"
            />
            <o-chart-report-group-select
              v-model="_reportingCategories"
              :groups="reportingGroups"
              :categories="reportingCategories"
              @keydown="cancel"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <slot name="append" v-bind="{ node: value }"></slot>
        </v-list-item>
      </v-list>
    </v-fade-transition>
  </v-navigation-drawer>
</template>

<script>
import OChartCategorySelect from "./OChartCategorySelect.vue";
import OChartReportGroupSelect from "./OChartReportGroupSelect.vue";

export default {
  name: "o-chart-node-editor",
  components: {
    OChartCategorySelect,
    OChartReportGroupSelect,
  },
  props: {
    value: {
      type: Object,
    },
    reportingGroups: {
      type: Array,
    },
    reportingCategories: {
      type: Array,
    },
    colorGroups: {
      type: Array,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    update(key, value) {
      this.data[key] = value;
    },
    cancel(e) {
      if (e.key !== "Escape") return;
      e.target.blur();
    },
  },
  computed: {
    show() {
      return this.value !== null && this.value !== undefined;
    },
    data() {
      return this.value.data;
    },
    _label: {
      get() {
        return this.data.label;
      },
      set(value) {
        this.update("label", value);
      },
    },
    _sublabel: {
      get() {
        return this.data.sublabel;
      },
      set(value) {
        this.update("sublabel", value);
      },
    },
    _colorGroup: {
      get() {
        return this.data.colorGroup;
      },
      set(value) {
        this.update("colorGroup", value);
      },
    },
    _reportingCategories: {
      get() {
        return this.data.reportingCategories;
      },
      set(value) {
        this.update("reportingCategories", value);
      },
    },
    _category: {
      get() {
        return this.data.category;
      },
      set(value) {
        this.update("category", value);
      },
    },
    _description: {
      get() {
        return this.data.description;
      },
      set(value) {
        this.update("description", value);
      },
    },
  },
};
</script>

<style></style>
 -->
