
const AlertLoadMixin = {
    mounted() {
        const user = this.$store.getters["account/user"];
        if ((user === null || user === undefined)
            && (user.company_id === null || user.company_id === undefined)) {
            console.warn("Cannot load alerts because user is not defined!");
            return;
        }

        this.$store.dispatch("alert/loadAlerts");
    },
}

export default AlertLoadMixin;