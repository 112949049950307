<template>
  <div v-if="this.company !== null">
    <h2 class="pb-6">Organisations Einstellungen</h2>
    <div>
      <v-row>
        <v-col cols="auto">
          <o-image-input disabled />
        </v-col>
        <v-col>
          <v-form v-model="valid">
            <p class="text-subtitle-2">Details</p>
            <v-text-field
              rounded
              filled
              label="Name *"
              placeholder="Tester GmbH"
              v-model="company.name"
              :rules="rules"
            />
            <v-textarea
              rounded
              filled
              label="Beschreibung"
              v-model="company.description"
            />
            <o-address-field
              :address.sync="company.address"
              :city.sync="company.city"
              :zip.sync="company.zip"
            />
          </v-form>
          <small class="text--secondary">Pflichtfelder *</small>
        </v-col>
      </v-row>
      <v-btn
        depressed
        color="primary"
        class="mt-6"
        :disabled="!valid"
        @click="
          () => {
            updateCompany({
              company_id: user.company_id,
              name: company.name,
              description: company.description,
              address: company.address,
              zip: company.zip,
              city: company.city,
            });
          }
        "
      >
        Speichern
      </v-btn>
      <v-divider class="my-6" />
    </div>
  </div>
</template>

<script>
/* {
  "name": "string",
  "description": "string",
  "address": "string",
  "zip": "string",
  "city": "string",
  "identifier": "string"
} */

import OImageInput from "@/common/components/OImageInput.vue";
import OAddressField from "@/common/components/OAddressField.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "o-company-settings-view",
  components: {
    OImageInput,
    OAddressField,
  },
  data() {
    return {
      valid: false,
      company: null,
      rules: [(v) => !!v],
    };
  },
  async mounted() {
    this.company = await this.getCompany(this.user.company_id);
  },
  methods: {
    ...mapActions("company", {
      getCompany: "get",
      updateCompany: "update",
    }),
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style></style>
