import Vue from "vue";
import Vuex from "vuex";

import { account } from "@/modules/user/login";
import { alert } from "@/modules/system/alert";
import { modal } from "@/modules/system/modal";
import { version } from "@/modules/system/version";

import { company } from "@/modules/company/editor";
import { tree } from "@/modules/company/tree";
import { questionary } from "@/modules/company/questionary";
import { questions } from "@/modules/company/questions";
import { orgagroups } from "@/modules/company/orgagroups";
import { poll } from "@/modules/poll";
import { reportings, rules } from "@/modules/company/reportings";
import { surveys } from "@/modules/company/surveys";
import { subjects } from "@/modules/company/subjects";
import { archive } from "@/modules/company/archive";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    alert,
    modal,
    company,
    tree,
    questionary,
    questions,
    orgagroups,
    reportings,
    rules,
    surveys,
    subjects,
    archive,
    poll,
    version,
  },
});
