<template>
  <v-slide-x-transition mode="out-in">
    <router-view />
  </v-slide-x-transition>
</template>

<script>
export default {
  name: "o-router-view",
  data() {
    return {
      transition: "fade",
    };
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transition = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },
};
</script>

<style></style>
