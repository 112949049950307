<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="px-1">
        <v-menu
          :close-on-content-click="false"
          transition="slide-y-transition"
          offset-y
          bottom
          right
          min-width="auto"
          rounded="xl"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              v-bind="attrs"
              v-on="on"
              label="Datum"
              readonly
              filled
              rounded
              class="rounded-l-xl rounded-r-0"
              hide-details
              :disabled="is_disabled"
            />
          </template>
          <v-date-picker
            v-model="_date"
            flat
            scrollable
            reactive
            show-adjacent-months
            :first-day-of-week="1"
            :min="minDate"
            :max="maxDate"
            :disabled="is_disabled"
          />
        </v-menu>
      </v-col>
      <v-col class="pr-1">
        <v-menu
          :close-on-content-click="false"
          transition="slide-y-transition"
          offset-y
          bottom
          right
          min-width="auto"
          rounded="xl"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="_time"
              :rules="rules"
              v-bind="attrs"
              v-on="on"
              label="Uhrzeit"
              readonly
              rounded
              filled
              class="rounded-l-0 rounded-r-xl"
              hide-details
              :disabled="is_disabled"
            />
          </template>
          <v-time-picker
            v-model="_time"
            format="24hr"
            flat
            scrollable
            :min="minTime"
            :max="maxTime"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "o-date-time-field",
  props: {
    value: {
      type: Number,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: () => new Date(Date.now()).getTime() / 1000,
    },
    max: {
      type: Number,
      default: undefined,
    },
    is_disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: new Date(Date.now()).getTime() / 1000,
      rules: [(value) => !!value || "erforderlich"],
    };
  },
  mounted() {
    if (this.value) {
      this.date = this.value;
    }
  },
  methods: {
    getDate(date) {
      return date.toISOString().split("T")[0];
    },
    getTime(date) {
      return date.toLocaleTimeString().substring(0, 5);
    },
  },
  computed: {
    _date: {
      get() {
        return this.getDate(new Date(this.date * 1000));
      },
      set(value) {
        var time = this.getTime(
          this.value ? new Date(this.value * 1000) : new Date(Date.now())
        );

        const newDate = new Date(`${value} ${time}`);
        this.date = newDate.getTime() / 1000;
        this.$emit("input", this.date);
      },
    },
    _time: {
      get() {
        return this.getTime(new Date(this.date * 1000));
      },
      set(value) {
        var date = this.getDate(
          this.value ? new Date(this.value * 1000) : new Date(Date.now())
        );

        const newDate = new Date(`${date} ${value}`);
        this.date = newDate.getTime() / 1000;
        this.$emit("input", this.date);
      },
    },
    formatDate() {
      var d = this.getDate(new Date(this.date * 1000));
      const [year, month, day] = d.split("-");
      return `${day}.${month}.${year}`;
    },
    minDate() {
      return this.min !== null && this.min !== undefined
        ? this.getDate(new Date(this.min * 1000))
        : undefined;
    },
    minTime() {
      return this.min !== null && this.min !== undefined
        ? this.getTime(new Date(this.min * 1000))
        : undefined;
    },
    maxDate() {
      return this.max !== null && this.max !== undefined
        ? this.getDate(new Date(this.max * 1000))
        : undefined;
    },
    maxTime() {
      return this.max !== null && this.max !== undefined
        ? this.getTime(new Date(this.max * 1000))
        : undefined;
    },
  },
  watch: {
    value(value) {
      this.date = value;
    },
  },
};
</script>

<style></style>
