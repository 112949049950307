<template>
  <v-select
    v-model="_value"
    :items="items"
    :disabled="is_disabled"
    item-value="questionnaire_id"
    item-text="name"
    filled
    rounded
  />
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "o-questionary-select",
  props: {
    value: {
      type: String,
    },
    is_disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    const response = await this.getAll({});
    this.items = response.items;
  },
  methods: {
    ...mapActions("questionary", ["getAll"]),
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>

<!-- <template>
  <o-loader
    getter="questionary/questionnaries"
    :fetchParam="this.user.company_id"
  >
    <template v-slot:default="{ value }">
      <v-select
        v-model="_value"
        :items="value"
        item-value="questionnaire_id"
        item-text="name"
        filled
        rounded
      />
    </template>
  </o-loader>
</template>

<script>
import { mapGetters } from "vuex";
import OLoader from "@/common/components/OLoader";

export default {
  name: "o-questionary-select",
  components: {
    OLoader,
  },
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style></style>
 -->
