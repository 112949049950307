<template>
    <v-select
    v-model="_value"
    label="Bitte wählen Sie einen Bericht"
    :items="items"
    item-value="rule_id"
    item-text="rulelocator"
    filled
    rounded
    :loading="isLoading"
    :menu-props="{
        offsetY: true,
        rounded: 'xl',
    }"
    clearable
    v-bind="{ ...$attrs, ...$props }"
    />
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "o-rule-select",
    props: {
      value: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        items: [],
      };
    },
    async mounted() {
      this.load();
    },
    methods: {
      async load() {
        const response = await this.getAllByCompanyId({});
        this.items = response.items;
    },
      ...mapActions("rules", ["getAllByCompanyId"]),
    },
    computed: {
      _value: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
      ...mapGetters("rules", ["isLoading"]),
    },
  };
  </script>
  
  <style></style>
  