<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title> Farbgruppen </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent.stop="addGroup">
          <v-icon>mdi-plus-thick</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-text-field
      class="mx-4"
      outlined
      dense
      hide-details
      placeholder="Suche..."
      prepend-inner-icon="mdi-magnify"
      v-model="search"
      clearable
    >
    </v-text-field>
    <v-container>
      <v-data-table
        :items="items"
        item-key="id"
        hide-default-footer
        :search="search"
        :headers="headers"
        :items-per-page="-1"
      >
        <!-- <template #[`item.index`]="{ index }"> {{ index + 1 }}. </template> -->
        <template #[`item.color`]="{ item }">
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-avatar size="26px" :color="item.color"></v-avatar>
              </v-btn>
            </template>
            <v-color-picker
              v-model="item.color"
              show-swatches
              hide-mode-switch
            />
          </v-menu>
        </template>
        <template #[`item.name`]="{ item }">
          <!-- <v-text-field
            flat
            background-color="transparent"
            hide-details
            solo
            dense
            v-model="item.name"
          /> -->
          <!--  <v-text-field
            flat
            hide-details
            single-line
            dense
            v-model="item.name"
          /> -->

          <v-text-field
            v-model="item.name"
            flat
            hide-details
            single-line
            dense
          />
        </template>
        <template #[`item.actions`]="{ index, item }">
          <v-btn
            icon
            color="error"
            @click.prevent.stop="deleteGroup(index)"
            v-if="item.id !== '0'"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "o-chart-color-group-edit-drawer",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    colorGroups: {
      type: Array,
      default: () => {
        const groups = [];

        for (let i = 0; i < 10; i++) {
          groups.push({
            id: `${i}`,
            name: `Kategorie ${i}`,
            color: `#4f4f4f`,
          });
        }
        return groups;
      },
    },
  },
  data() {
    return {
      search: "",
      headers: [
        /* {
          value: "index",
          text: "#",
          align: "start",
          sortable: false,
        }, */
        {
          text: "Farbe",
          value: "color",
          align: "start",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          align: "start",
        },
        {
          text: "Aktionen",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    addGroup() {
      const group = {
        id: this.generateId(),
        name: `Neue Farb Gruppe ${this.items.length}`,
        color: "#4f4f4f",
      };

      this.items.push(group);
    },
    async deleteGroup(index) {
      try {
        const permission = await this.awaitModal({
          title: "Farbgruppe Löschen?",
          message: "Wollen sie wirklich diese Farbgruppe Löschen?",
        });

        if (permission) {
          this.items.splice(index, 1);
          return Promise.resolve();
        }
      } catch (err) {
        console.log(err);
        return Promise.reject();
      }
    },
    generateId(maxLength = 12) {
      return (
        Date.now().toString(maxLength) +
        Math.floor(
          Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
        ).toString(maxLength)
      );
    },

    ...mapActions("modal", ["awaitModal"]),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    items: {
      get() {
        return this.colorGroups;
      },
      set(value) {
        this.$emit("update:colorGroups", value);
      },
    },
  },
};
</script>

<style></style>
