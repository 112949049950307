<template>
  <v-row justify="center">
    <v-dialog
      v-model="visible"
      :max-width="'30%'"
      style="z-index: 500"
      class="rounded-xl"
      persistent
    >
      <v-card rounded="xl" outlined elevation="12">
        <v-card-title>
          <div v-html="_content.title"></div>
        </v-card-title>
        <v-card-text>
          <div v-html="_content.message"></div>
        </v-card-text>
        <v-card-actions style="display: block" class="d-flex flex-column">
          <v-btn
            depressed
            block
            color="success"
            type="submit"
            @click.prevent.stop="acceptModal"
            rounded
            class="mb-1"
          >
            Bestätigen
            <v-icon left>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            block
            text
            color="error"
            @click.prevent.stop="quitModal"
            class="ml-0"
            rounded
          >
            Abbrechen
            <v-icon left>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "o-app-modal",
  data() {
    return {
      dialog: true,
      default: {
        title: "Title",
        message: "Hello World",
      },
    };
  },
  methods: {
    quitModal() {
      this.closeModal();
    },

    ...mapActions("modal", ["closeModal", "acceptModal"]),
  },
  computed: {
    ...mapGetters("modal", ["visible", "content"]),
    _content() {
      return this.content === null || this.content === undefined
        ? this.default
        : this.content;
    },
  },
};
</script>

<style></style>
