<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2"> Details </v-list-item-title>
        <v-container fluid>
          <v-text-field
            v-model="name"
            rounded
            filled
            label="Name *"
            :rules="[
              v => !!v || 'Pflichtfeld',
              v => v.length <= 22 || 'Maximal 22 Zeichen erlaubt'
            ]"
            counter="22"
          />
          <v-text-field
            v-model="applicant"
            rounded
            filled
            label="Auftraggeber"
            :rules="[v => v.length <= 22 || 'Maximal 22 Zeichen erlaubt']"
            counter="22"
          />
          <div>
            <v-textarea
              v-model="description"
              rounded
              filled
              label="Beschreibung"
              auto-grow
              counter="256"
            />
          </div>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2">Zugang</v-list-item-title>
        <v-container fluid>
          <v-radio-group row v-model="accessType">
            <v-radio
              label="closed (personalisierter Zugang)"
              value="closed"
            />
            <v-radio label="open (Hash wird erzeugt)" value="open" />
          </v-radio-group>
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2">
          Nachrichten
        </v-list-item-title>
        <v-container fluid>
          <v-textarea
            v-model="page_welcome"
            rounded
            filled
            label="Willkommen"
            auto-grow
            counter="8096"
          />
          <!--    <v-textarea
            v-model="page_feedback"
            rounded
            filled
            label="Feedback"
            auto-grow
            counter="8096"
          /> -->
          <v-textarea
            v-model="page_bye"
            rounded
            filled
            label="Verabschiedung"
            auto-grow
            counter="8096"
          />
          <v-textarea
            v-model="page_error"
            rounded
            filled
            label="Fehler Meldung"
            auto-grow
            counter="8096"
          />
        </v-container>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  <!-- <v-container>
    <p class="text-subtitle-2">Umfragename</p>
    <v-text-field v-model="name" rounded filled placeholder="Name" />
    <p class="text-subtitle-2">Auftragegeber</p>
    <v-text-field rounded filled placeholder="Name" />
    <p class="text-subtitle-2">Kurzbeschreibung der Umfrage</p>
    <v-textarea
      v-model="description"
      rounded
      filled
      placeholder="Beschreibung"
      auto-grow
      counter="256"
    />
    <p class="text-subtitle-2">Welcome-Text Startseite</p>
    <v-textarea
      v-model="page_welcome"
      rounded
      filled
      placeholder="Welcome"
      auto-grow
      counter="8096"
    />
    <p class="text-subtitle-2">Frage-Text Feedbackseite</p>
    <v-textarea
      v-model="page_feedback"
      rounded
      filled
      placeholder="Feedback"
      auto-grow
      counter="8096"
    />
    <p class="text-subtitle-2">Bye-Text Endseite</p>
    <v-textarea
      v-model="page_bye"
      rounded
      filled
      placeholder="Bye"
      auto-grow
      counter="8096"
    />
    <p class="text-subtitle-2">Error-Text Fehlerseite</p>
    <v-textarea
      v-model="page_error"
      rounded
      filled
      placeholder="Error"
      auto-grow
      counter="8096"
    />
  </v-container> -->
</template>

<script>
export default {
  name: "o-survey-form",
  props: {
    value: {
      type: Object,
      required: true,
    },
    reload: Boolean,
  },
  data() {
    return {
      rules: [(v) => !!v],
    };
  },
  watch: {
    reload() {
      if (this.reload) {
        this.getSurvey();
        this.$emit("update:reload", false);
        this.$emit("reload");
      }
    },
  },
  methods: {
    updateValue(key, value) {
      let item = { ...this.value };
      item[key] = value;
      this.$emit("input", item);
    },
  },
  computed: {
    name: {
      get() {
        return this.value.name;
      },
      set(value) {
        this.updateValue("name", value);
      },
    },
    applicant: {
      get() {
        return this.value.applicant;
      },
      set(value) {
        this.updateValue("applicant", value);
      },
    },
    questionnaire_id: {
      get() {
        return this.value.questionnaire_id;
      },
      set(value) {
        this.updateValue("questionnaire_id", value);
      },
    },
    questionnaires: {
      get() {
        return this.value.questionnaires;
      },
      set(value) {
        this.updateValue("questionnaires", value);
      },
    },
    description: {
      get() {
        return this.value.description;
      },
      set(value) {
        this.updateValue("description", value);
      },
    },
    accessType: {
      get() {
        return this.value.accesstype;
      },
      set(value) {
        this.updateValue("accesstype", value);
      },
    },
    page_welcome: {
      get() {
        return this.value.page_welcome;
      },
      set(value) {
        this.updateValue("page_welcome", value);
      },
    },
    page_bye: {
      get() {
        return this.value.page_bye;
      },
      set(value) {
        this.updateValue("page_bye", value);
      },
    },
    page_error: {
      get() {
        return this.value.page_error;
      },
      set(value) {
        this.updateValue("page_error", value);
      },
    },
    page_feedback: {
      get() {
        return this.value.page_feedback;
      },
      set(value) {
        this.updateValue("page_feedback", value);
      },
    },
    startdate: {
      get() {
        return this.value.startdate;
      },
      set(value) {
        this.updateValue("startdate", value);
      },
    },
    enddate: {
      get() {
        return this.value.enddate;
      },
      set(value) {
        this.updateValue("enddate", value);
      },
    },
  },
};
</script>

<style></style>
