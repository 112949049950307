<template>
  <v-row align="center" justify="center" align-content="center" no-gutters>
    <v-col cols="auto">
      <v-card flat max-width="700px" min-width="350px">
        <v-card-title>
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                src="@/assets/vlead-logo.png"
                max-height="55"
                max-width="55"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h4">
                Willkommen zu OrgaCheck
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-1">
                Bitte melden Sie sich an.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          <v-window :value="window">
            <v-window-item>
              <o-login-form @passwordForgotten="window = 1" />
            </v-window-item>
            <v-window-item>
              <o-password-forgotten-form @close="window = 0" />
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import OLoginForm from "./OLoginForm.vue";
import OPasswordForgottenForm from "./OPasswordForgottenForm.vue";
export default {
  components: { OLoginForm, OPasswordForgottenForm },
  name: "o-login",
  data() {
    return {
      window: 0,
    };
  },
};
</script>

<style></style>
