<template>
  <v-app>
    <!-- <o-app-modal /> -->
    <o-alert-snackbar />
    <o-process-snackbar />
    <o-alert-drawer />
    <o-router-view />
  </v-app>
</template>

<script>
import ORouterView from "./common/components/ORouterView.vue";
import {
  OAlertSnackbar,
  OProcessSnackbar,
  OAlertDrawer,
} from "./modules/system/alert";

// import { OAppModal } from "./modules/system/modal";
// Um Gotteswillen OAppModal NICHT mehr global einbinden, denn wenn mehrere Komponenten überlagern,
// dann kann man die Kind-Komponenten mit der obersten Komponente abschießen/schließen durch einfaches Klicken!

export default {
  components: {
    OAlertSnackbar,
    OAlertDrawer,
    ORouterView,
    // OAppModal,
    OProcessSnackbar,
  },
  name: "App",
};
</script>

<style>
.fill-height {
  height: 100%;
}
.pdfview {
  height: calc(100% - 70px);
}
</style>
