<template>
  <v-tooltip
    :bottom="bottom"
    :top="top"
    :left="left"
    :right="right"
    :open-delay="openDelay"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-on="{ ...on, ...$listeners }"
        v-bind="{ ...attrs, ...$attrs }"
      >
        <slot name="icon">
          <v-icon>
            {{ icon }}
          </v-icon>
        </slot>
      </v-btn>
    </template>
    <slot> Tooltip </slot>
  </v-tooltip>
</template>

<script>
export default {
  name: "o-tooltip-icon-btn",
  props: {
    openDelay: {
      type: Number,
      default: 500,
    },
    icon: {
      type: String,
    },
    bottom: {
      type: Boolean,
    },
    top: {
      type: Boolean,
    },
    left: {
      type: Boolean,
    },
    right: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
