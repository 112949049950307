import {
  OLoginView,
  OHomeView,
  ODashboardView,
  OAccountSettingsView,
  OTreeView,
  OShowTreeView,
  OQuestionaryView,
  OReportingsView,
  OSurveysView,
  OPollView,
  OSettingsView,
  OAboutView,
  OThemeSettingsView,
  OCompanyView,
  OArchiveView,
} from "@/views";

export const routes = [
  {
    path: "/",
    name: "home",
    redirect: "dashboard",
    component: OHomeView,
    children: [
      {
        path: "/about",
        name: "about",
        component: OAboutView,
        meta: {
          permissions: [
            {
              role: "admin",
              access: true,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: ODashboardView,
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "companies",
        name: "companies",
        component: OCompanyView,
        meta: {
          permissions: [
            {
              role: "user",
              access: false,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "orgatree",
        name: "orgatree",
        component: OTreeView,
        meta: {
          permissions: [
            {
              role: "admin",
              access: false,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "showtree",
        name: "showtree",
        component: OShowTreeView,
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "/settings",
        name: "settings",
        redirect: "/settings/account",
        component: OSettingsView,
        meta: {
          permissions: [
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
        children: [
          {
            path: "account",
            name: "settings-account",
            component: OAccountSettingsView,
            meta: {
              permissions: [
                {
                  role: "guest",
                  access: false,
                  redirect: "login",
                },
              ],
            },
          },
          /* {
            path: "company",
            name: "settings-company",
            component: OCompanySettingsView,
            meta: {
              permissions: [
                {
                  role: "admin",
                  access: false,
                  redirect: "home",
                },
                {
                  role: "guest",
                  access: false,
                  redirect: "login",
                },
              ],
            },
          }, */
          {
            path: "application",
            name: "settings-application",
            component: OThemeSettingsView,
            meta: {
              permissions: [
                {
                  role: "guest",
                  access: false,
                  redirect: "login",
                },
              ],
            },
          },
        ],
      },

      {
        path: "/questionary",
        name: "questionary",
        component: OQuestionaryView,
        meta: {
          permissions: [
            {
              role: "admin",
              access: false,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "/surveys",
        name: "surveys",
        component: OSurveysView,
        meta: {
          permissions: [
            {
              role: "admin",
              access: false,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "/reportings",
        name: "reportings",
        component: OReportingsView,
        meta: {
          permissions: [
            {
              role: "admin",
              access: false,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
      {
        path: "/archive",
        name: "archive",
        component: OArchiveView,
        meta: {
          permissions: [
            {
              role: "admin",
              access: false,
              redirect: "home",
            },
            {
              role: "guest",
              access: false,
              redirect: "login",
            },
          ],
        },
      },
    ],
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "login",
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    component: OLoginView,
    meta: {
      permissions: [
        {
          role: "admin",
          access: false,
          redirect: "home",
        },
        {
          role: "user",
          access: false,
          redirect: "home",
        },
      ],
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: OLoginView,
    meta: {
      permissions: [
        {
          role: "guest",
          access: false,
          redirect: "login",
        },
      ],
    },
  },
  {
    path: "/poll/:id",
    name: "poll",
    component: OPollView,
    props: true,
  },
  /* {
    path: "/poll",
    name: "poll",
    component: OPollView,
    props: (route) => ({ query: route.query }),
    children: [
      {
        path: "questionnaire",
        component: OPollQuestionnaireView,
      },
    ],
    meta: {
      permissions: [
        {
          role: "admin",
          access: false,
          redirect: "home",
        },
        {
          role: "user",
          access: false,
          redirect: "home",
        },
      ],
    },
  }, */
  /*  { path: "*", redirect: "/" }, */
];
