<template>
  <!-- <div>
    <v-toolbar flat class="my-16" color="transparent">
      <v-toolbar-title style="height: 100%">
        <o-welcome-message />
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon x-large to="/settings">
        <v-icon x-large>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid  class="pl-6">
      Um <router-link to="/companies">Organisationen</router-link> zu verwalten
      wechseln Sie bitte zur den entsprechenden Oberfläche.
    </v-container>
     <o-company-table />
  </div> -->
  <v-card flat>
    <v-card-title>
      <v-toolbar-title style="height: 100%">
        <o-welcome-message />
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon x-large to="/settings">
        <v-icon x-large>mdi-cog</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="text-body-1">
      Um <router-link to="/companies">Organisationen</router-link> zu verwalten
      wechseln Sie bitte zur den entsprechenden Oberfläche.
    </v-card-text>
  </v-card>
</template>

<script>
import OWelcomeMessage from "./OWelcomeMessage.vue";
//import { OCompanyTable } from "@/modules/company/editor";

export default {
  name: "o-admin-dashboard",
  components: {
    OWelcomeMessage,
    //OCompanyTable,
  },
};
</script>

<style></style>
