<template>
  <div>
    <p class="text-subtitle-2">Adresse</p>
    <v-text-field
      v-model="_address"
      :rules="rules.address"
      label="Straße & Hausnummer"
      filled
      rounded
      placeholder="Mustermannstraße, 2"
    />
    <v-row no-gutters align="center">
      <v-col>
        <v-text-field
          v-model="_zip"
          :rules="rules.zip"
          label="Postleitzahl"
          filled
          rounded
          class="rounded-l-xl rounded-r-0"
          placeholder="66666"
        />
      </v-col>
      <v-col cols="9" class="pl-1">
        <v-text-field
          v-model="_city"
          :rules="rules.city"
          label="Stadt"
          placeholder="Musterstadt"
          filled
          rounded
          class="rounded-r-xl rounded-l-0"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "o-address-field",
  props: {
    address: {
      type: String,
      default: "",
    },
    zip: {
      type: String,
      default: "",
    },
    city: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rules: {
        address: [],
        zip: [],
        city: [],
      },
    };
  },
  computed: {
    _address: {
      get() {
        return this.address;
      },
      set(value) {
        this.$emit("update:address", value);
        this.$emit("change");
      },
    },
    _zip: {
      get() {
        return this.zip;
      },
      set(value) {
        this.$emit("update:zip", value);
        this.$emit("change");
      },
    },
    _city: {
      get() {
        return this.city;
      },
      set(value) {
        this.$emit("update:city", value);
        this.$emit("change");
      },
    },
  },
};
</script>

<style></style>
