<template>
  <v-form v-model="valid" @submit.prevent.stop="submit">
    <v-card flat height="350px">
      <v-card-text>
        <o-email-field v-model="username" />
        <o-password-field v-model="password" />
        <v-row no-gutters justify="end">
          <v-col cols="auto">
            <v-btn
              text
              small
              rounded
              @click.prevent="$emit('passwordForgotten')"
              class="justify-end"
            >
              passwort vergessen?
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-4">
        <v-btn
          block
          :disabled="!valid"
          x-large
          rounded
          depressed
          color="primary"
          type="submit"
        >
          Anmelden
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import OEmailField from "@/common/components/OEmailField.vue";
import OPasswordField from "@/common/components/OPasswordField.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "o-login-form",
  components: { OEmailField, OPasswordField },
  data() {
    return {
      valid: false,
      username: "",
      password: "",
    };
  },
  computed: {
    ...mapState("account", ["status"]),
  },
  methods: {
    ...mapActions("account", ["login"]),
    submit() {
      const { username, password } = this;
      if (username && password) {
        this.login({ username, password });
      }
    },
  },
};
</script>

<style></style>
