<template>
  <o-company-table />
</template>

<script>
import { OCompanyTable } from "@/modules/company/editor";

export default {
  name: "o-company-view",
  components: {
    OCompanyTable,
  },
};
</script>

<style></style>
