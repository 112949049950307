<template>
  <!--
    <o-data-table-card title="Laufende Umfragen">
      <template v-slot:default="{ search }">
        <o-survey-ongoing-table :search="search" />
      </template>
    </o-data-table-card>
    -->
  <o-survey-table />
</template>

<script>
import { OSurveyTable, OSurveyOngoingTable } from "@/modules/company/surveys";
import ODataTableCard from "@/common/components/ODataTableCard.vue";

export default {
  name: "o-surveys-view",
  // components: { OSurveyTable, OSurveyOngoingTable, ODataTableCard },
  components: { OSurveyTable },
};
</script>

<style></style>
