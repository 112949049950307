<template>
  <v-row align="center" align-content="center" justify="center">
    <v-col cols="auto">
      {{ prependLabel }}
    </v-col>
    <v-col>
      <v-slider
        v-model="_value"
        :thumb-size="32"
        :step="5"
        ticks="always"
        thumb-label="always"
        class="px-2"
        :rules="rules"
        hide-details
      >
        <template v-slot:thumb-label="{ value }">
          {{ `${value}%` }}
        </template>
      </v-slider>
    </v-col>
    <v-col cols="auto">
      {{ appendLabel }}
    </v-col>
  </v-row>
  <!-- <v-slider
      v-model="_value"
      :thumb-size="32"
      :step="5"
      ticks="always"
      thumb-label="always"
      class="px-2"
      :label="prependLabel"
      :rules="rules"
    >
      <template v-slot:append>{{ appendLabel }}</template>
      <template v-slot:thumb-label="{ value }">
        {{ `${value}%` }}
      </template>
    </v-slider> -->
</template>

<script>
export default {
  name: "o-poll-scalar-slider",
  props: {
    value: {
      type: Number,
    },
    prependLabel: {
      type: String,
      default: "",
    },
    appendLabel: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      rules: [(v) => v !== undefined && v !== null],
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
