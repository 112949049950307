<template>
    <o-archive />
  </template>
  
  <script>
  import { OArchive } from "@/modules/company/archive";
  export default {
    components: { OArchive },
    name: "o-archive-view",
  };
  </script>
  
  <style></style>