
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from 'vuetify/lib/locale/de'

Vue.use(Vuetify);

const store = localStorage.getItem("darkmode");
const isDark = store ? JSON.parse(store) : window.matchMedia("(prefers-color-scheme: dark)").matches;

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  options: {
    customProperties: true,
  },
  theme: {
    //dark:  window.matchMedia("(prefers-color-scheme: dark)").matches,
    dark: isDark,
    themes: {
      light: {
        primary: {
          base: "#137cba",
          lighten1: "#b0cde0",
        },
        secondary: "#b0cde0",
        anchor: '#8c9eff',
      },
      dark: {
        primary: {
          base: "#137cba",
          lighten1: "#b0cde0",
        },
        secondary: "#b0cde0",
        anchor: '#8c9eff',
      },
    },
  },
});
