<template>
  <v-card flat>
    <v-card-title>
      <v-toolbar-title style="height: 100%">
        <o-welcome-message />
      </v-toolbar-title>
      <v-spacer />
      <!-- 
      <v-btn icon x-large to="/settings">
        <v-icon x-large>mdi-cog</v-icon>
      </v-btn>
      -->
      <!--
      <v-avatar
        color="#008ffb"
        size="100"
      >
        <span v-if="this.interviewCount" class="white--text text-h5">{{ this.interviewCount }}</span>
      </v-avatar>
     -->
    
     <v-tooltip bottom :open-delay="500">
        <template v-slot:activator="{ attrs, on }">
          <v-avatar
            color="#008ffb"
            size="100"
            v-bind="attrs"
            v-on="on"
          >
            <span v-if="interviewCount && company" class="white--text text-h5">{{ interviewCount }}</span>
          </v-avatar>
        </template>
        <span v-if="company">Gesamtanzahl aller Interviews von {{ company.name }}</span>
      </v-tooltip>
    
    </v-card-title>
    <v-card-text class="pt-12">
      <o-survey-ongoing-table />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { OSurveyOngoingTable } from "@/modules/company/surveys";
//import OAwait from "@/common/components/OAwait.vue";
import OWelcomeMessage from "./OWelcomeMessage.vue";

export default {
  name: "o-user-dashboard",
  components: {
    //OAwait,
    OSurveyOngoingTable,
    OWelcomeMessage,
    //ODataTableCard,
  },
  data() {
    return {
      company: null,
      interviewCount: null,
    };
  },
  async mounted() {
    this.company = await this.getCompany(this.user.company_id);
    this.interviewCount = this.company.n_interviews.toString();
    // console.log("name: ", this.company.name);
  },
  methods: {
    ...mapActions("company", { getCompany: "get",
    }),
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  padding: 24px;
}
</style>
