import { apiService } from "./api.service";

export const surveysService = {
  getAllBySurveyId: (data) => apiService.GET(`/surveys/${data.survey_id}`, data),
  getAll: ({ company_id, options }) => apiService.POST(`/companies/surveys/${company_id}`, options),
  getAllArchive: ({ company_id, options }) => apiService.POST(`/companies/surveys/archive/${company_id}`, options),
  delete: (data) => apiService.DELETE(`/surveys/${data.survey_id}`),
  reset: (data) => apiService.GET(`/surveys/reset/${data.survey_id}`),
  archive: (data) => apiService.GET(`/surveys/archive/${data.survey_id}`),
  generateSurvey: (data) => apiService.GET(`/surveys/generate/${data.survey_id}`),
  exportSurvey: (survey_id) => apiService.GET(`/surveys/export/data/${survey_id}`),
  exportSubjects: (survey_id) => apiService.GET(`/surveys/export/subjects/${survey_id}`),
  create: (data) => apiService.POST(`/surveys`, data),
  update: (data) => apiService.PUT(`/surveys/${data.survey_id}`, data),
  generateSubjectKeys: (survey_id, count) => apiService.POST(`/subjects`, { survey_id, count }),
  deleteUnusedSubjects: (data) => apiService.DELETE(`/subjects/unused/${data.survey_id}`),
  getSurveySeries: (survey_id, days = 7) => apiService.GET(`/surveys/series/${survey_id}/${days}`),
  getOngoingSurveys: (company_id) => apiService.GET(`/companies/currentsurveys/${company_id}`),
  getSurveyCount: (survey_id) => apiService.GET(`/welcome/surveys/${survey_id}`),
};
