<template>
  <v-data-table
    :headers="headers"
    :items="dataset"
    :loading="loading"
    :search="search"
  >
    <template #top>
      <o-data-table-toolbar title="Laufende Umfragen" :search.sync="search">
        <v-btn text class="text-capitalize" color="primary" to="/surveys">
          Alle anzeigen
        </v-btn>
      </o-data-table-toolbar>
    </template>
    <template #no-data>
      <b>Keine laufenden Umfragen vorhanden...</b>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <h3>{{ item.name }}</h3>
      <span>{{ item.applicant }}</span>
    </template>
    <template #[`item.accesstype`]="{ item }">
      <o-status-chip
        class="ml-2"
        :value="item.accesstype"
        :options="accesstypeOptions"
        dark
      />
    </template>
    <template v-slot:[`item.enddate`]="{ item }">
      <o-date-time :value="item.enddate_ux * 1000" />
    </template>
    <template v-slot:[`item.survey_duration`]="{ item }">
      <o-countdown :value="item.enddate_ux * 1000" />
    </template>
    <template v-slot:[`item.interviews`]="{ item }">
      <o-share-indicator
        :values="[
          {
            name: 'bereit',
            color: '#FEB019',
            value: item.n_base - item.n_started - item.n_completed,
          },
          {
            name: 'begonnen',
            color: '#00E396',
            value: item.n_started,
          },
          {
            name: 'fertiggestellt',
            color: '#008FFB',
            value: item.n_completed,
          },
        ]"
      />
    </template>
    <template v-slot:[`item.activity`]="{ item }">
      <o-survey-activity-chart :value="item.survey_id" :height="200" :width="400" />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OCountdown from "@/common/components/OCountdown.vue";
import ODateTime from "@/common/components/ODateTime.vue";
import OShareIndicator from "@/common/components/OShareIndicator.vue";
import OSurveyActivityChart from "./OSurveyActivityChart.vue";
import OStatusChip from "@/common/components/OStatusChip.vue";
import ODataTableToolbar from "@/common/components/ODataTableToolbar.vue";

export default {
  name: "o-survey-ongoing-table",
  components: {
    //OTable,
    OCountdown,
    ODateTime,
    OShareIndicator,
    OSurveyActivityChart,
    OStatusChip,
    ODataTableToolbar,
  },
  props: {},
  data() {
    return {
      loading: false,
      dataset: [],
      search: "",
      headers: [
        {
          text: "Name",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Zugang",
          value: "accesstype",
          sortable: false,
        },
        {
          text: "Ende",
          value: "enddate",
          sortable: false,
        },
        {
          text: "Dauer",
          value: "survey_duration",
          sortable: false,
        },
        {
          text: "Interviews",
          value: "interviews",
          sortable: false,
        },
        {
          text: "Interviews innerhalb der letzten 7 Tage",
          value: "activity",
          sortable: false,
        },
      ],
      accesstypeOptions: {
        closed: {
          name: "closed",
          color: "#F86624",
          icon: "mdi-lock",
          tooltip: "Teilnahme nur mit Schlüssel möglich",
        },
        open: {
          name: "open",
          color: "#43BCCD",
          icon: "mdi-lock-open-variant",
          tooltip: "Teilnahme ohne Schlüssel möglich",
        },
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const data = await this.getOngoingSurveys(this.user.company_id);
      this.dataset = data;
      this.loading = false;
    },
    ...mapActions("surveys", ["getOngoingSurveys"]),
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style></style>
