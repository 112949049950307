<template>
    <v-card-text>
        Der OrgaCheck ist ein Tool zur Analyse der digitalen Zusammenarbeit.
        Die Ergebnisse helfen dabei, Entwicklungspotentiale bei
        persönlichen, technischen und organisationalen Faktoren aufzudecken,
        z.B. bei der Selbstorganisation, Kommunikationskompetenz und
        technischer Ausstattung.
        <br/><br/>
        <div><b>Versionen</b></div>
        <div>Client: Ver. {{ clientVersionText }}</div>
        <div>Server: Ver. {{ serverversion }}</div>
    </v-card-text>
  </template>
  
<script>
import { mapActions } from "vuex";

export default {
  name: "o-version",
  data() {
    return {
      loading: false,
      serverversion: "k.A.",
    };
  },
  async mounted() {
      const response = await this.getVersion({});
      this.serverversion = response.version;
  },
  methods: {
    ...mapActions("version", ["getVersion"]),
  },
  computed: {
    clientVersionText() {
      return `${process.env.VUE_APP_VERSION}`;
    },
    serverVersionText() {
      return this.serverversion;
    },
  },
};
</script>

<style scoped>
/* test */
</style>
