<template>
  <o-menu-dropdown :items="items" maxWidth="400px">
    <template v-slot:title>
      <v-icon color="primary">mdi-account-circle</v-icon>
    </template>
    <template v-slot:prepend v-if="user">
      <v-list-item>
        <v-list-item-avatar>
          <v-avatar size="36" color="primary" class="white--text font-weight-bold">{{ user.email[0].toUpperCase() }}</v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ user.email }} 
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.company_name }} / {{ user.user_role }}</v-list-item-subtitle>
          <v-list-item-subtitle>Session: {{ user.session_id }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>
  </o-menu-dropdown>
</template>

<script>
import OMenuDropdown from "@/common/components/OMenuDropdown.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "o-account-menu",
  components: { OMenuDropdown },
  data() {
    return {
      items: [
        /*
        {
          title: "Mein Konto",
          icon: "mdi-account-edit",
          click: () => this.$router.push("/settings/account"),
        },
        */
        {
          title: "Abmelden",
          icon: "mdi-logout",
          click: () => this.logout(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("account", ["logout"]),
  },
};
</script>

<style></style>
