<template>
  <v-navigation-drawer :value="show" absolute right width="750px" outlined>
    <v-container class="px-12 py-6" v-if="show">
      <v-form v-model="valid">
        <o-company-form v-model="item" />
        <v-divider class="mb-4" />
        <v-list-item>
          <v-container fluid>
            <p class="text-subtitle-2">Konto Emailadresse</p>
            <o-email-field v-model="item.email" disable-icon label="Email" />
          </v-container>
        </v-list-item>
        <v-list-item>
          <v-container fluid>
            <p class="text-subtitle-2">Organisationsgruppe</p>
            <o-orgagroup-select v-model="item.orgagroup_id" />
          </v-container>
        </v-list-item>
        <v-list-item>
          <v-container fluid>
            <p class="text-subtitle-2">OrgaCheck Status</p>
            <v-select
              filled
              rounded
              v-model="item.status"
              :items="types.status"
              item-text="text"
              item-value="value"
            />
          </v-container>
        </v-list-item>
      </v-form>
      <small class="text--secondary">Pflichtfeld *</small>
    </v-container>
    <template v-slot:prepend>
      <v-toolbar flat dense v-if="item">
        <v-icon size="32"> mdi-office-building </v-icon>
        <v-toolbar-title class="pl-2">
          <v-list-item-content>
            <v-list-item-title>Organisation bearbeiten </v-list-item-title>
            <v-list-item-subtitle class="text--secondary">
              {{ item.company_id }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-toolbar-title>
        <!--  <v-app-bar-title v-if="item">
          Organisation bearbeiten (ID {{ item.company_id }})
        </v-app-bar-title> -->
        <v-spacer />
      </v-toolbar>
      <v-divider />
    </template>

    <template v-slot:append>
      <v-divider />
      <v-toolbar flat>
        <v-btn text color="error" @click.prevent.stop="cancel">
          <v-icon left>mdi-cancel</v-icon>
          Abbruch
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          :disabled="!valid"
          @click.prevent.stop="save"
        >
          <v-icon left>mdi-checkbox-marked-circle</v-icon>
          Speichern
        </v-btn>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
import OEmailField from "@/common/components/OEmailField.vue";
import OCompanyForm from "./OCompanyForm.vue";
import { OOrgagroupSelect } from "@/modules/company/orgagroups";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "o-company-edit-drawer",
  components: {
    OCompanyForm,
    OEmailField,
    OOrgagroupSelect,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      valid: false,
      item: null,
      types: {
        status: [
          { value: "active", text: "Aktiv" },
          { value: "inactive", text: "Inaktiv" },
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.$emit("input", null);
    },
    save() {
      if (this.item === null || this.item === undefined) {
        return;
      }

      const handler =
        this.item.company_id > 0 ? this.updateCompany : this.createCompany;
      handler(this.item);

      this.$nextTick(() => {
        this.cancel();
      });
    },
    ...mapGetters("orgagroups", ["getAll"]),
    ...mapActions("company", {
      updateCompany: "update",
      createCompany: "create",
    }),
  },
  computed: {
    show() {
      return this.item !== null && this.item !== undefined;
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    value(value) {
      this.item = value;
      if (!this.item) return;

      if (!this.item.company_id) {
        this.item.company_id = this.user.company_id;
      }
    },
    item() {
      if (!this.item) return;
      if (this.item.orgagroup_id === undefined) {
        this.valid = false;
      }

      // console.log("item changed: ", this.item, this.valid);
    },
  },
};
</script>

<style></style>
