<template>
  <div class="py-8">
    <!-- <v-navigation-drawer app right clipped floating width="450" /> -->
    <o-center column>
      <v-card flat class="py-12 px-6" rounded="xl" max-width="1180px">
        <v-row class="pb-12 px-12">
          <v-col cols="auto">
            <v-avatar color="primary" class="white--text text-h4">
              {{ value.survey_company_name[0].toUpperCase() }}
            </v-avatar>
          </v-col>
          <v-col cols="auto">
            <h1>{{ value.survey_company_name }}</h1>
          </v-col>
        </v-row>
        <v-card-title class="text-h5" style="white-space: per">
          <span>
            <b>{{ value.survey_company_applicant }}</b> lädt sie zur Umfrage
            <b>{{ value.poll_titel }}</b> ein!
          </span>
        </v-card-title>
        <v-card-text>
          <div class="text-subtitle-1" v-html="value.response_text" />
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="56" color="#e13568">
                  mdi-text-box-check-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div>
                  Die Umfrage beinhaltet
                  <strong>{{ totalQuestionCount }}</strong> Fragen
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="56" color="#e96f1e"> mdi-clock-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div>
                  Die Beantwortung wird ungefähr
                  <strong>{{ totalDuration }}</strong> Minuten Dauern
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon size="56" color="#c2d017">
                  mdi-content-save-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div>
                  Fortschritt wird <strong>gespeichert</strong>. Eine
                  Wiederaufnahme der Umfrage ist möglich.
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </o-center>
    <v-footer app outlined>
      <v-container>
        <v-row>
          <v-col></v-col>
          <v-col cols="auto">
            <v-btn
              rounded
              x-large
              depressed
              color="primary"
              right
              @click="$emit('next')"
            >
              Umfrage Starten
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import OCenter from "@/common/components/OCenter.vue";

export default {
  name: "o-poll-welcome",
  components: {
    OCenter,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      selected: [],
      valid: false,
      secondsPerQuestion: 15,
    };
  },
  computed: {
    totalQuestionCount() {
      return this.value.questionnarie.subcategories.reduce((v, e) => {
        return v + e.questioncount;
      }, 0);
    },
    totalDuration() {
      var spq =
        this.value.survey_answer_average_duration ?? this.secondsPerQuestion;
      return ((this.totalQuestionCount * spq) / 60).toFixed(0);
    },
  },
};
</script>

<style scoped>
.gradient {
  background-image: linear-gradient(
    to bottom right,
    #e13568,
    #e96f1e,
    #c2d017,
    #47852c
  );
}
</style>
