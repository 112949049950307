<template>
  <o-loader
    getter="orgagroups/orgagroups"
  >
    <template v-slot:default="{ value }">
      <v-select
        v-model="_value"
        :items="value"
        item-value="orgagroup_id"
        item-text="name"
        filled
        rounded
      />
    </template>
  </o-loader>
</template>

<script>
import OLoader from "@/common/components/OLoader";

export default {
  name: "o-orgagroup-select",
  components: {
    OLoader,
  },
  props: {
    value: {
      type: Number,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
  