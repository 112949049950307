import { archiveService } from "@/services";

const state = {
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  options: {}
};

const actions = {
  async getAllArchive({ commit, dispatch, rootGetters, getters }, options) {
    try {
      // console.log("Archive getAll action");
      commit("loading");
      const company_id = rootGetters["account/companyId"];
      if (company_id === -1) throw new Error("CompanyId could not be found!");
      const opt = options ?? getters.options;
      const response = await archiveService.getAllArchive({ company_id, options: opt });

      // console.log("Archive response: ", response);

      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });

      return response;
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async update({ dispatch }, data) {
    try {
      const response = await archiveService.update(data);
      dispatch("getAllArchive");
      dispatch(
        "alert/showAlertSuccess",
        `Umfrage ${data.name} wurde erfolgreich aktualisiert!`,
        { root: true }
      );

      return response;
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async unarchive({ dispatch }, data) {
    try {
      await archiveService.unarchive(data);
      dispatch("getAllArchive");
      dispatch(
        "alert/showAlertSuccess",
        `Umfrage ${data.name} wurde erfolgreich reaktiviert!`,
        {
          root: true,
        }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  
  async delete({ dispatch }, data) {
    try {
      await archiveService.delete(data);
      dispatch("getAllArchive");
      // Message wird bereits in der Komponente angezeigt!
      // dispatch("alert/showAlertSuccess", "Umfrage wurde gelöscht!", {
      //   root: true,
      // });
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async getAllSubjects(_, survey_id) {
    try {
      const { subjects } = await archiveService.getAllSubjects(survey_id);
      return Promise.resolve(subjects);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getSurveySeries(_, survey_id, days) {
    try {
      const data = await archiveService.getSurveySeries(survey_id, days);
      return Promise.resolve(data.series);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getOngoingSurveys(_, company_id) {
    try {
      const data = await archiveService.getOngoingSurveys(company_id);
      // console.log('Ongoing Surveys: ', data, data.surveys);
      return Promise.resolve(data.surveys);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getSurveyCount({ rootGetters }) {
    try {
      const company_id = rootGetters["account/companyId"];
      const count = await archiveService.getSurveyCount(company_id);
      return Promise.resolve(parseInt(count.n_surveys));
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateOptions({ commit, dispatch }, value) {
    commit("setOptions", value);
    dispatch("getAllArchive");
  }
};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, error) {
    state.isLoading = false;
    state.error = error;
  },
  setOptions(state, options) {
    state.options = options;
  }
}

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  options: (state) => state.options,
};

export const archive = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
