<template>
  <v-dialog :value="show" max-width="1028px" persistent>
    <v-card rounded="xl">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-sheet
          style="overflow: auto; max-height: 512px"
          outlined
          class="pa-4"
          rounded="xl"
        >
          <slot>
            <h3 class="pb-4">
              Datenschutzhinweise und Einwilligung zur Erhebung und Verarbeitung
              personenbezogener Daten im Forschungsprojekt VLead orgacheck
            </h3>
            <ol type="A">
              <li>
                <h3>
                  Gegenstand des Forschungsprojekts und Grundlage der
                  Einwilligungserklärung
                </h3>
                <v-container class="px-0">
                  <ol type="1">
                    <li>
                      <h4>Beschreibung des Forschungsprojekts:</h4>
                      <p class="pt-2 text--secondary">
                        Orgacheck ist eine Applikation, um Befragungen in
                        Organisationen durchzuführen und den gesamten
                        Befragungsprozess von Auswertungszielen bis zur
                        Ergebnisdarstellung zu steuern. Im ersten Schritt kann
                        ein Organisationsbaum angelegt werden, der als
                        Auswertungsgrundlage für eine Befragung mittels
                        Fragebogen dient. Im nächsten Schritt können
                        standardisierte, teils festgelegte Fragebögen ausgewählt
                        werden, um z.B., die digitale Führungssituation einer
                        Organisation zu analysieren. Diese Fragebögen können
                        dann als offene oder geschlossene Umfrage übertragen und
                        durchgeführt werden. Eine Auswertung der finalen
                        Ergebnisse der Umfrage als Bericht ist im letzten
                        Schritt automatisiert ab einer Auswertungsgröße von 5
                        Personen möglich.
                      </p>
                    </li>
                    <li>
                      <h4>Verantwortliche Stelle:</h4>
                      <p class="pt-2 text--secondary">
                        Universität Trier<br />
                        Fachbereich 1<br />
                        Arbeits-, Betriebs-, und Organisationspsychologie<br />
                        Universitätsring 15<br />
                        54296 Trier
                      </p>
                    </li>
                    <li>
                      <h4>Projektleitung:</h4>
                      <p class="pt-2 text--secondary">
                        Prof. Dr. Conny H. Antoni (antoni@uni-trier.de)
                      </p>
                    </li>
                    <li>
                      <h4>Ansprechpartner:</h4>
                      <p class="pt-2 text--secondary">
                        Inhaltlich: Dr. Benedikt Graf (graf@uni-trier.de)<br />
                        Technisch: Dr. Alexander Lürken-Uhl
                        (alexander.luerken@uni-trier.de)
                      </p>
                    </li>
                    <li>
                      <h4>
                        Art der personenbezogenen Daten, die erhoben und
                        verarbeitet werden:
                      </h4>
                      <p class="pt-2 text--secondary">
                        Persönliche Angaben: [Name, Anschrift, Telefonnummer,
                        E-Mail]<br />
                        <br />
                        Besondere Kategorien personenbezogener Daten, nämlich
                        Angaben über: Orgacheck erfordert explizit keine
                        inhaltliche Datenerhebung nach Art. 9 DSGVO. Jedoch kann
                        der Nutzer innerhalb eines Auswertungsbaumes freie
                        Felder beschreiben, weshalb ein Ausschluss der Erhebung
                        von Daten nach Art. 9 DSVGO gemäß dem Verhalten des
                        Nutzenden nicht gewährleistet werden kann. Eine
                        Eintragung von Daten nach Art. 9 DSVGO durch den
                        Nutzenden hat eine Verarbeitung durch orgacheck zur
                        Folge.<br />
                        <br />
                        Aufnahmen: Videoaufzeichnungen, Fotos, Tonaufnahmen
                      </p>
                    </li>
                  </ol>
                </v-container>
              </li>
              <li>
                <h3>
                  Gegenstand des Forschungsprojekts und Grundlage der
                  Einwilligungserklärung
                </h3>
                <v-container class="px-0">
                  <ol type="1">
                    <li>
                      <h4>
                        Art der Verarbeitung der personenbezogenen Daten / Zweck
                        der Verarbeitung
                      </h4>
                      <p class="pt-2 text--secondary">
                        Welche Daten werden erhoben?<br />
                        Login-Daten wie Firmenname, E-Mail-Adresse werden
                        erhoben, um einen Zugriff auf Orgacheck zu ermöglichen.
                        Firmenbezogene Daten wie Organisationsnamen (vgl.
                        Matrix-Organisationen), Abteilungsnamen (vgl. Sales
                        Europa), Teamnamen sowie individuelle Vor- und Nachnamen
                        (Team Max Mustermann), Kostenstellen, etc., werden
                        genutzt, um einen Auswertungsbaum anzulegen. Eine
                        anonymisierte Eintragung ist abhängig des Nutzers und
                        wird nicht von orgacheck vorausgesetzt. <br />
                        <br />
                        Was passiert mit den Daten?<br />
                        Die Daten aus dem Auswertungsbaum werden genutzt, um
                        eine spezifische Zuteilung der Umfrage-Teilnehmenden zu
                        den Auswertungsgruppen vorzunehmen. <br />
                        Die erhobenen Umfragedaten der Umfrageteilnehmenden
                        werden automatisiert ausgewertet. Eine Auswertung der
                        Umfragedaten in Auswertungsgruppen unter 5 ausgefüllten
                        Umfragedaten findet nicht statt.<br />
                        <br />
                        Werden die Daten anonymisiert oder pseudonymisiert?<br />
                        Die Umfragedaten sind in offenen Umfragen vollständig
                        anonymisiert. In geschlossenen Umfragen sind die
                        Umfragedaten pseudonymisiert, da dort ein Code pro
                        Teilnehmenden generiert wird, um einen Zugang zur
                        Umfrage zu gewährleisten. In Orgacheck ist keine
                        Möglichkeit gegeben, die Codes zu einzelnen Personen
                        anhand der Umfragedaten zuzuordnen. <br />
                        <br />
                        Wie werden die Daten aufbewahrt?
                        <br />
                        @Alexander <br />
                        Wer hat Zugriff auf die Daten? Zugriff auf die Rohdaten
                        hat das Orgacheck Team. Zugriff zu den ausgewerteten
                        Dateien hat der Nutzer von orgacheck per PDF-Bericht,
                        der automatisiert erstellt werden kann. Was ist der
                        Zweck dieser Verarbeitung? <br />
                        @Alexander
                      </p>
                    </li>
                    <li>
                      <h4>Kontaktdaten der Datenschutzbeauftragten</h4>
                      <p class="pt-2 text--secondary">
                        Regine Schmirander <br />
                        Universitätsring 15,<br />
                        54296 Trier<br />
                        E-Mail: dsb@uni-trier.de <br />
                      </p>
                    </li>
                    <li>
                      <h4>Rechtsgrundlage</h4>
                      <p class="pt-2 text--secondary">
                        Die Universität Trier verarbeitet die von Ihnen
                        erhobenen personenbezogene Daten auf Basis Ihrer
                        Einwilligung gemäß Art. 6 Abs. 1 Satz 1 Buchst. a DSGVO.
                        Sofern besondere Kategorien personenbezogener Daten
                        betroffen sind, verarbeitet die Universität Trier die
                        von Ihnen erhobenen personenbezogenen Daten auf Basis
                        Ihrer Einwilligung gemäß Art. 9 Abs. 2 Buchst. a DSGVO.
                      </p>
                    </li>
                    <li>
                      <h4>
                        Empfänger oder Kategorien von Empfängern /
                        Drittstaatenübermittlung
                      </h4>
                      <p class="pt-2 text--secondary">
                        Die personenbezogenen Daten werden nicht an Dritte
                        weitergegeben, außer eine Weitergabe ist nach einer
                        gesetzlichen Vorgabe oder einer gerichtlichen oder
                        behördlichen Entscheidung zwingend erforderlich.
                      </p>
                    </li>
                    <li>
                      <h4>
                        Dauer, für die die personenbezogenen Daten gespeichert
                        werden / Kriterien für die Festlegung der Dauer
                      </h4>
                      <p class="pt-2 text--secondary">
                        Ihre Daten werden für einen Zeitraum von … gespeichert
                        und anschließend datenschutzgerecht gelöscht.
                      </p>
                    </li>
                    <li>
                      <h4>
                        Ihre Rechte im Hinblick auf die gespeicherten Daten
                      </h4>
                      <p class="pt-2 text--secondary">
                        Nach Art. 15 DSGVO haben Sie einen Anspruch auf
                        kostenfreie Auskunft über die zu Ihrer Person
                        gespeicherten Daten. Sie können Ihre bei uns
                        gespeicherten Daten gemäß Art. 16 und 17 DSGVO
                        berichtigen oder löschen lassen, sowie die Verarbeitung
                        der Daten gem. Art. 18 DSGVO einschränken. Sie haben
                        außerdem gem. Art. 20 DSGVO das Recht, bereitgestellte
                        personenbezogene Daten von dem Verantwortlichen in einem
                        strukturierten, gängigen und maschinenlesbaren Format zu
                        erhalten und diese Daten unter bestimmten
                        Voraussetzungen einem anderen Verantwortlichen ohne
                        Behinderung durch den Verantwortlichen zu übermitteln
                        (Recht auf Datenübertragbarkeit).<br />
                        <br />
                        Darüber hinaus besteht das Recht zur Beschwerde bei der
                        zuständigen Datenschutzaufsichtsbehörde. Für die
                        Universität Trier ist dies der Landesbeauftragte für den
                        Datenschutz und die Informationsfreiheit
                        Rheinland-Pfalz, Hintere Bleiche 34, 55116 Mainz.
                      </p>
                    </li>
                    <li>
                      <h4>Einwilligungserklärung</h4>
                      <p class="pt-2 text--secondary">
                        Ich bin damit einverstanden, dass die genannten
                        personenbezogenen Daten von der Universität Trier für
                        die genannten Forschungszwecke erhoben und verarbeitet
                        werden. Sofern ich besondere Kategorien von
                        personenbezogenen Daten angebe bzw. angegeben habe, sind
                        diese von der Einwilligungserklärung umfasst. <br />
                        <br />
                        Ihre Einwilligung ist freiwillig. Sie können die
                        Einwilligung ablehnen, ohne dass Ihnen dadurch
                        irgendwelche Nachteile entstehen.<br />
                        <br />
                        Ihre Einwilligung können Sie jederzeit ohne Begründung
                        und ohne dass Ihnen daraus ein Nachteil entsteht,
                        widerrufen, mit der Folge, dass die Verarbeitung Ihrer
                        personenbezogenen Daten für die Zukunft unzulässig wird.
                        Dies berührt die Rechtmäßigkeit der aufgrund der
                        Einwilligung bis zum Widerruf erfolgten Verarbeitung
                        jedoch nicht.
                      </p>
                    </li>
                  </ol>
                </v-container>
              </li>
            </ol>
          </slot>
        </v-sheet>
        <v-checkbox
          v-model="confirmDisclaimer"
          label="Ich stimme den vorliegenden Datenschutzhinweisen zu und bestätige hiermit die Nutzung meiner persönlichen Daten."
          hide-details
        />
        <v-checkbox
          v-model="confirmCookies"
          label="hiermit erlaube ich die Verwendung von Cookies"
          hide-details
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          rounded
          color="success"
          depressed
          :disabled="!valid"
          @click.prevent="confirm"
        >
          Speichern
        </v-btn>
        <v-spacer />
        <v-btn rounded color="error" text @click.prevent="cancel">
          Abmelden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { cookieService } from "@/services";

export default {
  name: "o-data-protection-dialog",
  data() {
    return {
      show: true,
      confirmDisclaimer: false,
      confirmCookies: false,
    };
  },
  mounted() {
    this.show = !this.hasConfirmedDataProtectionDisclaimer();
  },
  methods: {
    confirm() {
      this.show = false;
      cookieService.setCookie("hasConfirmedDataProtectionDisclaimer", true);
    },
    cancel() {
      this.show = false;
      cookieService.setCookie("hasConfirmedDataProtectionDisclaimer", false);
      this.logout();
    },
    hasConfirmedDataProtectionDisclaimer() {
      const cookie = cookieService.getCookie(
        "hasConfirmedDataProtectionDisclaimer"
      );
      return cookie ? cookie === "true" : false;
    },
    ...mapActions("account", ["logout"]),
  },
  computed: {
    valid() {
      return this.confirmDisclaimer && this.confirmCookies;
    },
  },
};
</script>

<style scoped>
ol > li::before {
  font-size: 2em;
  font-weight: bold;
}
</style>
