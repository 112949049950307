var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"absolute":"","clipped":"","left":"","mini-variant":_vm.mini,"width":_vm.nav.width,"permanent":"","hide-overlay":""}},[_c('v-responsive',{attrs:{"width":`calc(${_vm.nav.width} - 56px)`}},[_c('v-scroll-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.nav.selected)?_c(_vm.nav.selected.content,_vm._g(_vm._b({tag:"component"},'component',{ ..._vm.$props },false),_vm.$listeners)):_vm._e()],1)],1),_c('v-sheet',{staticClass:"d-flex flex-column align-center",style:({
      justify: 'center',
      overflow: 'clip',
      position: 'absolute',
      top: '0',
      right: '0',
    }),attrs:{"elevation":"1","width":"56px","height":"100%"}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","height":"56px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('expand')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-expand-all-outline ")])],1)]}}])},[_vm._v(" aufklappen ")]),_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","height":"56px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('collapse')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-collapse-all-outline ")])],1)]}}])},[_vm._v(" einklappen ")]),_c('v-divider'),_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","height":"56px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm._compact = !_vm._compact}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-sitemap-outline ")])],1)]}}])},[_vm._v(" vereinfachen ")]),_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","height":"56px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('center')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fit-to-screen-outline")])],1)]}}])},[_vm._v(" zentrieren ")]),_c('o-icon-select-btn',{attrs:{"items":_vm.toolbar.layouts.items,"depressed":"","height":"56px","menu-props":{
        offsetX: true,
        rounded: 'lg',
      },"tooltip":"Orientierung"},model:{value:(_vm._layout),callback:function ($$v) {_vm._layout=$$v},expression:"_layout"}}),_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","height":"56px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('zoomIn')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")])],1)]}}])},[_vm._v(" vergrößern ")]),_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","height":"56px"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('zoomOut')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-minus-outline ")])],1)]}}])},[_vm._v(" verkleineren ")]),_c('span',{staticClass:"text--vertical-toolbar"},[_vm._v(" "+_vm._s(_vm._zoomLevel + "%")+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }