<template>
  <!-- <v-row
      no-gutters
      align="center"
      align-content="center"
      justify="center"
      class="pa-2"
    >
      <v-col cols="auto" class="pa-1">
        <v-img src="/logo.svg" max-height="56px" max-width="56px" contain/>
      </v-col>
      <v-col cols="auto" class="pa-1">
        <h2>OrgaCheck</h2>
      </v-col>
    </v-row> -->
  <div class="d-flex flex-row justify-center align-center align-content-center">
    <v-img src="/logo.svg" max-height="56px" max-width="56px" contain />
    <h2>OrgaCheck</h2>
  </div>
</template>

<script>
export default {
  name: "o-logo",
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
