import { jwtService } from "@/services";

class _VueRouterRoleGuard {
  constructor(router) {
    this.router = router;
  }
  hasAccess({ name }) {
    const route = this.router.options.routes.find((r) => r.name === name);
    if (!route)
      throw new Error(`Route ${name} is not defined in the current router`);

    return this._hasAccessToRoute(route).access;
  }
  async _hasAccessToRoute(route) {
    if (!route.meta.permissions) return { access: true };

    const role = await jwtService.role();
    const matched = route.meta.permissions.find((item) => item.role === role);

    if (matched) {
      if (
        (typeof matched.access === "boolean" && !matched.access) ||
        (typeof matched.access === "function" && !matched.access(role, route))
      ) {
        return { access: false, redirect: matched.redirect };
      }
    }

    return { access: true };
  }

  resolve(to, from, next) {
    this._hasAccessToRoute(to).then(({ access, redirect }) => {
      access ? next() : next({ name: redirect });
    }).catch((err) => {
      console.error(err);
    })
  }
}

const RoleGuard = {
  install(Vue, options) {
    if (!options.router) throw new Error("Vue-Router needs to referenced");

    const guard = new _VueRouterRoleGuard(options.router);
    options.router.beforeEach((to, from, next) =>
      guard.resolve(to, from, next)
    );
  },
};

export default RoleGuard;
