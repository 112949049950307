<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2">
          Organisationdetails
        </v-list-item-title>
        <v-container>
          <v-text-field 
            v-model="name" 
            rounded 
            filled 
            label="Name der Organisation"
            :rules="requiredField"
          />
          <v-text-field
            v-model="identifier"
            rounded
            filled
            label="Kurzname"
          />
          <v-textarea
            v-model="description"
            rounded
            filled
            label="Beschreibung"
            auto-grow
            counter="255"
          />
        </v-container>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item>
      <v-list-item-content>
        <o-address-field
          :address.sync="address"
          :zip.sync="zip"
          :city.sync="city"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import OAddressField from "@/common/components/OAddressField.vue";

export default {
  name: "o-company-form",
  components: {
    OAddressField,
  },
  data() {
    return {
      requiredField: [v => !!v || 'Angabe ist Pflicht!'],
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    reload: Boolean,
  },
  watch: {
    reload() {
      if (this.reload) {
        // console.log("reload triggered");
        this.getCompanies();
        this.$emit("update:reload", false);
        this.$emit("reload");
      }
    },
  },
  methods: {
    updateValue(key, value) {
      let item = { ...this.value };
      item[key] = value;
      this.$emit("input", item);
    },
  },
  computed: {
    name: {
      get() {
        return this.value.name;
      },
      set(value) {
        this.updateValue("name", value);
      },
    },
    identifier: {
      get() {
        return this.value.identifier;
      },
      set(value) {
        this.updateValue("identifier", value);
      },
    },
    address: {
      get() {
        return this.value.address;
      },
      set(value) {
        this.updateValue("address", value);
      },
    },
    zip: {
      get() {
        return this.value.zip;
      },
      set(value) {
        this.updateValue("zip", value);
      },
    },
    city: {
      get() {
        return this.value.city;
      },
      set(value) {
        this.updateValue("city", value);
      },
    },

    description: {
      get() {
        return this.value.description;
      },
      set(value) {
        this.updateValue("description", value);
      },
    },
  },
};
</script>

<style></style>
