<template>
  <div>
    <div style="position: relative; display: inline-block">
      <v-avatar size="128" color="secondary">
        <v-img v-if="image !== null" :src="image" alt="avatar" />
        <v-icon v-else size="28">mdi-camera</v-icon>
      </v-avatar>
      <v-sheet
        style="position: absolute; bottom: 0; right: 0"
        color="primary"
        rounded="circle"
      >
        <v-btn
          fab
          dark
          x-small
          @click="show = true"
          color="primary"
          :disabled="disabled"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
      </v-sheet>
    </div>
    <v-dialog :value="show" max-width="512px" @click:outside="close">
      <v-card flat rounded="xl">
        <v-card-title>
          Bild Hochladen
          <v-spacer />
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle> Erlaubt sind .PNG und .JPEG Bilder </v-card-subtitle>
        <v-card-text>
          <div
            @drop.stop.prevent="handleDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
            class="rounded-xl pa-6"
            :style="{
              border: `dashed 2px ${this.$vuetify.theme.secondary}`,
              width: '100%',
            }"
          >
            <v-row
              class="d-flex flex-column pa-2"
              dense
              align="center"
              justify="center"
            >
              <v-icon size="60" class="pa-1 mb-4"> mdi-image</v-icon>
              <span class="mb-1"><b>Drag & Drop</b> Bild hier</span>
              <span>oder</span>
              <o-file-input-btn
                plain
                rounded
                color="primary"
                @change="handleButton"
                :accept="accept"
              />
            </v-row>
          </div>
        </v-card-text>
        <v-slide-x-transition>
          <v-list-item v-if="file" class="px-6">
            <v-list-item-icon>
              <v-icon>mdi-file</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ file.name }} </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text--secondary"> {{ file.size / 1000 }} kb </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small color="error" @click.prevent="removeFile">
                <v-icon small> mdi-close </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-slide-x-transition>
        <v-card-actions style="display: block">
          <v-btn rounded depressed block color="primary" @click.prevent="save">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OFileInputBtn from "./OFileInputBtn.vue";
export default {
  components: { OFileInputBtn },
  name: "o-image-input",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragover: false,
      file: null,
      image: null,
      reader: null,
      show: false,
      accept: ["image/png", "image/jpeg", "image/bmp"],
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
    };
  },
  methods: {
    close() {
      this.file = null;
      this.$nextTick(() => {
        this.show = false;
      });
    },
    save() {
      this.readFile(this.file);
      this.$nextTick(() => {
        this.show = false;
      });
    },
    handleDrop(e) {
      this.dragover = false;

      this.file = e.dataTransfer.files[0];
    },
    handleButton(file) {
      this.file = file;
    },
    removeFile() {
      this.file = null;
      this.image = null;
    },
    readFile(file) {
      if (!file) return;

      this.file = file;
      this.reader = new FileReader();
      this.reader.onload = (e) => {
        this.image = e.target.result;
      };
      this.reader.onprogress = (e) => {
        //console.log(e);
      };
      this.reader.onerror = (e) => {
        // console.log(e);
      };

      this.reader.readAsDataURL(this.file);
    },
  },
  computed: {
    fileName() {
      return this.file === null || this.file === undefined
        ? ""
        : this.file.name;
    },
  },
};
</script>

<style>
.v-input.button > .v-input__prepend-outer {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}
</style>
