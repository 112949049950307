<template>
  <div>
    <!-- <o-rules-edit-dialog v-model="edit.item" /> -->
    <o-rules-edit-drawer v-model="edit.item" />
    <v-data-table
      dense
      :items="items"
      :server-items-length="totalItems"
      :headers="table.headers"
      hide-default-footer
      :height="height"
      fixed-header
      :loading="isLoading"
      :search="search"
      :items-per-page="-1"
      item-key="rule_id"
      @update:options="updateOptions"
    >
      
      <template #top>
        <v-toolbar flat dense>
            <v-toolbar-title v-if="reporting_reference != null"> 
              <v-toolbar-title class="text-h5">
                <span class="berichte-title">Berichte:</span>
                <span class="text-h7">
                  {{ transformedReportingReference }}
                </span>
              </v-toolbar-title>
              <!--
              <div class="text-h5 pt-8">
                Berichte
              </div> 
              <div class="text-h7">
                {{ transformedReportingReference }}
              </div>
              -->
            </v-toolbar-title>
          <v-spacer />
          <v-btn depressed color="primary" @click="editItem(edit.default)">
            <v-icon left>mdi-plus</v-icon>
            Neuer Bericht
          </v-btn>
        </v-toolbar>
      </template>
      <template #no-data>
        <b>Keine Berichte vorhanden...</b>
      </template>
      <template v-slot:[`item.index`]="{ index }">
        {{ index + 1 }}
      </template>
      
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ item.name }}</span>
          </template>
          <span>{{ item.rulelocator }} --> {{ item.hash }}</span>
        </v-tooltip>
      </template>
      <!--
      <template v-slot:[`item.description`]="{ item }">
        {{ item.descriptiom }}
      </template>
      -->
      <template v-slot:[`item.split_typus`]="{ item }">
        <o-status-chip
          small
          :value="item.split_typus"
          :options="{
            category: {
              name: 'Kategoriebericht',
              color: '#E96F1E',
            },
            node: {
              name: 'Abteilungsbericht',
              color: '#E13568',
            },
            company: {
              name: 'Organisationsbericht',
              color: '#B3BF1D',
            },
          }"
        />
      </template>
      <!--
      <template v-slot:[`item.split_reference`]="{ item }">
        <o-status-chip
          small
          :value="item.split_reference"
          :options="{
            none: {
              name: 'ohne',
              color: '#47852C',
            },
            norm: {
              name: 'Norm',
              color: '#E13568',
            },
            company: {
              name: 'Organisation',
              color: '#B3BF1D',
            },
          }"
        />
      </template>
      -->
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
        <!-- {{ new Date(item.created_at).toLocaleString() }} -->
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              @click="openPDF(item.hash)"
              :disabled="item.n_completed < 3"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
            Bericht herunterladen
        </v-tooltip>
        <v-menu offset-y rounded="lg" offset-overflow left>
          <template #activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon> mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="editItem(item)">
              <v-list-item-avatar>
                <v-icon> mdi-pencil</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Bericht bearbeiten</v-list-item-title>
            </v-list-item>
            <v-list-item @click="copyToClipboard(item.hash)">
              <v-list-item-avatar>
                <v-icon> mdi-content-copy </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Link kopieren</v-list-item-title>
            </v-list-item>
            <!--
            <v-list-item
              @click="openPDF(item.hash)"
              :disabled="item.n_completed < 3"
            >
              <v-list-item-avatar>
                <v-icon> mdi-file-pdf-box </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Bericht herunterladen</v-list-item-title>
            </v-list-item>
            -->
            <v-list-item @click="deleteItem(item)">
              <v-list-item-avatar>
                <v-icon color="error"> mdi-trash-can-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Bericht löschen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import OStatusChip from "@/common/components/OStatusChip.vue";
import ORulesEditDrawer from "./ORulesEditDrawer.vue";

export default {
  name: "o-rules-table",
  components: {
    OStatusChip,
    ORulesEditDrawer,
  },
  props: {
    value: {
      type: Object,
    },
    reporting_reference: {
      type: String,
      default: null,
    },
    reference_rulelocator: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      message: "Link kopieren",
      search: "",
      edit: {
        item: null,
        default: {
          name: "",
          description: "",
          rulelocator: null,
          hash: null,
          /* split_reference: "none", */
          split_typus: "company",
          rule: [],
        },
      },
      table: {
        headers: [
          { text: "#", value: "index", align: "start", sortable: false },
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Kommentar",
            value: "description",
          },
          {
            text: "Split",
            value: "split_typus",
          },
          {
            text: "Interviews",
            value: "n_completed",
          },
          {
            text: "erstellt am",
            value: "created_at",
          },
          {
            text: "Aktionen",
            value: "action",
            align: "end",
            sortable: false,
          },
        ],
        actions: [],
      },
    };
  },
  mounted() {
    this.updateReportingId(this.value.reporting_id);
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(date).toLocaleString('de-DE', options);
    },
    openPDF(rule_hash) {
      const result_url = process.env.VUE_APP_API_URL + "/results/" + rule_hash;
      window.open(result_url);
      //this.downloadReport(rule_hash);
    },
    copyToClipboard: function (rule_hash) {
      const result_url = process.env.VUE_APP_API_URL + "/results/" + rule_hash;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(result_url).then(() => {
          this.showAlertSuccess("Berichtslink wurde in Zwischenablage kopiert");
        });
      }
    },
    editItem(item) {
      this.edit.item = { ...item };

      this.edit.item.reporting_id = this.value.reporting_id;
      this.edit.item.survey_id = this.value.survey_id;
    },
    async deleteItem(item) {
      if (item === null || item === undefined) return;

      const permission = await this.awaitModal({
        title: "Löschen",
        message: " Wollen sie diesen Bericht wirklich löschen?",
      });
      if (permission) {
        await this.deleteRule(item);
      }
    },
    // updateContent(value) {
    //   console.log(value);
    //   this.updateOptions({
    //     reporting_id: this.value.reporting_id,
    //     options: value,
    //   });
    // },
    ...mapActions("rules", {
      deleteRule: "delete",
      updateReportingId: "updateReportingId",
      downloadReport: "downloadReport",
      updateOptions: "updateOptions",
    }),
    ...mapActions("modal", ["awaitModal"]),
    ...mapActions("alert", ["showAlertSuccess"]),
  },
  computed: {
    transformedReportingReference() {
      switch (this.reporting_reference) {
        case 'norm':
          return 'Referenz Normierung';
        case 'company':
          return 'Referenz Organisation';
        case 'rule':
          return 'Referenz ' + this.reference_rulelocator;
        case 'none':
          return 'Keine Referenz';
        default:
          return this.reporting_reference;
      }
    },
    height() {
      let h = 36;
      h += this.items.length * 36;
      h = Math.max(0, Math.min(h, 512));
      return h;
    },
    ...mapGetters("rules", ["items", "totalItems", "isLoading"]),
  },
  watch: {
    value(value) {
      if (!value) return;
      this.updateReportingId(value.reporting_id);
    },
  },
};
</script>

<style scoped>
  .berichte-title {
    margin-right: 30px;
  }
</style>
