<template>
  <v-menu v-bind="{ ...menuProps }">
    <template #activator="menu">
      <v-tooltip right open-delay="500">
        <template #activator="tooltip">
          <v-btn
            v-on="{ ...tooltip.on, ...menu.on, ...$listeners }"
            v-bind="{ ...tooltip.attrs, ...menu.attrs, ...$attrs }"
          >
            <v-icon>{{ _icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-btn-toggle v-model="_value" mandatory>
      <v-btn v-for="(item, index) in items" :key="index" icon>
        <v-icon>{{ item }}</v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-menu>
</template>

<script>
export default {
  name: "o-icon-select-btn",
  props: {
    value: {
      type: Number,
    },
    items: {
      type: Array,
    },
    menuProps: {
      type: Object,
      default: () => {
        return {
          offsetX: false,
          offsetY: true,
          offestOverflow: false,
          nudgeTop: 0,
          nudgeBottom: 0,
          nudgeLeft: 0,
          nudgeRight: 0,
          top: false,
          rounded: "xl",
        };
      },
    },
    tooltip: {
      type: String,
      default: "Tooltip Content",
    },
  },
  computed: {
    _icon() {
      return this._value ? this.items[this._value] : this.items[0];
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
