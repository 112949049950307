<template>
  <component
    :is="scalartype.component"
    v-bind="{ ...scalartype.props, ...question }"
    v-model="_value"
  />
</template>

<script>
import OPollScalarRadio from "./OPollScalarRadio.vue";
import OPollScalarSlider from "./OPollScalarSlider.vue";
import OPollScalarIcon from "./OPollScalarIcon.vue";
import OPollScalarBipolar from "./OPollScalarBipolar.vue";

export default {
  name: "o-poll-scalartype-item",
  props: {
    value: {
      type: Number,
    },
    question: {
      type: Object,
      default: () => {
        return {
          scalartype: 1,
        };
      },
    },
  },
  data() {
    return {
      types: {
        1: {
          component: OPollScalarRadio,
          props: {
            scalar: [
              "stimme nicht zu",
              "stimme eher nicht zu",
              "teils teils",
              "stimme eher zu",
              "stimme zu",
            ],
          },
        },
        2: {
          component: OPollScalarRadio,
          props: {
            scalar: [
              "stimme nicht zu",
              "stimme eher nicht zu",
              "teils teils",
              "stimme eher zu",
              "stimme zu",
            ],
          },
        },
        3: {
          component: OPollScalarRadio,
          props: {
            scalar: ["nie", "selten", "gelegentlich", "oft", "immer"],
          },
        },
        19: {
          component: OPollScalarRadio,
          props: {
            scalar: ["nie", "selten", "gelegentlich", "oft", "immer"],
          },
        },
        9: {
          component: OPollScalarSlider,
          props: {
            prependLabel: "nie",
            appendLabel: "immer",
          },
        },
        11: {
          component: OPollScalarSlider,
          props: {
            prependLabel: "keine",
            appendLabel: "alle",
          },
        },
        17: {
          component: OPollScalarSlider,
          props: {
            prependLabel: "nie (persönlich)",
            appendLabel: "immer",
          },
        },
        18: {
          component: OPollScalarRadio,
          props: {
            scalar: [
              "gar nicht",
              "ein wenig",
              "mittelmäßig",
              "ziemlich",
              "sehr",
            ],
          },
        },
        20: {
          component: OPollScalarRadio,
          props: {
            scalar: [
              "gar nicht",
              "ein wenig",
              "mittelmäßig",
              "ziemlich",
              "sehr",
            ],
          },
        },
        6: {
          component: OPollScalarIcon,
          props: {
            scalar: [
              "mdi-emoticon-sad-outline",
              "mdi-emoticon-confused-outline",
              "mdi-emoticon-neutral-outline",
              "mdi-emoticon-outline",
              "mdi-emoticon-excited-outline",
            ],
          },
        },
        31: {
          component: OPollScalarRadio,
          props: {
            scalar: [
              "Trifft überhaupt nicht zu",
              "Trifft nicht zu",
              "Trifft eher nicht zu",
              "Trifft eher zu",
              "Trifft zu",
              "Trifft voll und ganz zu",
            ],
          },
        },
        32: {
          component: OPollScalarRadio,
          props: {
            scalar: [
              "Nie / fast nie",
              "Selten",
              "Eher selten",
              "Eher oft",
              "Oft",
              "Immer / fast immer",
            ],
          },
        },
        33: {
          component: OPollScalarBipolar,
          props: {
            scalar: [
              "genau A",
              "fast A",
              "eher A",
              "eher B",
              "fast B",
              "genau B",
            ],
          },
        },
      },
    };
  },
  computed: {
    scalartype() {
      return this.types[this.question.scaletype];
    },
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
S
