<template>
  <v-select
    label="Farb Gruppe"
    filled
    rounded
    :items="items"
    item-value="id"
    v-model="select"
    :menu-props="{
      offsetY: true,
      rounded: 'xl',
    }"
    v-on="$listeners"
    :readonly="readonly"
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-avatar>
          <v-avatar size="20" :color="item.color" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <v-avatar size="20" :color="item.color" class="mr-2" />
      {{ item.name }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: "o-chart-category-select",
  props: {
    value: {
      default: "0",
    },
    items: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    select: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
