import { render, staticRenderFns } from "./OPasswordForgottenForm.vue?vue&type=template&id=53a4d9a6"
import script from "./OPasswordForgottenForm.vue?vue&type=script&lang=js"
export * from "./OPasswordForgottenForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports