<template>
  <o-show-chart-editor
    :tree_id="tree_id" 
    :survey_id="survey_id"
    v-model="tree" 
  />
</template>

<script>
import { mapActions } from "vuex";
import OShowChartEditor from "./OShowChartEditor.vue";

export default {
  name: "o-show-tree",
  components: { OShowChartEditor },
  props: {
      tree_id: {
        type: Number,
      },
      survey_id: {
        type: Number,
        default: null,
      }
    },
  data() {
    return {
      saved: false,
      timeout: 3000,
      tree: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (this.survey_id != null) {
        if (this.tree_id != null) {
          // console.log("Survey getTree Id: ", this.tree_id, '  surveyId:', this.survey_id);
          // const response = await this.getByTreeId(this.tree_id);
          const response = await this.getFieldTreeBySurveyId(this.survey_id);
          this.tree = { ...response };
      }
      }
      else if (this.tree_id != null) {
        // console.log("Only getTree Id: ", this.tree_id, '  surveyId:', this.survey_id);
        const response = await this.getByTreeId(this.tree_id);
        this.tree = { ...response };
      }
    },
    ...mapActions("tree", {
      getByTreeId: "getByTreeId", 
      getFieldTreeBySurveyId: "getFieldTreeBySurveyId",
    }),
    ...mapActions("alert", ["showAlertSuccess"]),
  },
  watch: {
    tree_id: {
      handler: function (value) {
        this.load();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
