<template>
  <v-list-item v-if="value" two-line>
    <v-list-item-avatar>
      <v-sheet flat width="50" height="50" style="position: relative">
        <v-overlay absolute :value="true" :color="type.color" opacity="0.15">
          <v-icon :color="type.color" style="opacity: 100%">
            {{ type.icon }}
          </v-icon>
        </v-overlay>
      </v-sheet>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title :style="{ 'white-space': 'normal' }">
        {{ text }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="value.createdAt">
       <!--  <v-chip v-for="group in value.groups" :key="`chip_${group}`">
          {{ group }}
        </v-chip> -->
        {{ createdAt }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { formatDistance } from "date-fns";
import de from "date-fns/locale/de";

export default {
  name: "o-alert-item",
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: "error",
          text: "message",
          time: Date.now(),
        };
      },
    },
    index: {
      type: Number,
    },
    height: {
      type: Number,
      default: 56,
    },
  },
  data() {
    return {
      types: {
        error: {
          icon: "mdi-alert",
          color: "error",
        },
        success: {
          icon: "mdi-checkbox-marked-circle",
          color: "success",
        },
        info: {
          icon: "mdi-information",
          color: "info",
        },
        fallback: {
          icon: "mdi-exclamation-thick",
          color: "primary",
        },
      },
    };
  },
  computed: {
    type() {
      return this.types[this.value.type] ?? this.types.fallback;
    },
    text() {
      return this.value.text;
    },
    createdAt() {
      return formatDistance(this.value.createdAt, new Date(), { locale: de });
      /* const time = new Date(Date.now() - this.value.time);
      return time.getMinutes(); */
    },
  },
};
</script>

<style></style>

<!-- <template>
  <v-list-item v-if="value" two-line>
    <v-list-item-avatar>
      <v-sheet flat width="50" height="50" style="position: relative">
        <v-overlay absolute :value="true" :color="type.color" opacity="0.15">
          <v-icon :color="type.color" style="opacity: 100%">
            {{ type.icon }}
          </v-icon>
        </v-overlay>
      </v-sheet>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title :style="{ 'white-space': 'normal' }">
        {{ text }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="value.createdAt">
        {{ createdAt }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { formatDistance } from "date-fns";
import de from "date-fns/locale/de";

export default {
  name: "o-alert-item",
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: "error",
          text: "message",
          time: Date.now(),
        };
      },
    },
    index: {
      type: Number,
    },
    height: {
      type: Number,
      default: 56,
    },
  },
  data() {
    return {
      types: {
        error: {
          icon: "mdi-alert",
          color: "error",
        },
        success: {
          icon: "mdi-checkbox-marked-circle",
          color: "success",
        },
        info: {
          icon: "mdi-info",
          color: "info",
        },
        fallback: {
          icon: "mdi-exclamation-thick",
          color: "primary",
        },
      },
    };
  },
  computed: {
    type() {
      return this.types[this.value.type] ?? this.types.fallback;
    },
    text() {
      return this.value.text;
    },
    createdAt() {
      return formatDistance(this.value.createdAt, new Date(), { locale: de });
      /* const time = new Date(Date.now() - this.value.time);
      return time.getMinutes(); */
    },
  },
};
</script>

<style></style>
 -->
