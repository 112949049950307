<template>
  <h1>404 Nicht gefunden</h1>
</template>

<script>
export default {
  name: "o-404-view",
};
</script>

<style></style>
