<template>
  <v-text-field
    v-model="email"
    :rules="rules"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: "o-email-field",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Email",
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    prependInnerIcon:{
      type: String,
      default: "mdi-email"
    },
  },
  data() {
    return {
      rules: [
        (value) => !!value,
        (value) => {
          //var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}(\/[A-Z0-9._%+-]*)?$/i;
          return regex.test(value) || "Die angegebene Email is ungültig";
        },
      ],
    };
  },
  computed: {
    email: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
