<template>
  <v-row align="center">
    <v-col class="text-h6"> Am <b>Arbeitsplatz A</b>{{ text }} </v-col>
    <v-col cols="4">
      <!--  <v-slider
        v-model="_value"
        :min="0"
        :max="scalar.length - 1"
        :tick-labels="scalar"
        :rules="rules"
        append-icon="mdi-chevron-right"
        prepend-icon="mdi-chevron-left"
      >
      </v-slider> -->
      <v-radio-group row class="mr-0" v-model="_value" :rules="rules">
        <div
          class="d-flex flex-column align-center"
          v-for="(item, i) in scalar"
          :key="i"
        >
          <span class="vertical-text"> {{ item }}</span>
          <v-radio class="py-6" :value="i+1"> </v-radio>
        </div>
      </v-radio-group>
    </v-col>
    <v-col class="text-h6"> Am <b>Arbeitsplatz B</b>{{ textb }} </v-col>
  </v-row>
</template>

<script>
export default {
  name: "o-poll-scalar-bipolar",
  props: {
    value: {
      type: Number,
    },
    scalar: {
      type: Array,
      default: () => {
        return ["genau A", "fast A", "eher A", "eher B", "fast B", "genau B"];
      },
    },
    text: {
      type: String,
      default:
        "lorem, ipsum dolor sit amet consectetur adipisicing elit. beatae perferendis obcaecati in cupiditate similique velit ex doloribus quibusdam sint!",
    },
    textb: {
      type: String,
      default:
        "lorem, ipsum dolor sit amet consectetur adipisicing elit. beatae perferendis obcaecati in cupiditate similique velit ex doloribus quibusdam sint!",
    },
  },
  data() {
    return {
      rules: [(v) => v !== undefined && v !== null],
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.vertical-text {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
}
</style>
