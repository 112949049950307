import { questionaryService } from "@/services";

const state = {
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  options: {}
};

const actions = {
  async getAll({ commit, dispatch, rootGetters, getters }, options) {
    try {
      commit("loading");
      const company_id = rootGetters["account/companyId"];
      const opt = options ?? getters.options;
      const response = await questionaryService.getAll({
        company_id,
        options: opt,
      })
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });

      return response;
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async update({ dispatch }, data) {
    try {
      const response = await questionaryService.update(data);
      await dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Fragebogen erfolgreich aktualisiert!",
        { root: true }
      );

      return response;
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async create({ dispatch }, data) {
    try {
      const response = await questionaryService.create(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Fragebogen wurde angelegt!",
        {
          root: true,
        }
      );
      return response;
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async delete({ dispatch }, data) {
    try {
      await questionaryService.delete(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Fragebogen wurde gelöscht!",
        {
          root: true,
        }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async getQuestionaryCount({ dispatch }, id) {
    try {
      const { n_questionnaires } = await questionaryService.getQuestionaryCount(id);
      return n_questionnaires;
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async updateOptions({ commit, dispatch }, options) {
    await commit("setOptions", options);
    dispatch("getAll");
  }
};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, value) {
    state.isLoading = false;
    state.error = value;
  },
  setOptions(state, options) {
    state.options = options;
  }
};

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  options: (state) => state.options,
};

export const questionary = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
