<template>
  <o-loader getter="questions/all">
    <template v-slot:default="{ value }">
      <v-data-table
        class="elevation-1"
        :headers="table.headers"
        :items="value"
        item-key="category_id"
        hide-default-footer
        show-expand
        :expandeds.sync="table.expanded"
        :single-expand="table.singleExpand"
      >
        <template #[`item.name`]="{ item }">
          <v-list-item>
            <v-list-item-avatar>
              <v-sheet
                :color="item.colorcode ?? 'success'"
                width="15px"
                class="py-1"
                rounded="xl"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:[`item.subcategoriescount`]="{ item }">
          {{
            `${getSelectedSubcategories(item).length}/${
              item.subcategories.length
            }`
          }}
        </template>
        <template v-slot:[`item.questioncount`]="{ item }">
          {{ questionCount(item) }}
        </template>
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <tr v-if="getSelectedSubcategories(item).length > 0">
            <td>
              <v-btn icon @click="expand(!isExpanded)">
                <v-icon>{{
                  `mdi-chevron-${isExpanded ? "up" : "down"}`
                }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <v-list dense>
              <template
                v-for="(subcategory, index) in getSelectedSubcategories(item)"
              >
                <v-list-item :key="index" dense>
                  <v-list-item-avatar>
                    <v-sheet
                      class="pa-1"
                      width="15px"
                      :color="subcategory.colorcode ?? 'success'"
                      rounded="xl"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ subcategory.name }}
                      ({{
                        isLongVersion(subcategory)
                          ? "Lange Version"
                          : "Kurze Version"
                      }})
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Anzahl Fragen:
                      {{
                        isLongVersion(subcategory)
                          ? subcategory.questioncount_long
                          : subcategory.questioncount_short
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`divider_${index}`" v-if="index < getSelectedSubcategories(item).length-1"/>
              </template>
            </v-list>
          </td>

          <!-- <td v-for="(header, index) in headers" :key="index">
            <v-list dense >
              <v-list-item
                v-for="(subcategory, index) in getSelectedSubcategories(item)"
                :key="index"
              >
                <v-list-item-avatar v-if="header.value === 'name'">
                  <v-sheet
                    class="pa-1"
                    width="15px"
                    :color="subcategory.colorcode ?? 'success'"
                    rounded="xl"
                  />
                </v-list-item-avatar>
                <v-list-item-title>
                  {{ subcategory[header.value] }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </td> -->
        </template>
      </v-data-table>
    </template>
  </o-loader>
</template>

<script>
import OLoader from "@/common/components/OLoader.vue";

export default {
  components: { OLoader },
  name: "o-categorie-summary",
  props: {
    item: {
      type: Object,
    },
    editallowed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      table: {
        expanded: [],
        singleExpand: false,
        headers: [
          {
            text: "Kategorie",
            value: "name",
            align: "start",
          },
          {
            text: "Anzahl Fragen",
            value: "questioncount",
          },
          {
            text: "Anzahl Module",
            value: "subcategoriescount",
          },
          { text: "", value: "data-table-expand" },
        ],
      },
    };
  },
  methods: {
    getSelectedSubcategories(categorie) {
      return categorie.subcategories.filter((cat) =>
        this.item.short_subcategories.includes(cat.id)
      );
    },
    isLongVersion(subcategory) {
      return this.item.long_subcategories.includes(subcategory.id);
    },
    questionCount(categorie) {
      let count = 0;

      categorie.subcategories.forEach((subcategorie) => {
        if (!this.item.short_subcategories.includes(subcategorie.id)) return;
        const isLong = this.item.long_subcategories.includes(subcategorie.id);

        count += isLong
          ? subcategorie.questioncount_long
          : subcategorie.questioncount_short;
      });

      return count;
    },
  },
};
</script>

<style></style>
