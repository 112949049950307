<template>
  <v-btn text @click.prevent.stop="_logout">
    <v-icon left> mdi-logout </v-icon>
    Logout
  </v-btn>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "o-logout-btn",
  methods: {
    ...mapActions("account", ["logout"]),
    _logout() {
      this.logout();
      //window.location.reload();
    },
  },
};
</script>

<style></style>
