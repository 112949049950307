import { apiService } from "./index";

export const treeService = {
  get: (id) => apiService.GET(`/trees/${id}`),
  getFieldTreeBySurveyId: (survey_id) => apiService.GET(`/surveys/tree/${survey_id}/withcount`),
  getPollTreeBySurveyId: (survey_id) => apiService.GET(`/polls/tree/${survey_id}/nocount`),
  create: (company_id) => apiService.GET(`/trees/company/${company_id}`),
  update: (id, data) => apiService.PUT(`/trees/${id}`, data),
  delete: (id) => apiService.DELETE(`/trees/${id}`),
  getAll: () => apiService.GET(`/trees`),
  getNodeCount: (id) => apiService.GET(`/welcome/trees/${id}`),
};