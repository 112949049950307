<template v-if="item">
  <v-navigation-drawer
    clipped
    right
    absolute
    :value="show"
    width="750px"
    style="z-index: 4"
    height="100%"
  >
    <template #prepend>
      <v-toolbar v-if="show" flat>
        <v-toolbar-title>
          <span v-if="item.rule_id">Bericht bearbeiten (ID {{ item.rule_id }})</span>
          <span v-if="!item.rule_id">Bericht erstellen</span>
        </v-toolbar-title>
        <!-- <v-toolbar-itle>Subtitle</v-toolbar-title> -->
      </v-toolbar>
    </template>
    <template #append>
      <v-toolbar flat>
        <v-btn text color="error" @click.prevent="close">
          <v-icon left>mdi-cancel</v-icon> Abbruch
        </v-btn>
        <v-spacer />
        <v-btn depressed color="primary" @click="save" :disabled="!_valid">
          <v-icon left>mdi-check</v-icon> Speichern
        </v-btn>
      </v-toolbar>
    </template>
    <v-fade-transition>
      <v-form v-if="show" v-model="valid">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Beschreibung
            </v-list-item-title>
            <v-container fluid>
              <v-text-field
                v-model="item.name"
                rounded
                filled
                label="Name *"
                :rules="[
                  v => !!v || 'Pflichtfeld',
                  v => v.length <= 22 || 'Maximal 22 Zeichen erlaubt'
                ]"
                counter="22"
              />
              <v-textarea
                v-model="item.description"
                rounded
                filled
                counter="128"
                label="Kommentar"
              />
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Splits
            </v-list-item-title>
            <span class="py-4">
              Neben dem Organisationsbericht können noch Abteilungs- und Kategorie- Teilberichte (Splits) erzeugt werden: Teilergebnisse können entweder nur nach Abteilungen oder Kategorien gesplittet werden. Mehrfachauswahlen sind jeweils innerhalb der Abteilungen oder Kategorien möglich.
            </span>
            <v-toolbar dense flat>
              <v-tabs v-model="_split_typus" fixed-tabs>
                <v-tab>
                  <v-icon left>
                    {{
                      item.split_typus === "company"
                        ? "mdi-radiobox-marked"
                        : "mdi-radiobox-blank"
                    }}
                  </v-icon>
                  Organisationsbericht
                </v-tab>
                <v-tab>
                  <v-icon left>
                    {{
                      item.split_typus === "node"
                        ? "mdi-radiobox-marked"
                        : "mdi-radiobox-blank"
                    }}
                  </v-icon>
                  Abteilungsbericht
                </v-tab>
                <v-tab>
                  <v-icon left>
                    {{
                      item.split_typus === "category"
                        ? "mdi-radiobox-marked"
                        : "mdi-radiobox-blank"
                    }}
                  </v-icon>
                  Kategoriebericht
                </v-tab>
              </v-tabs>
            </v-toolbar>
          </v-list-item-content>
        </v-list-item>
        <v-container fluid>
          <div v-if="item.split_typus === 'node'">
            <v-list-item-title 
              v-model="item.rule"
              :rules="[(v) => !!v]"
              class="text-subtitle-2">
              <span class="py-4">Split nach Abteilungen des Organisationsbaums, bitte wählen Sie die Abteilung(en).</span>
            </v-list-item-title>
            <br/>
            <o-survey-tree-field
              v-model="item.rule"
              :survey-id="item.survey_id"
              multiple
              maxHeight="512px"
              :rules="[(v) => !!v]"
            />
          </div>

          <div v-if="item.split_typus === 'category'">
            <v-list-item-title 
              v-model="item.rule"
              :rules="[(v) => !!v]"
              class="text-subtitle-2">
                <span class="py-4">Split nach Kategorien des Organisationsbaums, bitte wählen Sie die Kategorie(n).</span>
            </v-list-item-title>
            <br/>
            <o-chart-report-group-select
              v-model="item.rule"
              :rules="[(v) => !!v]"
            />
          </div>

          <div v-if="item.split_typus === 'company'">
            <v-list-item-title 
              v-model="item.rule"
              :rules="[(v) => !!v]"
              class="text-subtitle-2">
                <span class="py-4">Organisationsbericht über alle Abteilungen und Kategorien.</span>
            </v-list-item-title>
          </div>
        </v-container>
        <!--
        <v-list-item v-if="orgacheck2" >
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Referenztyp
            </v-list-item-title>
            <span class="py-4">
              Sollen die Split-Ergebnisse mit der Organisation, der Norm oder gar nicht verglichen werden?
            </span>
              <v-radio-group
                v-model="_split_reference"
                row
                mandatory
              >
                <v-radio
                  label="Organisation"
                  value="company"
                ></v-radio>
                <v-radio
                  label="Norm"
                  value="norm"
                ></v-radio>
                <v-radio
                  label="ohne"
                  value="none"
                ></v-radio>
             </v-radio-group>
          </v-list-item-content>
        </v-list-item>
        -->
      </v-form>
    </v-fade-transition>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { OSurveyTreeField, OChartReportGroupSelect } from "@/modules/company/tree";
import { mapActions } from "vuex";

export default {
  name: "o-rules-edit-drawer",
  components: {
    OSurveyTreeField,
    OChartReportGroupSelect,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      item: null,
      valid: false,
    };
  },
  methods: {
    close() {
      this.$emit("input", null);
    },
    async save() {
      try {
        if (!this.item || !this.item.reporting_id)
          throw new Error("items was not set!");
        // console.log(this.item);
        var handler = !this.item.rule_id ? this.create : this.update;
        await handler(this.item);
      } catch (error) {
        console.error(error);
      } finally {
        this.close();
      }
    },
    updateValue(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    ...mapActions("rules", ["create", "update"]),
  },
  computed: {
    ...mapGetters("account", ["user"]),
    orgacheck2() {
      if (!this.item.orgagroup_id) {
        return false;
      } else {
        if (this.item.orgagroup_id == 2) {
          return true;
        }
        return false;
      }
    },
    show() {
      return this.item !== null && this.item !== undefined;
    },
    _valid() {
      // return this.valid && this.item && this.item.rule.length > 0;
      return this.valid && this.item;
    },
    /*
    _split_reference: {
      get() {
        return this.item.split_reference;
        // return this.item._split_reference === "none" ? "none" : "company";
      },
      set(value) {
        this.item.split_reference = value;
        // this.item.split_reference = value === "none" ? "none" : "compnay";
      },
    },
    */
    _split_typus: {
      get() {
        if (this.item.split_typus === "node") {
          return 1;
        } else if (this.item.split_typus === "category") {
          return 2;
        } else {
          return 3;
        }
        // return this.item.split_typus;
        // return this.item._split_reference === "none" ? "none" : "company";
      },
      set(value) {
        if (value === 1) {
          this.item.split_typus = "node";
        } else if (value == 2) {
          this.item.split_typus = "category";
        } else {
          this.item.split_typus = "company";
        }
      },
    },
    /*
    _ruletype: {
      get() {
        return this.item.ruletype === "node" ? 0 : 1;
      },
      set(value) {
        this.item.ruletype = value === 0 ? "node" : "category";
      },
    },
    */
  },
  watch: {
    value(value) {
      this.item = value;
      
      if (!this.item) return;
      if (!this.item.orgagroup_id) {
        this.item.orgagroup_id = this.user.orgagroup_id;
      }

    },
  },
};
</script>

<style>
  .subtitle {
    font-size: 0.8rem;
    color: gray;
  }
</style>

