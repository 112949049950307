<template>
  <v-autocomplete
    v-if="!loading"
    v-model="select"
    :items="_items"
    filled
    rounded
    chips
    label="Kategorien"
    item-text="name"
    item-value="id"
    multiple
    :menu-props="{
      rounded: 'xl',
    }"
    no-data-text="Keine Kategorien vorhanden"
    v-on="$listeners"
  >
    <template v-slot:selection="{ attrs, selected, item, parent }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        @click:close="parent.selectItem(item)"
      >
        {{ item.name }}
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-title>
        {{ item.name }}
      </v-list-item-title>
      <!-- <template v-if="typeof item !== 'object'">
        <v-list-item-content>
          {{ item }}
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </template> -->
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "o-chart-report-group-select",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      groups: [],
      categories: [],
    };
  },
  async mounted() {
    this.loading = true;
    const tree = await this.getByCompanyId();
    this.loading = false;

    if (tree) {
      this.groups = tree.reporting_groups;
      this.categories = tree.reporting_categories;
    }
  },
  methods: {
    ...mapActions("tree", ["getByCompanyId"]),
  },
  computed: {
    _items() {
      const items = [];
      for (let i = 0; i < this.groups.length; i++) {
        const group = this.groups[i];
        items.push({ header: group.name });

        items.push(
          ...this.categories.filter((cat) => {
            return cat.group_id === group.id;
          })
        );

        if (i < this.groups.length - 1) {
          items.push({ divider: true });
        }
      }
      return items;
    },
    select: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
