<template>
  <v-navigation-drawer
    absolute
    clipped
    left
    :mini-variant="mini"
    :width="nav.width"
    permanent
    hide-overlay
  >
    <v-responsive :width="`calc(${nav.width} - 56px)`">
      <v-scroll-y-transition hide-on-leave>
        <component
          v-if="nav.selected"
          :is="nav.selected.content"
          v-bind="{ ...$props }"
          v-on="$listeners"
        />
      </v-scroll-y-transition>
    </v-responsive>
    <v-sheet
      elevation="1"
      width="56px"
      height="100%"
      class="d-flex flex-column align-center"
      :style="{
        justify: 'center',
        overflow: 'clip',
        position: 'absolute',
        top: '0',
        right: '0',
      }"
    >
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            @click.prevent="mini = !mini"
            height="56px"
            v-on="on"
            v-bind="attrs"
          >
            <v-icon large>
              {{
                mini ? "mdi-chevron-double-right" : "mdi-chevron-double-left"
              }}
            </v-icon>
          </v-btn>
        </template>
        {{ mini ? "öffnen" : "schließen" }}
      </v-tooltip>
      <v-btn-toggle
        vertical
        class="flex-column"
        borderless
        group
        mandatory
        tile
        v-model="nav.selected"
      >
        <v-tooltip v-for="(item, i) in nav.btn" :key="i" right>
          <template #activator="{ on, attrs }">
            <v-btn
              :value="item"
              class="ma-0"
              depressed
              width="56px"
              height="56px"
              v-on="on"
              v-bind="attrs"
              @click.prevent="
                () => {
                  mini = false;
                  content = item.content;
                }
              "
            >
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-btn>
          </template>
          {{ item.tooltip }}
        </v-tooltip>
      </v-btn-toggle>
      <v-spacer />
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('refresh')"
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </template>
        aktualisieren
      </v-tooltip>
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('save')"
          >
            <v-icon> mdi-content-save-outline </v-icon>
          </v-btn>
        </template>
        speichern
      </v-tooltip>
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('expand')"
          >
            <v-icon> mdi-expand-all-outline </v-icon>
          </v-btn>
        </template>
        aufklappen
      </v-tooltip>
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('collapse')"
          >
            <v-icon> mdi-collapse-all-outline </v-icon>
          </v-btn>
        </template>
        einklappen
      </v-tooltip>
      <v-divider />
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="_compact = !_compact"
          >
            <v-icon>mdi-sitemap-outline </v-icon>
          </v-btn>
        </template>
        vereinfachen
      </v-tooltip>
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('center')"
          >
            <v-icon>mdi-fit-to-screen-outline</v-icon>
          </v-btn>
        </template>
        zentrieren
      </v-tooltip>
      <o-icon-select-btn
        v-model="_layout"
        :items="toolbar.layouts.items"
        depressed
        height="56px"
        :menu-props="{
          offsetX: true,
          rounded: 'lg',
        }"
        tooltip="Orientierung"
      />
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('zoomIn')"
          >
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
        </template>
        vergrößern
      </v-tooltip>
      <v-tooltip right open-delay="500">
        <template #activator="{ on, attrs }">
          <v-btn
            depressed
            height="56px"
            v-on="on"
            v-bind="attrs"
            @click.prevent.stop="$emit('zoomOut')"
          >
            <v-icon>mdi-magnify-minus-outline </v-icon>
          </v-btn>
        </template>
        verkleineren
      </v-tooltip>
      <span class="text--vertical-toolbar"> {{ _zoomLevel + "%" }} </span>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import OIconSelectBtn from "@/common/components/OIconSelectBtn.vue";
import OChartTreeview from "./OChartTreeview.vue";
import OChartReportGroupTreeview from "./OChartReportGroupTreeview.vue";
import OChartColorGroupTable from "./OChartColorGroupTable.vue";

export default {
  name: "o-chart-drawer",
  components: { OIconSelectBtn },
  props: {
    graph: {
      type: Array,
    },
    colorGroups: {
      type: Array,
    },
    reportingGroups: {
      type: Array,
    },
    reportingCategories: {
      type: Array,
    },
    config: {
      type: Object,
    },
    zoom: {
      type: Number,
    },
    active: {
      type: [String, Number],
    },
  },
  data() {
    return {
      show: true,
      mini: true,
      nav: {
        width: "512px",
        selected: null,
        btn: [
          {
            icon: "mdi-family-tree",
            content: OChartTreeview,
            tooltip: "Organisation",
          },
          {
            icon: "mdi-chart-box-outline",
            content: OChartReportGroupTreeview,
            tooltip: "Kategorien",
          },
          {
            icon: "mdi-palette",
            content: OChartColorGroupTable,
            tooltip: "Farbgruppen",
          },
        ],
      },
      toolbar: {
        layouts: {
          items: [
            "mdi-format-vertical-align-bottom",
            "mdi-format-horizontal-align-right",
            "mdi-format-vertical-align-top",
            "mdi-format-horizontal-align-left",
          ],
        },
      },
    };
  },
  computed: {
    _layout: {
      get() {
        return this.config.layout;
      },
      set(value) {
        const updated = { ...this.config, layout: value };
        this.$emit("update:config", updated);
      },
    },
    _compact: {
      get() {
        return this.config.compact;
      },
      set(value) {
        const updated = { ...this.config, compact: value };
        this.$emit("update:config", updated);
      },
    },
    _zoomLevel() {
      return Math.trunc(this.zoom * 100);
    },
  },
};
</script>

<style scoped>
.v-btn--vertical-toolbar {
  display: flex;
  min-width: 100% !important;
  max-width: none;
}
.text--vertical-toolbar {
  display: flex;
  height: 56px;
  margin: auto;
  align-items: center;
  justify-content: center;
}
</style>
