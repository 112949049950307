<template>
  <div>
    <h2 class="pb-6">Theme wechseln</h2>
    <div>
      <h3 class="mb-3">Theme</h3>
        <div data-tooltip="Achtung: Die Änderung wird auch für die Umfragen wirksam!">
          <v-row justify="center" align="center">
              <v-col>
                Hiermit können Sie das Aussehen der Anwendung anpassen:
              </v-col>
                <v-col cols="auto">
                    <o-change-theme-switch />
                </v-col>
          </v-row>
      </div>
    </div>
    <v-divider class="my-6" />
  </div>
</template>



<script>
import OChangeThemeSwitch from "@/modules/user/settings/components/OChangeThemeSwitch.vue";

export default {
  components: { OChangeThemeSwitch },
  name: "o-application-settings-view",
  mounted() {
    this.addTooltipStyles();
  },
  methods: {
    addTooltipStyles() {
      const style = document.createElement('style');
      style.innerHTML = `
        [data-tooltip] {
          position: relative;
          cursor: pointer;
        }

        [data-tooltip]::after {
          content: attr(data-tooltip);
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          background-color: #333;
          color: #fff;
          padding: 5px;
          border-radius: 3px;
          white-space: nowrap;
          font-size: 12px;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.3s;
        }

        [data-tooltip]:hover::after,
        [data-tooltip]:focus::after {
          opacity: 1;
        }
      `;
      document.body.appendChild(style);
    }
  }
};

/*
export default {
  components: { OChangeThemeSwitch },
  name: "o-application-settings-view",
};
*/

</script>

<style></style>
