import { companyService } from "@/services";

const state = {
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  options: {}
};

const actions = {
  async getAll({ commit, dispatch, getters }, options) {
    try {
      commit("loading");
      const opt = options ?? getters.options;
      const response = await companyService.getAll(opt);
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });
      return response;
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async get({ dispatch }, id) {
    try {
      const result = await companyService.get(id);
      return Promise.resolve(result);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async update({ dispatch }, data) {
    // console.log("update company: ", data);
    try {
      await companyService.update(data.company_id, data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `Organisation ${data.name} wurde erfolgreich aktualisiert!`,
        { root: true }
      );
      dispatch("account/updateStatus", null, { root: true });
      return Promise.resolve();
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },
  async create({ dispatch }, data) {
    try {
      await companyService.create(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `Organisation ${data.name} wurde erfolgreich erstellt!`,
        { root: true }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
    }
  },
  async delete({ dispatch }, data) {
    try {
      // console.log("delete company id: ", data.company_id);
      await companyService.delete(data.company_id);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `Organisation ${data.name} wurde gelöscht!`,
        { root: true }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async reset({ dispatch }, data) {
    try {
      await companyService.reset(data.company_id);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `Organisation ${data.name} wird zurückgesetzt!`,
        { root: true }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async regenerate({ dispatch }, data) {
    try {
      await companyService.regenerate(data.company_id);
      dispatch("getAll");
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async updateOptions({ commit, dispatch }, options) {
    commit("setOptions", options);
    dispatch("getAll");
  }
};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, error) {
    state.isLoading = false;
    state.error = error;
  },
  setOptions(state, options) {
    state.options = options;
  }
};

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  options: (state) => state.options,
};

export const company = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
