<template>
  <o-loader getter="questions/all" :fetchParam="this.user.company_id">
    <template v-slot:default="{ value }">
      <v-treeview
        v-model="_value"
        :items="value"
        item-children="subcategories"
        selectable
        hoverable
        rounded
        selected-color="primary"
        open-on-click
        :disabled="isItemDisabled"
      >
        <template v-slot:label="{ item, leaf }">
          <v-card flat color="transparent">
            <v-card-title>
              <v-row align="center" align-content="center" justify="center">
                <v-col cols="auto">
                  <v-sheet
                    class="pa-1"
                    width="15px"
                    :color="item.colorcode ?? 'success'"
                    rounded="xl"
                  />
                </v-col>
                <v-col cols="auto">
                  {{ item.name }}
                </v-col>
                <v-spacer />
                <v-col cols="auto" v-if="!leaf">
                  {{ `(${selectedCount(item)}/${item.subcategories.length})` }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text v-if="item.description">
              {{ item.description }}
            </v-card-text>
          </v-card>
        </template>
        <template v-slot:append="{ item, selected }">
          <v-checkbox
            v-if="item.questioncount_long > 0"
            v-model="_long"
            :value="item.id"
            :disabled="!selected"
            label="Lange Version"
          />
        </template>
      </v-treeview>
    </template>
  </o-loader>
</template>

<script>
import { mapGetters } from "vuex";
import OLoader from "@/common/components/OLoader.vue";

export default {
  name: "o-categorie-treeview",
  components: { OLoader },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    long: {
      type: Array,
      default: () => [],
    },
    editallowed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _value: {
      get() {
        // console.log("!!! this value: ", this.value);
        // console.log("Type of this.value:", typeof this.value);
        // console.log("Is this.value an array?", Array.isArray(this.value));
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters("account", ["user"]),
    _long: {
      get() {
        return this.long;
      },
      set(value) {
        this.$emit("update:long", value);
      },
    },
    isItemDisabled() {
      return !this.editallowed;
    },
  },
  methods: {
    selectedCount(categorie) {
      return categorie.subcategories.filter((cat) =>
        this._value.includes(cat.id)
      ).length;
    },
  },
  
};
</script>

<style scoped></style>
