<template>
  <div>
    <v-navigation-drawer v-model="_drawer" app right clipped bottom width="350">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-title class="font-weight-medium">
            Kategorien
          </v-list-item-title>
        </v-list-item>
        <v-divider />
      </template>
      <v-list rounded class="px-4" dense>
        <v-list-item-group v-model="_step" mandatory>
          <o-poll-stepper-step
            v-for="(subcategory, i) in _questionnarie.subcategories"
            :key="i"
            :value="subcategory"
            :index="i + 1"
            :disabled="i > progress"
            :completed="progress > i"
            :selected="_step == i"
          />
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <o-center class="py-8" style="width: 100vw">
      <v-window :value="_step" :touchless="!_valid" style="max-width: 1180px">
        <v-window-item
          v-for="(subcategory, i) in _questionnarie.subcategories"
          :key="i"
          :value="i"
        >
          <o-poll-subcategory-item
            :value="subcategory"
            :valid.sync="valid[i]"
          />
        </v-window-item>
      </v-window>
    </o-center>
    <v-footer app outlined>
      <v-container>
        <v-row>
          <v-col cols="auto">
            <v-btn rounded x-large depressed text left @click="back">
              <v-icon>mdi-chevron-left</v-icon>
              Zurück
            </v-btn>
          </v-col>
          <v-col></v-col>
          <v-col cols="auto">
            <v-btn
              rounded
              x-large
              depressed
              color="primary"
              right
              :disabled="!_valid"
              @click="next"
            >
              {{ _isLastSubcategorie ? "Umfrage Beenden" : "Weiter" }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import OCenter from "@/common/components/OCenter.vue";
import OPollSubcategoryItem from "./OPollSubcategoryItem.vue";
import OPollStepperStep from "./OPollStepperStep.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "o-poll-questionnaire",
  components: {
    OPollSubcategoryItem,
    OPollStepperStep,
    OCenter,
  },
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 0,
      progress: 0,
      valid: Array(this.value.questionnarie.subcategories.length).fill(false),
    };
  },
  mounted() {
    this.progress =
      this.value.questionnarie.last_subcategory_index_activity ?? 0;
    this.step = this.progress;
  },
  methods: {
    back() {
      if (this._isFirstSubcategorie) {
        this.$emit("back");
        return;
      }

      this._step--;
    },
    next() {
      if (this._isLastSubcategorie) {
        this.$emit("next");
        this.checkout({
          phash: this.checkin.value.poll_hash,
          shash: this.checkin.value.subject_hash,
        });
        return;
      }

      this._step++;
    },
    print(msg) {
      // console.log(msg);
    },
    ...mapActions("poll", ["checkout"]),
  },
  computed: {
    _questionnarie() {
      return this.value.questionnarie;
    },
    _subcategory() {
      return this._questionnarie.subcategories[this.step];
    },
    _step: {
      get() {
        return this.step;
      },
      set(value) {
        this.step = this.$utils.math.clamp(
          value,
          0,
          this._questionnarie.subcategories.length - 1
        );
        this.progress = Math.max(this.progress, this.step);
        window.scrollTo(0, 0);
      },
    },
    _progress() {
      return (this.progress / this._questionnarie.subcategories.length) * 100;
    },
    _valid() {
      return this.valid[this._step];
    },
    _isFirstSubcategorie() {
      return this.step === 0;
    },
    _isLastSubcategorie() {
      return this.step === this._questionnarie.subcategories.length - 1;
    },
    _drawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },
    ...mapGetters("poll", ["checkin"]),
  },
};
</script>

<style scoped></style>
