import * as jose from "jose";

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtHm/bGqVW/HKTIUKlTOo
2CceApXjvlHQwPzYu4yvHUPLmonEuo1amyUXCa8QuRJDGB5Rgh1PMl9kHk87k+wV
o2WPExKvYV098XA1Ty6mqWT7cuQxelbp9ZTgccZegc80lhTs4Egjq7vnMIF8K9+D
Kn4NOhSfh9NzC7ML8fpGCjkUr+vSIEQX6RP/pW1BUhsfR9JTf0RCIfxaiZPHCam6
OzQEJpgRhcyWaEUFEjGHnrWBu3LKkpdZ6NE/j50fzP7Aaufs0MNeluv1gGgKoA8h
VnH2e4ajy3r5DeaZPCFJUsmv4K6HRZ1Wqoj9ZPm5tG1YetPSVPB3i6BQUDqL5+w3
OQIDAQAB
-----END PUBLIC KEY-----`;

const OPTIONS = {
  algorithms: ["RS256"],
  clockTolerance: "0",
  ignoreExpiration: false,
  // maxAge: "2 days",
};

export const jwtService = {
  set,
  get,
  remove,
  header,
  role,
  id,
  session_id,
};



async function verify(token) {
  try {
    const publicKey = await jose.importSPKI(PUBLIC_KEY, "RS256");
    const { payload } = await jose.jwtVerify(token, publicKey, {
      algorithms: ["RS256"],
      clockTolerance: 1,
    });

    return { valid: true, payload }
  } catch (error) {
    return { valid: false, };
  }
}

async function set(token) {
  const { valid } = await verify(token);
  if (!valid) return false

  sessionStorage.setItem("jwt", JSON.stringify({ token }));
  return true;
}

async function get() {
  const jwt = JSON.parse(sessionStorage.getItem("jwt"));
  if (!jwt || !jwt.token) return { valid: false };

  const { valid, payload } = await verify(jwt.token);

  return { valid, token: jwt.token, payload };
}

function remove() {
  sessionStorage.removeItem("jwt");
}

async function header() {
  const { token } = await get();
  return token ? { Authorization: "Bearer " + token } : {};
}

async function role() {
  const { valid, payload } = await get();
  return valid ? payload.user_role : "guest";
}

async function id() {
  const { valid, payload } = await get();
  return valid ? payload.user_id : -1;
}

async function session_id() {
  const { valid, payload } = await get();
  return valid ? payload.session_id : "471147124713";
}
