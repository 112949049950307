<template>
  <div style="width: 100%; height: 100%">
    <slot name="body" v-bind="{ item }">
      <slot name="error" v-if="status === 3" v-bind:error="item.error">
        <o-center height="100vh">
          <o-error-card
            :status="item.error.status"
            :statusText="item.error.statusText"
            :message="item.error.message"
          />
        </o-center>
      </slot>
      <slot name="loading" v-else-if="status === 1">
        <o-center style="height: 100vh">
          <v-progress-circular size="56" color="primary" indeterminate />
        </o-center>
      </slot>
      <slot
        v-else-if="status === 2"
        v-bind:value="item.value"
        v-bind:item="item"
      ></slot>
    </slot>
  </div>
</template>

<script>
import OCenter from "./OCenter.vue";
import OErrorCard from "./OErrorCard.vue";

export default {
  components: { OCenter, OErrorCard },
  name: "o-loader",
  props: {
    value: {
      type: Object,
    },
    getter: {
      type: String,
      required: true,
    },
    getterParam: {},
    fetchParam: {},
    reload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStatus: -1,
    };
  },
  mounted() {
    this.load(this.reload);
  },
  methods: {
    existGetter(name) {
      return (
        Object.keys(this.$store.getters).findIndex((key) => key === name) !== -1
      );
    },
    load(reload = false) {
      this.item?.load(this.$store, this.fetchParam, reload);
    },
    setStatus(value) {
      this.currentStatus = value;
    },
  },
  watch: {
    reload(value) {
      if(value == false) return;
      this.load(true);
      this.$emit("update:reload", false);
    },
  },
  computed: {
    item() {
      if (!this.getter || !this.existGetter(this.getter)) {
        console.warn(
          `[o-loader]: getter ${this.getter} is undefined or does not exit!`
        );
        return;
      }

      if (this.getterParam === undefined || this.getterParam === null) {
        const result = this.$store.getters[this.getter];
        this.$emit("input", result.value);
        return result;
      }

      let _param = this.getterParam;
      if (typeof _param === "function") {
        _param = _param();
      }

      const result = this.$store.getters[this.getter](_param);
      this.$emit("input", result.value);
      return result;
    },
    status() {
      const status = this.item.status;
      if (status !== this.currentStatus) {
        this.setStatus(status);
        switch (status) {
          case 0:
            this.$emit("isEmpty", this.item);
            break;
          case 1:
            this.$emit("isLoading", this.item);
            break;
          case 2:
            this.$emit("isLoaded", this.item);
            break;
          case 3:
            this.$emit("isFailure", this.item);
            break;
        }
        this.$emit("statusChanged", { status, item: this.item });
      }

      return status;
    },
  },
};
</script>

<style></style>
