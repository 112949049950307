<template>
  <div>
    <input
      ref="file"
      hidden
      type="file"
      @change="handleFileChange"
      :accept="_accept"
    />
    <v-btn
      v-bind="{ ...$attrs, ...$props }"
      v-on="$listeners"
      @click.prevent="click"
    >
      <slot>Durchsuchen</slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "o-file-input-btn",
  props: {
    accept: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    click() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    handleFileChange(e) {
      this.$emit("change", e.target.files[0]);
    },
  },
  computed: {
    _accept() {
      return this.accept.reduce((value, elem) => {
        return value + `${elem}, `;
      }, "");
    },
  },
};
</script>

<style scoped>
.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: #2ea169;

  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}

.hide-file-input {
  display: none;
}
</style>
