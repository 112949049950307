<template>
  <v-navigation-drawer
    :value="show"
    absolute
    right
    overlay-opacity="0.5"
    :hide-overlay="false"
    style="z-index: 5"
    width="768px"
    height="100%"
  >
    <template v-slot:prepend>
      <v-toolbar dense flat>
        <v-icon size="26">mdi-pencil</v-icon>
        <v-toolbar-title v-if="show" class="pl-2">
          <span v-if="value.reporting_id">Auswertung bearbeiten (ID {{ value.reporting_id }})</span>
          <span v-if="!value.reporting_id">Auswertung erstellen</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-divider />
    </template>
    <template v-slot:append>
      <v-divider />
      <v-toolbar flat>
        <v-btn text color="error" @click.prevent.stop="close">
          <v-icon left>mdi-cancel</v-icon>
          Abbruch
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          :disabled="!valid"
          @click.prevent.stop="save"
        >
          Speichern
          <v-icon right> mdi-checkbox-marked-circle </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <div v-if="show">
      <v-form v-model="valid">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Beschreibung
            </v-list-item-title>
            <v-container fluid>
              <v-text-field
                v-model="item.name"
                rounded
                filled
                label="Name*"
                :rules="[
                  v => !!v || 'Pflichtfeld',
                  v => v.length <= 22 || 'Maximal 22 Zeichen erlaubt'
                ]"
                counter="22"
              />
              <v-textarea
                v-model="item.description"
                rounded
                filled
                label="Kommentar"
                auto-grow
                counter="256"
              />
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Welche Umfrage soll ausgewertet werden?
            </v-list-item-title>
            <v-container fluid>
              <o-survey-select
                v-model="item.survey_id"
                :disabled="!isSelectable"
                :rules="[(v) => !!v]"
              />
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item v-if="orgacheck2" >
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Soll die Auswertung Vergleichswerte beinhalten?
            </v-list-item-title>
              <v-radio-group
                v-model="item.reporting_reference"
                row
                mandatory
                :disabled="!isSelectable"
              >
                <v-radio
                  label="keine"
                  value="none"
                ></v-radio>  
                <v-radio
                  label="Organisation"
                  value="company"
                ></v-radio>
                <v-radio
                  label="Normierung"
                  value="norm"
                ></v-radio>
                <v-radio
                  label="anderer Bericht"
                  value="rule"
                ></v-radio>
                
             </v-radio-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item >
          <v-list-item-content>
            <v-container fluid>
              <o-rule-select
                v-if="item.reporting_reference === 'rule'"
                v-model="item.reference_rule_id"
                :disabled="!isSelectable"
                :rules="[(v) => !!v]"
              />
            </v-container>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-form>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { OSurveySelect } from "@/modules/company/surveys";
import  ORuleSelect  from "./ORuleSelect.vue";

export default {
  components: { OSurveySelect, ORuleSelect },
  name: "o-reportings-edit-drawer",
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      item: null,
      valid: false,
    };
  },
  // mounted() {
  //   this.item.reference_rulelocator = "hallo";
  // },
  methods: {
    async save() {
      try {
        var handler = !this.value.reporting_id
          ? this.createReporting
          : this.updateReporting;

        await handler(this.value);
        // const reporting_id = await handler(this.value);
        // console.log("🚀 ~ save ~ reporting_id:", reporting_id);

        this.close();
      } catch (error) {
        console.warn(error);
      }
    },
    close() {
      this.$emit("input", null);
    },
    updateValue(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    ...mapActions("reportings", {
      createReporting: "create",
      updateReporting: "update",
    }),
  },
  computed: {
    _reporting_reference: {
      get() {
        // return this.item.reporting_reference;
        return this.item._reporting_reference === "rule" ? "rule" : null;
      },
      set(value) {
        this.item.reporting_reference = value;
      },
    },
    orgacheck2() {
      if (!this.user.orgagroup_id) {
        return false;
      } else {
        if (this.user.orgagroup_id == 2) {
          return true;
        }
        return false;
      }
    },
    _numericReferenceRuleId: {
      get() {
        return this.item.reference_rule_id ? parseInt(this.item.reference_rule_id, 10) : null;
      },
      set(value) {
        this.item.reference_rule_id = value;
      }
    },
    show() {
      return this.item !== null && this.item !== undefined;
    },
    isSelectable() {
      return !this.item.reporting_id;
    },
    ...mapGetters("account", ["companyId"]),
    ...mapGetters("account", ["user"]),
  },
  watch: {
    value(value) {
      this.item = value;
      if (!this.item) return;

      if (!this.item.company_id) {
        this.item.company_id = this.companyId;
      }
    },
  },
};
</script>

<style></style>
