import Vue from "vue";

export const STATUS = {
  empty: 0,
  loading: 1,
  loaded: 2,
  error: 3,
};

export class ApiMap {
  constructor(initValue = null, action) {
    this.values = {}
    this.error = null;
    this.action = action;
    this.initValue = initValue;
  }

  get(key) {
    if (!this.values[key]) {
      Vue.set(this.values, key, new ApiValue(this.initValue, this.action));
    }

    return this.values[key];
  }

  success(key, value) {
    this.get(key).success(value);
  }

  failure(key, error) {
    this.get(key).failure(error);
  }

  load(key, store, param = null, reload = false) {
    this.get(key).load(store, param, reload);
  }

  isEmpty(key) {
    return this.get(key).status === STATUS.empty;
  }

  isLoading(key) {
    return this.get(key).status === STATUS.loading;
  }

  isLoaded(key) {
    return this.get(key).status === STATUS.loaded;
  }

  isFailure(key) {
    return this.get(key).status === STATUS.error;
  }
}

export class ApiValue {
  constructor(initValue = null, action) {
    this.initValue = initValue;
    this.status = STATUS.empty;
    this.value = initValue;
    this.error = null;
    this.action = action;
  }

  loading() {
    this.status = STATUS.loaded;
    this.value = this.initValue;
  }

  success(value) {
    this.status = STATUS.loaded;
    this.value = value;
  }

  failure(error) {
    this.status = STATUS.error;
    this.error = error;
  }

  clear(clearValue = null) {
    this.status = STATUS.empty;
    this.value = clearValue;
  }

  load(store, param = null, reload = false) {
    /* if (!this.action || (this.isLoaded() && !reload) || this.isLoading())
      return; */

    if (!this.action || this.isLoading())
      return;

    const existAction =
      Object.keys(store._actions).findIndex((key) => key === this.action) !==
      -1;

    if (!existAction) return;

    this.status = STATUS.loading;

    if (!param === undefined || param === null) {
      store.dispatch(this.action, {
        root: true,
      });

      return;
    }

    let _param = param;

    if (typeof _param === "function") {
      _param = _param();
    }

    store.dispatch(this.action, _param, {
      root: true,
    });
  }

  isEmpty() {
    return this.status === STATUS.empty;
  }

  isLoading() {
    return this.status === STATUS.loading;
  }

  isLoaded() {
    return this.status === STATUS.loaded;
  }

  isFailure() {
    return this.status === STATUS.error;
  }
}
