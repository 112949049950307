<template>
  <v-dialog v-model="show" max-width="384px" persistent>
    <v-form>
      <v-card rounded="xl">
        <v-card-title> Neue Teilnehmerschlüssel generieren </v-card-title>
        <v-card-text>
          <p>
            Wie viele neue Schlüssel sollen für Umfrage {{ this.name }} generiert werden?
          </p>
          <v-text-field
            v-model.number="_count"
            rounded
            filled
            persistent-hint
            label="Anzahl"
            placeholder="666"
            :hint="`Es können maximal ${max} Schüssel erzeugt werden.`"
            type="number"
            suffix="Schlüssel"
            prepend-inner-icon="mdi-account-key"
            :min="min"
            :max="max"
          />
        </v-card-text>
        <v-card-actions style="display: block">
          <v-btn
            block
            depressed
            rounded
            color="success"
            @click.prevent="generate"
            type="submit"
          >
            Generieren
          </v-btn>
          <v-btn
            block
            text
            rounded
            color="error"
            class="ml-0 mt-1"
            @click.prevent="show = false"
          >
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "o-subject-generate-dialog",
  props: {
    value: {
      type: Boolean,
    },
    survey_id: {
      type: String,
    },
    company_id: {
      type: String,
    },
    name: {
      type: String,
      default: "hallo",
    },
    override: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      min: 1,
      max: 2000,
      count: 1,
    };
  },
  methods: {
    async generate() {
      // console.log("generate: ", this.survey_id, this.company_id, this.name, this.count);

      const promise = this.newgenerateSubjectKeys({
        survey_id: this.survey_id,
        company_id: this.company_id,
        name: this.name,
        count: this.count
      });

      this.showProcess({
        text: this.count + " Teilnehmerschlüssel werden für Umfrage " + this.name + " generiert",
        promise,
      });
      this.show = false;

      // alles was nach dem await steht wird erst ausgeführt, wenn die promise fertig ist
      // d.h. also auch das Neuladen der Subjects in SubjectsManager!
      let temp = await promise;

      // Emit the event after generation is complete, Bescheid geben, dass die Generation abgeschlossen ist
      this.$emit('generation-complete');
    },
    ...mapActions("subjects", ["newgenerateSubjectKeys"]),
    ...mapActions("alert", ["showProcess"]),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    _count: {
      get() {
        return this.count;
      },
      set(value) {
        this.count = this.$utils.math.clamp(value, this.min, this.max);
      },
    },
  },
};
</script>

<style></style>
