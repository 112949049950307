<template>
  <div class="fill-height" v-if="this.user">
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "o-account",
  mounted() {
    this.updateStatus();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...mapActions("account", ["updateStatus"]),
  },
};
</script>

<style></style>
