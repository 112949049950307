import { ApiValue } from "@/common/helpers";
import { pollService } from "@/services";

const state = {
  checkin: new ApiValue(null, "poll/checkin"),
};

const actions = {
  checkin({ commit, dispatch }, data) {
    pollService.checkin(data).then(
      (d) => {
        commit("getSuccess", d);
      },
      (error) => {
        commit("getFailure", error);
        dispatch("alert/showAlertError", {
          text: `${error.status} ${error.statusText}: ${error.message}`,
        }, { root: true });
      }
    );
  },
  checkout({ dispatch }, data) {
    pollService.checkout(data).then(
      () => { },
      (error) => {
        dispatch("alert/showAlertError", {
          text: `${error.status} ${error.statusText}: ${error.message}`,
        }, { root: true });
      }
    );
  },
  updateNode({ dispatch }, data) {
    pollService.updateNode(data).then(
      () => { },
      (error) => {
        dispatch("alert/showAlertError", {
          text: `${error.status} ${error.statusText}: ${error.message}`,
        }, { root: true });
      }
    );
  },
  updateAnswer({ dispatch }, data) {
    pollService.updateAnswer(data).then(
      (d) => { },
      (error) => {
        dispatch("alert/showAlertError", {
          text: `${error.status} ${error.statusText}: ${error.message}`,
        }, { root: true });
      }
    );
  },
};

const mutations = {
  getSuccess(state, data) {
    state.checkin.success(data);
  },
  getFailure(state, err) {
    state.checkin.failure(err);
  },
};

const getters = {
  checkin: (state) => state.checkin,
};

export const poll = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
