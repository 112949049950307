<template>
  <v-navigation-drawer
    absolute
    right
    outlined
    temporary
    v-model="_show"
    width="384px"
  >
    <template v-slot:prepend>
      <v-toolbar flat>
        <v-btn icon @click.prevent="_show = false">
          <v-icon> mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>Benachrichtigungen</v-toolbar-title>
        <v-spacer />
        <v-tooltip open-delay="500" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click.prevent="clearAlerts" v-on="on" v-bind="attrs">
              <v-icon> mdi-notification-clear-all </v-icon>
            </v-btn>
          </template>
          Benachrichtigungen Leeren
        </v-tooltip>
        <template #extension>
          <v-tabs fixed-tabs>
            <v-tab>Alle</v-tab>
            <v-tab>Aktivitäten</v-tab>
            <v-tab>System</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-divider />
    </template>
    <o-alert-list :value="['all']" />
    <!--  <v-list class="py-0">
      <template v-for="(item, key) in alertsByDate">
        <v-subheader :key="`subheader_${key}`">
          <o-date-time :value="key" :show-time="false" />
        </v-subheader>
        <div :key="`section_${key}`">
          <v-slide-x-transition
            :key="`item_${index}`"
            v-for="(item, index) in item"
            class="pb-4"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-row no-gutters align="center">
                  <v-col cols="10">
                    <o-alert-item :value="item" />
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <v-fade-transition>
                      <v-btn
                        icon
                        @click.prevent="removeAlert(index)"
                        v-show="hover"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </template>
            </v-hover>
          </v-slide-x-transition>
        </div>
      </template>
    </v-list> -->
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import OAlertList from "./OAlertList.vue";

export default {
  name: "o-alert-drawer",
  components: {
    OAlertList,
  },
  methods: {
    ...mapActions("alert", [
      "clearAlerts",
      "setShowAlertDrawer",
      "removeAlert",
    ]),
  },
  computed: {
    _show: {
      get() {
        return this.showAlertDrawer;
      },
      set(value) {
        this.setShowAlertDrawer(value);
      },
    },
    ...mapGetters("alert", ["showAlertDrawer", "alertsByDate"]),
  },
};
</script>

<style></style>
