import { surveysService } from "@/services";
import { csvService } from "@/services";

const state = {
  items: [],
  totalItems: 0,
  isLoading: false,
  error: null,
  options: {},
};

const actions = {
  async getAll({ commit, dispatch, rootGetters, getters }, options) {
    try {
      commit("loading");
      const company_id = rootGetters["account/companyId"];
      if (company_id === -1) throw new Error("CompanyId could not be found!");
      const opt = options ?? getters.options;

      const response = await surveysService.getAll({ company_id, options: opt });
      // console.log("Surveys response: ", response);  
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });

      return response;
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async getAllArchive({ commit, dispatch, rootGetters, getters }, options) {
    try {
      commit("loading");
      const company_id = rootGetters["account/companyId"];
      if (company_id === -1) throw new Error("CompanyId could not be found!");
      const opt = options ?? getters.options;

      const response = await surveysService.getAllArchive({ company_id, options: opt });
      // console.log("Surveys archive response: ", response);  
      commit("success", {
        items: response.items,
        totalItems: response.totalItems,
      });

      return response;
    } catch (error) {
      commit("failure", error);
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },
  async create({ dispatch }, data) {
    try {
      // console.log(data);
      const response = await surveysService.create(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `Umfrage ${data.name} wurde erfolgreich angelegt!`,
        { root: true }
      );

      return response;
    } catch (error) {

      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async update({ dispatch }, data) {
    try {
      const response = await surveysService.update(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `Umfrage ${data.name} wurde erfolgreich aktualisiert!`,
        { root: true }
      );

      return response;
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return error;
    }
  },

  async delete({ dispatch }, data) {
    try {
      await surveysService.delete(data);
      dispatch("getAllArchive");
      // Message erfolgt bereits in der Komponente!
      // dispatch("alert/showAlertSuccess", "Umfrage wurde gelöscht!", {
      //   root: true,
      // });
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async reset({ dispatch }, data) {
    try {
      await surveysService.reset(data);
      dispatch("getAll");
      // Message erfolgt bereits in der Komponente!
      // dispatch(
      //   "alert/showAlertSuccess",
      //   `Umfrage ${data.name} und Teilnehmerstati werden zurückgesetzt ...`,
      //   {
      //     root: true,
      //   }
      // );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async archive({ dispatch }, data) {
    try {
      await surveysService.archive(data);
      dispatch("getAll");
      // Message erfolgt bereits in der Komponente!
      // dispatch(
      //   "alert/showAlertSuccess",
      //   `Umfrage ${data.name} wurde archiviert!`,
      //   {
      //     root: true,
      //   }
      // );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async generateSurvey({ dispatch }, data) {
    try {
      await surveysService.generateSurvey(data);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        "Freie Teilnehmer und Antworten wurden mit Testdaten belegt!",
        {
          root: true,
        }
      );
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async exportSurvey({ dispatch }, item) {
    
    function sanitizeFilenameSuffix(suffix) {
      return suffix.replace(/[^a-zA-Z0-9]/g, '');
    }
    
    try {
      const suffix = item.name || "export";
      const sanitizedSuffix = sanitizeFilenameSuffix(suffix);
      const exportFilename = `orgacheck-data-${sanitizedSuffix}.csv` || "export.csv"; // eslint-disable-line

      const rawdata = await surveysService.exportSurvey(item.survey_id);
      const blob = new Blob([rawdata], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportFilename);
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", exportFilename);
      a.click();
  
      return Promise.resolve(rawdata);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async exportSubjects({ dispatch }, item) {
    
    function sanitizeFilenameSuffix(suffix) {
      return suffix.replace(/[^a-zA-Z0-9]/g, '');
    }
    
    try {
      const suffix = item.name || "export";
      const sanitizedSuffix = sanitizeFilenameSuffix(suffix);
      const exportFilename = `orgacheck-teilnehmerlinks-${sanitizedSuffix}.csv` || "export.csv"; // eslint-disable-line

      const rawdata = await surveysService.exportSubjects(item.survey_id);
      const blob = new Blob([rawdata], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportFilename);
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", exportFilename);
      a.click();
  
      return Promise.resolve(rawdata);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async generateSubjectKeys({ dispatch }, data) {
    try {
      const result = await surveysService.generateSubjectKeys(data.survey_id, data.count);
      dispatch("getAll");
      // await dispatch("getAll", data.company_id);
      // Message bereits in der Komponente!
      // dispatch(
      //   "alert/showAlertSuccess",
      //   `${data.count} Teilnehmerschlüssel sollen in Umfrage ${data.name} generiert werden`,
      //   {
      //     root: true,
      //   }
      // );
      return Promise.resolve(result);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      return Promise.reject(error);
    }
  },

  async deleteUnusedSubjects({ dispatch }, data) {
    try {
      const result = await surveysService.deleteUnusedSubjects(data);
      // await dispatch("getAll", data.company_id);
      dispatch("getAll");
      dispatch(
        "alert/showAlertSuccess",
        `${result.count} unbenutzte Teilnehmerschlüssel in Umfrage ${data.name} werden gelöscht`,
        {
          root: true,
        }
      );
      dispatch(
        "reportings/getAll",
        data.company_id,
        {
          root: true,
        }
      );

      return Promise.resolve(result);
    } catch (error) {
      dispatch("alert/showAlertError", {
        text: `${error.status} ${error.statusText}: ${error.message}`,
      }, { root: true });
      Promise.reject(error);
    }
  },
  
  async getSurveySeries(_, survey_id, days) {
    try {
      const data = await surveysService.getSurveySeries(survey_id, days);
      return Promise.resolve(data.series);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getOngoingSurveys(_, company_id) {
    try {
      const data = await surveysService.getOngoingSurveys(company_id);
      // console.log('Ongoing Surveys: ', data, data.surveys);
      return Promise.resolve(data.surveys);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getSurveyCount({ rootGetters }) {
    try {
      const company_id = rootGetters["account/companyId"];
      const count = await surveysService.getSurveyCount(company_id);
      return Promise.resolve(parseInt(count.n_surveys));
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async updateOptions({ commit, dispatch }, value) {
    commit("setOptions", value);
    dispatch("getAll");
  },

};

const mutations = {
  loading(state) {
    state.isLoading = true;
  },
  success(state, value) {
    state.isLoading = false;
    state.items = value.items;
    state.totalItems = value.totalItems;
  },
  failure(state, error) {
    state.isLoading = false;
    state.error = error;
  },
  setOptions(state, options) {
    state.options = options;
  },
}

const getters = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  isLoading: (state) => state.isLoading,
  options: (state) => state.options,
};

export const surveys = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
