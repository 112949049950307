<template>
  <div>
    <o-reportings-edit-drawer 
      v-model="edit.item"
    />
    <!--
    <o-tree-drawer
      v-if="tree.item != null"
      :tree_name="tree.item.name"
      :tree_id="Number(tree.item.tree_id)"
      :survey_id="Number(tree.item.survey_id)"
      :tree_type="this.tree_type"
      style="z-index: 4"
      v-model="tree.item" 
    />
    -->
    <v-data-table
      :items="items"
      item-key="reporting_id"
      :headers="table.headers"
      :server-items-length="totalItems"
      :options="options"
      :loading="isLoading"
      @update:options="updateOptions"
      show-expand
      single-expand
    >
      <template #top>
        <o-data-table-toolbar title="Auswertungen">
          <v-btn
            :disabled="edit.disabled"
            elevation="0"
            color="primary"
            @click.prevent.stop="editItem(edit.default)"
          >
            <v-icon left>mdi-plus</v-icon>
            Neue Auswertung
          </v-btn>
        </o-data-table-toolbar>
      </template>
      <template #no-data>
        <b>Keine Auswertungen vorhanden...</b>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <!-- <b>{{ item.name }}</b>-->
        <v-responsive max-width="400px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.name }}
              </v-list-item-title>
                Umfrage: {{ item.survey_name }}
              </v-list-item-content>
            </v-list-item>
        </v-responsive>
      </template>
      <template v-slot:[`item.reporting_reference`]="{ item }">
        <o-status-chip
          small
          :value="item.reporting_reference"
          :options="{
            none: {
              name: 'ohne',
              color: '#47852C',
            },
            norm: {
              name: 'Norm',
              color: '#E13568',
            },
            company: {
              name: 'Organisation',
              color: '#B3BF1D',
            },
            rule: {
              name: 'Bericht',
              color: '#E96F1E',
            },
          }"
        />
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
        <!-- <o-date-time :value="item.created_at" /> -->
      </template>
      
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              rounded
              @click.prevent.stop="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          Auswertung bearbeiten
        </v-tooltip>
        <!--
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              @click.prevent.stop="showTree(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-family-tree</v-icon>
            </v-btn>
          </template>
          Interviewverteilung von {{ item.survey_name }}
        </v-tooltip>
        -->
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <o-async-btn
              icon
              rounded
              color="error"
              :disabled="false"
              v-bind="attrs"
              v-on="on"
              :click="() => deleteItem(item)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </o-async-btn>
          </template>
          Auswertung und alle Berichte löschen
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :style="{ backgroundColor: getBackgroundColor }"></td>
        <td :colspan="headers.length" class="pb-12 pt-6">
          <o-rules-table 
            :value="item" 
            :reporting_reference="item.reporting_reference"
            :reference_rulelocator="item.reference_rulelocator"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ODataTableToolbar from "@/common/components/ODataTableToolbar.vue";
// import ODateTime from "@/common/components/ODateTime.vue";
import OReportingsEditDrawer from "./OReportingsEditDrawer.vue";
import ORulesTable from "./ORulesTable.vue";
import OAsyncBtn from "@/common/components/OAsyncBtn.vue";
import OStatusChip from "@/common/components/OStatusChip.vue";
// import OTreeDrawer from "@/common/components/OTreeDrawer.vue";

export default {
  name: "o-reportings-table",
  components: {
    ODataTableToolbar,
    // ODateTime,
    OReportingsEditDrawer,
    ORulesTable,
    OAsyncBtn,
    OStatusChip,
    // OTreeDrawer,
  },
  data() {
    return {
      tree_id: null,
      survey_id: null,
      tree_name: '',
      tree_type: "fieldstatus",
      selected: null,
      edit: {
        item: null,
        disabled: false,
      },
      tree: {
        item: null,
        disabled: false,
      },
      table: {
        expanded: [],
        headers: [
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Kommentar",
            value: "description",
            align: "start",
          },
          {
            text: "Referenz",
            value: "reporting_reference",
            align: "start",
          },
          {
            text: "Berichte",
            value: "n_rules",
            align: "start",
          },
          {
            text: "erstellt am",
            value: "created_at",
            align: "start",
          },
          {
            text: "Aktionen",
            value: "action",
            align: "end",
            sortable: false,
          },
        ],
      },
    };
  },
  mounted() {
    // console.log("ORT: ", this.items, this.totalItems);
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(date).toLocaleString('de-DE', options);
    },
    showTree(item) {
      this.tree.item = { ...item };
    },
    editItem(item) {
      this.edit.item = { ...item };
    },
    async deleteItem(item) {
      if (item === null || item === undefined) return;

      const permission = await this.awaitModal({
        title: "Löschen",
        message:
          "Wollen sie diese Auswertung löschen? Beachte: Alle untergeordneten Berichte werden auch gelöscht!",
      });

      if (permission) {
        this.deleteReporting(item);
      }
    },
    ...mapActions("modal", ["awaitModal"]),
    ...mapActions("reportings", {
      updateOptions: "updateOptions",
      deleteReporting: "delete",
    }),
  },
  computed: {
    getBackgroundColor() {
      return this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.2)";
    },
    ...mapGetters("reportings", [
      "items",
      "totalItems",
      "isLoading",
      "options",
    ]),
  },
};
</script>

<style>
/* .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
} */
</style>
