var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('o-reportings-edit-drawer',{model:{value:(_vm.edit.item),callback:function ($$v) {_vm.$set(_vm.edit, "item", $$v)},expression:"edit.item"}}),_c('v-data-table',{attrs:{"items":_vm.items,"item-key":"reporting_id","headers":_vm.table.headers,"server-items-length":_vm.totalItems,"options":_vm.options,"loading":_vm.isLoading,"show-expand":"","single-expand":""},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('o-data-table-toolbar',{attrs:{"title":"Auswertungen"}},[_c('v-btn',{attrs:{"disabled":_vm.edit.disabled,"elevation":"0","color":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.editItem(_vm.edit.default)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Neue Auswertung ")],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('b',[_vm._v("Keine Auswertungen vorhanden...")])]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('v-responsive',{attrs:{"max-width":"400px"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),_vm._v(" Umfrage: "+_vm._s(item.survey_name)+" ")],1)],1)],1)]}},{key:`item.reporting_reference`,fn:function({ item }){return [_c('o-status-chip',{attrs:{"small":"","value":item.reporting_reference,"options":{
          none: {
            name: 'ohne',
            color: '#47852C',
          },
          norm: {
            name: 'Norm',
            color: '#E13568',
          },
          company: {
            name: 'Organisation',
            color: '#B3BF1D',
          },
          rule: {
            name: 'Bericht',
            color: '#E96F1E',
          },
        }}})]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","rounded":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Auswertung bearbeiten ")]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":500},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('o-async-btn',_vm._g(_vm._b({attrs:{"icon":"","rounded":"","color":"error","disabled":false,"click":() => _vm.deleteItem(item)}},'o-async-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_vm._v(" Auswertung und alle Berichte löschen ")])]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{style:({ backgroundColor: _vm.getBackgroundColor })}),_c('td',{staticClass:"pb-12 pt-6",attrs:{"colspan":headers.length}},[_c('o-rules-table',{attrs:{"value":item,"reporting_reference":item.reporting_reference,"reference_rulelocator":item.reference_rulelocator}})],1)]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }