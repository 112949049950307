<template>
  <div>
    <o-questionary-edit-drawer 
      v-model="edit.item" 
    />
    <v-data-table
      :items="items"
      :server-items-length="totalItems"
      :headers="table.headers"
      :search="table.search"
      :loading="isLoading"
      :editallowed="true"
      :options="options"
      @update:options="updateOptions"
    >
      <template #top>
        <o-data-table-toolbar title="Fragebögen" :search.sync="table.search">
          <v-btn
            depressed
            color="primary"
            @click.prevent.stop="editItem(edit.default)"
          >
            Neuer Fragebogen
          </v-btn>
        </o-data-table-toolbar>
      </template>

      <template #no-data>
        <b>Keine Fragebögen vorhanden...</b>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-responsive max-width="400px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.name }}
              </v-list-item-title>
              {{ item.description }}
            </v-list-item-content>
          </v-list-item>
        </v-responsive>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <o-date-time :value="item.created_at" />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <o-status-chip :value="item.status" :options="statusOptions" />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <o-tooltip-icon-btn
          bottom
          icon="mdi-pencil"
          @click.prevent.stop="editItem(item)"
        >
          Fragebogen bearbeiten
        </o-tooltip-icon-btn>
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <o-async-btn
              icon
              rounded
              color="error"
              tooltip="Fragebogen löschen"
              :disabled="item.status === 'active' ? true : false"
              v-bind="attrs"
              v-on="on"
              :click="() => deleteItems(item)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </o-async-btn>
          </template>
          Fragebogen löschen
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
//  :disabled="item.status === 'active' ? true : false"

import { mapActions, mapGetters } from "vuex";

import OTooltipIconBtn from "@/common/components/OTooltipIconBtn.vue";
import OAsyncBtn from "@/common/components/OAsyncBtn.vue";
import OQuestionaryEditDrawer from "./OQuestionaryEditDrawer.vue";
import ODataTableToolbar from "@/common/components/ODataTableToolbar.vue";
import OStatusChip from "@/common/components/OStatusChip.vue";
import ODateTime from "@/common/components/ODateTime.vue";

export default {
  components: {
    OTooltipIconBtn,
    OQuestionaryEditDrawer,
    OAsyncBtn,
    ODataTableToolbar,
    OStatusChip,
    ODateTime,
  },
  name: "o-questionary-table",
  props: {},
  data() {
    return {
      edit: {
        item: null,
        default: {
          company_id: null,
          name: "",
          description: "",
          short_subcategories: [],
          long_subcategories: [],
        },
      },
      table: {
        search: "",
        expanded: [],
        singleExpand: true,
        headers: [
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Status",
            value: "status",
          },
          {
            text: "Fragenanzahl",
            value: "n_questions",
          },
          {
            text: "erstellt am",
            value: "created_at",
          },
          {
            text: "Aktionen",
            value: "action",
            align: "end",
            sortable: false,
          },
        ],
      },
      statusOptions: {
        active: {
          name: "aktiv",
          color: "success",
        },
        inactive: {
          name: "inaktiv",
          color: "error",
        },
      },
    };
  },
  methods: {
    editItem(item) {
      this.edit.item = { ...item };
    },
    async deleteItems(item) {
      if (item === null || item === undefined) return;

      const permission = await this.awaitModal({
        title: "Löschen",
        message: " Wollen Sie wirklich diesen Fragebogen löschen?",
      });
      return permission && this.deleteQuestionary(item);
    },
    ...mapActions("questionary", {
      deleteQuestionary: "delete",
      updateOptions: "updateOptions",
    }),
    ...mapActions("modal", ["awaitModal"]),
  },
  computed: {
    ...mapGetters("questionary", [
      "items",
      "totalItems",
      "isLoading",
      "options",
    ]),
    ...mapGetters("account", ["user"]),
  },
};
</script>

<style>
.item {
  height: 86px;
}
.header {
  height: 86px;
}
</style>
