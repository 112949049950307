<template>
  <v-snackbar :value="visible" timeout="-1" rounded="xl" bottom right absolute>
    <div class="d-flex flex-column">
      <div
        v-for="(process, index) in processes"
        :key="index"
        class="d-flex flex-row align-center py-2"
        :style="{
          gap: '4px',
        }"
      >
        <span class="flex-grow-1">{{ process.text }}</span>
        <v-progress-circular
          class="flex-grow-0"
          indeterminate
          size="20"
          color="primary"
        />
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import { formatDistance } from "date-fns";
import de from "date-fns/locale/de";

import { mapGetters } from "vuex";
export default {
  name: "o-process-snackbar",
  methods: {
    formatDate(date) {
      return formatDistance(date, new Date(), { locale: de });
    },
  },
  computed: {
    visible() {
      return this.processes.length > 0;
    },
    ...mapGetters("alert", ["processes"]),
  },
};
</script>

<style></style>
