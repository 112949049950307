<template>
  <v-toolbar flat>
    <v-toolbar-title class="text-h5">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
   <!--  <o-search v-model="_search" dense class="mr-3" /> -->
    <slot></slot>
  </v-toolbar>
</template>

<script>
export default {
  name: "o-data-table-toolbar",
  components: {},
  props: {
    title: {
      type: String,
      default: "Toolbar-Title",
    },
    search: {
      type: String,
    },
  },
  computed: {
    _search: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
  },
};
</script>

<style></style>
