<template>
  <v-row>
    <v-col cols="auto">
      <h3>{{ interval.days }}</h3>
      <span>Tage</span>
    </v-col>
    <v-col cols="auto">
      <h3>{{ interval.hours }}</h3>
      <span>Stunden</span>
    </v-col>
    <v-col cols="auto">
      <h3>{{ interval.minutes }}</h3>
      <span>Minuten</span>
    </v-col>
  </v-row>
</template>

<script>
import { addDays, intervalToDuration, isBefore, parseISO } from "date-fns";

export default {
  name: "o-countdown",
  props: {
    value: {
      type: [Number, String],
      default: () => {
        return addDays(new Date(), 7).getTime();
      },
    },
  },
  computed: {
    interval() {
      const start = new Date();
      const end = this._value;
      const interval = {
        start,
        end: isBefore(end, start) ? start : end,
      };

      return intervalToDuration(interval);
    },
    _value() {
      if (typeof this.value === "string") {
        return parseISO(this.value);
      }

      return new Date(this.value);
    },
  },
};
</script>

<style></style>
