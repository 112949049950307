<template>
  <o-show-tree />
</template>

<script>
import { OShowTree } from "@/modules/company/showtree";
export default {
  components: { OShowTree },
  name: "o-show-tree-view",
};
</script>

<style></style>
