import { questionsService } from "@/services";
import { ApiValue } from "@/common/helpers";

const state = {
  all: new ApiValue([], "questions/getAllByCompanyId"),
};

const actions = {
  getAllByCompanyId({ commit, dispatch }, companyId) {
    questionsService.getAllByCompanyId(companyId).then(
      (data) => {
        commit("getAllSuccess", data.categories);
      },
      (err) => {
        commit("getAllFailure", err);
        dispatch("alert/failure", err, { root: true });
      }
    );
  },
};

const mutations = {
  getAllSuccess(state, all) {
    state.all.success(all);
  },
  getAllFailure(state, error) {
    state.all.failure(error);
  },
};

const getters = {
  all: (state) => state.all,
};

export const questions = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
