<template>
  <div>
    <o-app-modal />

    <o-survey-edit-drawer 
      v-model="edit.item" 
    />
    <o-tree-drawer
      v-if="tree.item != null"
      :tree_name="tree.item.name"
      :tree_id="Number(tree.item.tree_id)"
      :survey_id="Number(tree.item.survey_id)"
      style="z-index: 4"
      v-model="tree.item"
      height="100%" 
    />
    <OSurveySubjectsManager
      v-if="showSubjectsManager"
      :key="subjectsListKey" 
      :survey="selectedItem" 
      :show_subjectmanager="showSubjectsList" 
      @update:show_subjectmanager="handleClose($event)"
      @reloadOSurveyTable="reloadComponent"
    />
  
    <v-data-table
      :items="items"
      :server-items-length="totalItems"
      :loading="isLoading"
      :headers="table.headers"
      item-key="survey_id"
      :search="table.search"
      :options="options"
      @update:options="updateOptions"
      height="100%" 
      class="custom-margin-survey-table"
    >
      <template #top>
        <o-data-table-toolbar title="Umfragen" :search.sync="table.search">
          <v-btn
            :disabled="edit.disabled"
            depressed
            color="primary"
            @click.prevent.stop="startEditItem(edit.default)"
          >
            Neue Umfrage
          </v-btn>
        </o-data-table-toolbar>
      </template>
      <template #no-data>
        <b>Keine Umfragen vorhanden...</b>
      </template>
      
      <template v-slot:[`item.name`]="{ item }">
        <v-responsive max-width="400px">
          <v-tooltip bottom open-delay="450" close-delay="350">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                    Fragebogen: {{ item.questionnaire_name }}
                  </v-list-item-content>
              </v-list-item>
            </template>
              <div v-if="item.description">Kommentar: {{ item.description }}</div>
              <div v-if="item.enddate">Umfragezeitraum: {{ itemFormatDate(item.startdate) }} - {{ itemFormatDate(item.enddate) }}</div>
              <div v-if="item.applicant">Auftrageber: {{ item.applicant }}</div>
            </v-tooltip>
        </v-responsive>
      </template>
      
      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          inset
          true-value="active"
          false-value="inactive"
          :input-value="item.status"
          @change="(value) => updateSurveyStatus(item, value)"
          color="success"
        />
      </template>
      <template v-slot:[`item.applicant`]="{ item }">
        {{ item.applicant }}
      </template>
      <template v-slot:[`item.questionnaire`]="{ item }">
        {{ item.questionnaire_name }}
      </template>
      <template #[`item.accesstype`]="{ item }">
        <o-status-chip
          class="ml-2"
          :value="item.accesstype"
          :options="accesstypeOptions"
          :input-value="item"
          dark
        />
      </template>
      <template v-slot:[`item.n_base`]="{ item }">
        <o-share-indicator
          class="my-6"
          :values="[
            {
              name: 'bereit',
              color: '#FEB019',
              value: item.n_base - item.n_started - item.n_completed,
            },
            {
              name: 'begonnen',
              color: '#00E396',
              value: item.n_started,
            },
            {
              name: 'fertiggestellt',
              color: '#008FFB',
              value: item.n_completed,
            },
          ]"
        />
      </template>
      <template v-slot:[`item.enddate`]="{ item }">
        <o-date-time :value="item.enddate" />
      </template>
      <template v-slot:[`item.responserate`]="{ item }">
        <h3 v-if="item.responserate !== 'n.c.'">{{ item.responserate }} %</h3>
      </template>
      <!--  <template v-slot:[`item.activity`]="{ item }">
        <o-survey-activity-bar-chart
          :value="item.survey_id"
          :height="250"
          :width="450"
        />
      </template> -->
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              @click.prevent.stop="showTree(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-family-tree</v-icon>
            </v-btn>
          </template>
          Interviewverteilung ansehen
        </v-tooltip>
        <v-tooltip bottom :open-delay="500">
          <template v-slot:activator="{ attrs, on }">
            <v-btn 
              icon 
              @click="pollPreview(item.hash, item.subject_testhash)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-human-male-board-poll</v-icon>
            </v-btn>
          </template>
          Umfrage-Vorschau öffnen
        </v-tooltip>
        <v-menu bottom rounded="xl" offset-y left>
          <template #activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, i) in table.actions"
              :key="`item_${i}`"
              @click="action.click(item)"
              :disabled="!action.show(item)"
            >
              <v-list-item-avatar>
                <v-icon :color="action.color">
                  {{ action.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>{{ action.tooltip }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
  
</template>

<script>
import { CSVService } from "@/services";
import { mapActions, mapGetters } from "vuex";

import OSurveyEditDrawer from "./OSurveyEditDrawer.vue";
import OSurveySubjectsManager from './OSurveySubjectsManager.vue';
import OShareIndicator from "@/common/components/OShareIndicator.vue";
import ODateTime from "@/common/components/ODateTime.vue";
import ODataTableToolbar from "@/common/components/ODataTableToolbar.vue";
import OStatusChip from "@/common/components/OStatusChip.vue";
import OTreeDrawer from "@/common/components/OTreeDrawer.vue";
import { OAppModal } from "@/modules/system/modal";
// import { questionary } from "../../questionary";

export default {
  components: {
    OSurveyEditDrawer,
    OSurveySubjectsManager,
    OShareIndicator,
    ODateTime,
    ODataTableToolbar,
    OStatusChip,
    OTreeDrawer,
    OAppModal,
  },
  name: "o-survey-table",
  props: {},
  data() {
    return {
      selectedItem: null, // Beispiel: Das ausgewählte Item, das an die Kind-Komponente übergeben wird
      showSubjectsManager: true,
      showSubjectsList: false, // Beispiel: Der Status, ob die Kind-Komponente angezeigt wird
      subjectsListKey: 0, // Key für das Neurendern der Komponente
      subjects: null,
      survey_id: null,
      company_id: null,
      name: null,
      edit: {
        disabled: true,
        item: null,
        default: {
          company_id: null,
          survey_id: null,
          questionnaire_id: null,
          questionnaires: [],
          name: "",
          description: "",
          startdate: null,
          startdate_ux: null, // Date.parse(new Date(Date.now()))/1000,
          enddate: null,
          enddate_ux: null, // Date.parse(new Date(Date.now()))/1000,
          status: "inactive",
          accesstype: "closed",
        },
      },
      tree: {
        item: null,
        disabled: false,
      },
      table: {
        search: "",
        expanded: [],
        singleExpand: true,
        headers: [
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Zugang",
            value: "accesstype",
            align: "center",
          },
          {
            text: "Ende",
            value: "enddate",
          },
          {
            text: "Interviews",
            value: "n_base",
          },
          {
            text: "Responserate",
            value: "responserate",
          },
          {
            text: "Status",
            value: "status",
            
          },
          {
            text: "Aktionen",
            value: "action",
            align: "end",
            sortable: false,
          },
        ],
        actions: [
          {
            icon: "mdi-pencil",
            show: () => true,
            click: (item) => this.startEditItem(item),
            isAsync: false,
            tooltip: "Umfrage bearbeiten",
          },
          {
            icon: "mdi-account-group",
            show: () => true,
            click: (item) => 
              this.toggleSubjectsList(item),
            isAsync: false,
            tooltip: "Teilnehmer verwalten",
          },
          {
            icon: "mdi-human-male-board-poll",
            show: () => true,
            click: (item) => this.pollPreview(item.hash, item.subject_testhash),
            isAsync: false,
            tooltip: "Umfrage-Vorschau öffnen",
          },
          {
            icon: "mdi-content-copy",
            color: "",
            show: (item) =>
              item.accesstype === "open",
            click: (item) =>
              this.copyUmfrageOpenLinkClipboard(item.hash),
            isAsync: false,
            tooltip: "Umfrage-Link (open) kopieren",
          },
          {
            icon: "mdi-content-copy",
            color: "",
            show: (item) =>
              item.accesstype === "closed",
            click: (item) =>
              this.copyTestLinkClipboard(item.hash, item.subject_testhash),
            isAsync: false,
            tooltip: "Test-Link (closed) kopieren",
          },
          {
            icon: "mdi-database-refresh",
            color: "error",
            show: (item) => item.status === "inactive",
            click: this.resetItems,
            isAsync: true,
            tooltip: "Umfrage zurücksetzen",
          },
          {
            icon: "mdi-database-alert",
            color: "info",
            show: (item) => item.status === "inactive",
            click: (item) => this.generateItems(item),
            isAsync: false,
            tooltip: "Testdaten erzeugen",
          },
          {
            icon: "mdi-download",
            color: "",
            /* show: (item) => item.status === "inactive", */
            show: () => true,
            click: this.exportData,
            isAsync: true,
            tooltip: "Ergebnisse exportieren",
          },
          {
            icon: "mdi-archive-lock",
            color: "",
            show: (item) => item.status === "inactive",
            click: (item) => this.archiveItems(item),
            isAsync: false,
            tooltip: "Umfrage archivieren",
          },
        ],
      },
      statusOptions: {
        active: {
          name: "Aktiv",
          color: "success",
        },
        inactive: {
          name: "Inaktiv",
          color: "red",
        },
        onhold: {
          name: "Pausiert",
          color: "blue",
        },
      },
      accesstypeOptions: {
        closed: {
          name: "closed",
          color: "#F86624",
          icon: "mdi-lock",
          tooltip: "Teilnahme nur mit Schlüssel",
          // callback: async (item) => {
          //   await this.getAllSubjectsForItem(item);
          // },
        },
        open: {
          name: "open",
          color: "#43BCCD",
          icon: "mdi-lock-open-variant",
          tooltip: "Teilnahme ohne Schlüssel",
        },
      },
    };
  },
  async mounted() {
    //TODO: new survey can only be created if one or more questionnaires are availible and if there are active
    try {
      const count = await this.getQuestionaryCount(this.user.company_id);
      this.edit.disabled = count <= 0;
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    ...mapGetters("surveys", ["items", "totalItems", "isLoading", "options"]),
    ...mapGetters("account", ["user"]),
  },
  methods: {
    itemFormatDate(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };
      let resdat = new Date(date).toLocaleString('de-DE', options);
      // resdat = resdat.replace(/,/g, '');
      return resdat;
    },
    handleClose(event) {
      this.showSubjectsList = false;
      this.showSubjectsManager = false;
    },
    async reloadComponent() {
      await this.loadSurveys();
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    toggleSubjectsList(item) {
      this.showSubjectsManager = true;
      this.selectedItem = item;
      this.showSubjectsList = true;
      this.subjectsListKey += 1; // Key ändern, um Neurendern zu erzwingen
    },
    showTree(item) {
      this.tree.item = { ...item };
    },
    startEditItem(item) {
      this.edit.item = { ...item };
      // console.log("tei: ", this.edit.item);
    },
    updateSurveyStatus(item, value) {
      this.updateSurvey({ ...item, status: value });
    },
    pollPreview(survey_hash, subject_hash) {
      const poll_url =
        survey_hash === "" || !survey_hash
          ? `poll/${survey_hash}`
          : `poll/${survey_hash}&${subject_hash}`;

      let route = this.$router.resolve({ path: poll_url });
      window.open(route.href);
    },
    async exportData(item) {
      if (item === null || item === undefined) return;
      const permission = await this.awaitModal({
        title: item.name + " Ergebnisse exportieren",
        message: " Rohdaten als CSV-File herunterladen?",
      });
      if (permission) {
        this.showProcess({
          text: "Bitte warten, Ergebnisse aus " + item.name + " werden exportiert ...",
          promise: this.exportSurvey(item),
          // csv-file wird aus exportSurvey() delivered, daher hier kein return!
        });
      }
    },
    async generateItems(item) {
      if (item === null || item === undefined) return;

      const permission = await this.awaitModal({
        title: item.name + " Testdaten generieren",
        message: " Wollen Sie alle noch offenen Teilnehmer (Status <bereit>) mit Testdaten belegen?",
      });
      if (permission) {
        const p = this.generateSurvey(item);
        this.showProcess({
          text: "Bitte warten, Test-Daten werden erzeugt ...",
          promise: p,
        });
      }
    },
    async resetItems(item) {
      if (item === null || item === undefined) return;

      const permission = await this.awaitModal({
        title: item.name + " zurücksetzen",
        message: " Achtung: Wollen Sie wirklich alle Umfragedaten, Auswertungen, Berichte und Teilnehmerstati zurücksetzen?",
      });
      //return permission && this.resetSurvey(item);
      if (permission) {
        this.showProcess({
          text: "Umfrage " + item.name+ " wird zurückgesetzt",
          promise: this.resetSurvey(item),
        });
      }
    },
    async archiveItems(item) {
      if (item === null || item === undefined) return;

      const permission = await this.awaitModal({
        title: item.name + " archivieren",
        message: " Wollen Sie die Umfrage mit allen Ergebnissen archivieren?",
      });
      //return permission && this.resetSurvey(item);
      if (permission) {
        this.showProcess({
          text: "Umfrage " + item.name+ " wurde archiviert",
          promise: this.archiveSurvey(item),
        });
      }
    },
    copyTestLinkClipboard(survey_hash, subject_hash) {
      const poll_url =
        survey_hash === "" || !survey_hash
          ? `poll/${survey_hash}`
          : `poll/${survey_hash}&${subject_hash}`;

      const path = window.location.origin + "/" + poll_url;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(path);
      }
    },
    copyUmfrageOpenLinkClipboard(survey_hash) {
      const poll_url =
        survey_hash === "" || !survey_hash
          ? `poll/${survey_hash}`
          : `poll/${survey_hash}`;

      const path = window.location.origin + "/" + poll_url;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(path);
      }
    },
    ...mapActions("surveys", {
      loadSurveys: "getAll",
      updateSurvey: "update",
      updateOptions: "updateOptions",
      generateSurvey: "generateSurvey",
      exportSurvey: "exportSurvey",
      resetSurvey: "reset",
      archiveSurvey: "archive",
    }),
    ...mapActions("questionary", ["getQuestionaryCount"]),
    ...mapActions("company", ["getSurveys"]),
    ...mapActions("modal", ["awaitModal"]),
    ...mapActions("alert", ["showProcess"]),
  },
};
</script>

<style scoped>
.custom-switch .v-input__control--switch {
  background-color: red; /* Set the color for the off state */
}

.custom-margin-survey-table {
  margin-top: 20px; /* Hier können Sie die gewünschte Anzahl von Pixeln anpassen */
}

</style>

