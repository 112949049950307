import { apiService } from "./api.service";

export const subjectsService = {
  exportSubjects: (survey_id) => apiService.GET(`/surveys/export/subjects/${survey_id}`),
  
  generateSubjectKeys: (survey_id, count) => {
    // console.log("generateSubjectKeys called with:", survey_id, count);
    return apiService.POST(`/subjects`, { survey_id, count })
  },
  
  deleteUnusedSubjects: (data) => apiService.DELETE(`/subjects/unused/${data.survey_id}`),

  getAllSubjects: (survey_id) => {
    // console.log("getAllSubjects called with id:", survey_id);
    return apiService.GET(`/surveys/subjects/${survey_id}`);
  },

  getAllSubjectsPagination: (survey_id, subjectsopt) => {
    // console.log("getAllSubjectsPagination called with survey_id:", survey_id);
    // console.log("Type of survey_id in getAllSubjectsPagination:", typeof survey_id);
    // console.log("getAllSubjectsPagination called with subjectsopt:", subjectsopt);
    return apiService.POST(`/surveys/subjects/pagination/${survey_id}`, subjectsopt);
  },
};
