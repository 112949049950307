<template>
  <div style="position: relative;">
    <p
      class="text-center text-h5 font-weight-medium"
      style="position: sticky; bottom: 0"
    >
      {{ value.name }}
    </p>
    <v-form v-model="_valid" >
      <v-list v-for="(question, i) in value.questions" :key="i" style="background-color: transparent;">
        <o-poll-question-item :index="i + 1" :value="question" />
      </v-list>
    </v-form>
  </div>
  <!-- <v-card flat width="1180" class="mb-16">
    <v-card-title
      class="text-center font-weight-bold mb-8"
      style="display: block"
    >
      {{ value.name }}
    </v-card-title>
    <v-form v-model="_valid">
      <v-list v-for="(question, i) in value.questions" :key="i">
        <o-poll-question-item :index="i + 1" :value="question" />
      </v-list>
    </v-form>
  </v-card> -->
</template>

<script>
import OPollQuestionItem from "./OPollQuestionItem.vue";

export default {
  name: "o-poll-subcategory-item",
  components: {
    OPollQuestionItem,
  },
  props: {
    value: {
      type: Object,
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    _valid: {
      get() {
        return this.valid;
      },
      set(value) {
        this.$emit("update:valid", value);
      },
    },
    _href() {
      return `${this.value.id}`;
    },
  },
};
</script>

<style scoped></style>
