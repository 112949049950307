import { apiService } from "@/services";

export const rulesService = {
  getAll: ({ reporting_id, options }) => apiService.POST(`/reportings/rules/${reporting_id}`, options),
  get: (rule_id) => apiService.GET(`/reportings/rules/${rule_id}`),
  delete: (data) => apiService.DELETE(`/reportings/rule/${data.rule_id}`),
  create: (data) => apiService.POST(`/reportings/rule`, data),
  update: (data) => apiService.PUT(`/reportings/rule/${data.rule_id}`, data),
  downloadReport: (rule_hash) => apiService.GET(`/results/${rule_hash}`, null, {
    'Content-Disposition': 'attachment; filename="report.pdf"'
  })
};
