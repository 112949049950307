<template>
  <v-text-field
    v-model="password"
    :rules="rules"
    :append-icon="eye"
    :type="show ? 'text' : 'password'"
    @click:append="show = !show"
    v-on="$listeners"
    v-bind="{ ...$attrs, ...$props }"
  />
</template>

<script>
export default {
  name: "o-password-field",
  props: {
    value: {
      type: String,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    prependInnerIcon: {
      type: String,
      default: "mdi-lock",
    },
    placeholder: {
      type: String,
      default: "Passwort",
    },
    disableEye: Boolean,
  },
  data() {
    return {
      show: false,
      rules: [(value) => !!value],
    };
  },
  computed: {
    eye() {
      if (this.disableEye) return "";
      return this.show ? "mdi-eye" : "mdi-eye-off";
    },
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
