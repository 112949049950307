var render = function render(){var _vm=this,_c=_vm._self._c;return (this.company !== null)?_c('div',[_c('h2',{staticClass:"pb-6"},[_vm._v("Organisations Einstellungen")]),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('o-image-input',{attrs:{"disabled":""}})],1),_c('v-col',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p',{staticClass:"text-subtitle-2"},[_vm._v("Details")]),_c('v-text-field',{attrs:{"rounded":"","filled":"","label":"Name *","placeholder":"Tester GmbH","rules":_vm.rules},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('v-textarea',{attrs:{"rounded":"","filled":"","label":"Beschreibung"},model:{value:(_vm.company.description),callback:function ($$v) {_vm.$set(_vm.company, "description", $$v)},expression:"company.description"}}),_c('o-address-field',{attrs:{"address":_vm.company.address,"city":_vm.company.city,"zip":_vm.company.zip},on:{"update:address":function($event){return _vm.$set(_vm.company, "address", $event)},"update:city":function($event){return _vm.$set(_vm.company, "city", $event)},"update:zip":function($event){return _vm.$set(_vm.company, "zip", $event)}}})],1),_c('small',{staticClass:"text--secondary"},[_vm._v("Pflichtfelder *")])],1)],1),_c('v-btn',{staticClass:"mt-6",attrs:{"depressed":"","color":"primary","disabled":!_vm.valid},on:{"click":() => {
          _vm.updateCompany({
            company_id: _vm.user.company_id,
            name: _vm.company.name,
            description: _vm.company.description,
            address: _vm.company.address,
            zip: _vm.company.zip,
            city: _vm.company.city,
          });
        }}},[_vm._v(" Speichern ")]),_c('v-divider',{staticClass:"my-6"})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }